// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rechtsbeistand {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 10pt;
  color: #ffffff;
  background-color: #669966 !important;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding: 5px;
}

.rechtsbeistand H2 {
  font-size: 14pt;
  margin-top: 9pt;
  margin-bottom: 7pt;
  font-weight: bold;
}

.rechtsbeistand .Body-Text {
  line-height: 14pt;
  margin-top: 5pt;
  text-align: justify;
}

.rechtsbeistand A {
  color: #ffffff;
  text-decoration: underline;
}

.rechtsbeistand a:hover {
  color: #00ff00;
  text-decoration: underline;
}

.rechtsbeistand P.List {
  margin-left: 2em;
}

.rechtsbeistand .Note {
  margin-top: 9pt;
  margin-bottom: 9pt;
  padding-top: 1px;
  padding-bottom: 1px;
  border-color: #000000 black;
  border-style: solid;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/dunning/rechtsbeistand/rechtsbeistand.css"],"names":[],"mappings":"AAAA;EACE,kDAAA;EACA,eAAA;EACA,cAAA;EACA,oCAAA;EAGA,qBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AACA;EACE,cAAA;EACA,0BAAA;AAEF;;AACA;EACE,cAAA;EACA,0BAAA;AAEF;;AAAA;EACE,gBAAA;AAGF;;AAAA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;EACA,qBAAA;EACA,uBAAA;EACA,wBAAA;EACA,sBAAA;EACA,mBAAA;EAGA,qBAAA;EACA,aAAA;AAGF","sourcesContent":[".rechtsbeistand {\n  font-family: Verdana, Arial, Helvetica, sans-serif;\n  font-size: 10pt;\n  color: #ffffff;\n  background-color: #669966 !important;\n  -ms-hyphens: auto;\n  -moz-hyphens: auto;\n  -webkit-hyphens: auto;\n  hyphens: auto;\n  padding: 5px;\n}\n\n.rechtsbeistand H2 {\n  font-size: 14pt;\n  margin-top: 9pt;\n  margin-bottom: 7pt;\n  font-weight: bold;\n}\n\n.rechtsbeistand .Body-Text {\n  line-height: 14pt;\n  margin-top: 5pt;\n  text-align: justify;\n}\n.rechtsbeistand A {\n  color: #ffffff;\n  text-decoration: underline;\n}\n\n.rechtsbeistand a:hover {\n  color: #00ff00;\n  text-decoration: underline;\n}\n.rechtsbeistand P.List {\n  margin-left: 2em;\n}\n\n.rechtsbeistand .Note {\n  margin-top: 9pt;\n  margin-bottom: 9pt;\n  padding-top: 1px;\n  padding-bottom: 1px;\n  border-color: #000000 black;\n  border-style: solid;\n  border-top-width: 1px;\n  border-right-width: 0px;\n  border-bottom-width: 1px;\n  border-left-width: 0px;\n  text-align: justify;\n  -ms-hyphens: auto;\n  -moz-hyphens: auto;\n  -webkit-hyphens: auto;\n  hyphens: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
