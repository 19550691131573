import React, { useState } from 'react';
import { Translate } from 'react-jhipster';
import ForeignCountryModal from 'app/modules/dunning/attorney/foreign-country-dialog/foreignCountryModal';
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap';

export const LocationDetailsLabel = () => {
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <Label className="name-label" id="label-attornyOfRecord.postcode-city">
      <Translate contentKey="dunning.attorneyOfRecord.postcode-city">Label before input.</Translate>
      <Link className="foreign-country-link" to="" onClick={handleToggleModal} id="label-attornyOfRecord.foreign-country">
        <ForeignCountryModal showModal={showModal} handleClose={handleToggleModal} />
        <Translate contentKey="dunning.attorneyOfRecord.foreign-country"></Translate>
      </Link>
      {':'}
    </Label>
  );
};
