// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-layout {
  margin-top: 20px;
}

.court-assigned-guardian-ad-item-id {
  font-weight: bold;
  margin-top: 50px;
}

.enter-data {
  font-weight: bold;
  margin-top: 50px;
}

.delete-attorny-of-record {
  font-weight: bold;
}

.delete-attorny-of-record-layout {
  text-align: left;
}

.legal-representative-label {
  text-align: right;
}

.foreign-country-link {
  font-weight: normal;
}

#next-button {
  margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/dunning/attorney/attornyOfRecord.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,iBAAA;EACA,gBAAA;AAEF;;AAAA;EACE,iBAAA;EACA,gBAAA;AAGF;;AAAA;EACE,iBAAA;AAGF;;AADA;EACE,gBAAA;AAIF;;AADA;EACE,iBAAA;AAIF;;AADA;EACE,mBAAA;AAIF;;AAFA;EACE,iBAAA;AAKF","sourcesContent":[".form-layout {\n  margin-top: 20px;\n}\n.court-assigned-guardian-ad-item-id {\n  font-weight: bold;\n  margin-top: 50px;\n}\n.enter-data {\n  font-weight: bold;\n  margin-top: 50px;\n}\n\n.delete-attorny-of-record {\n  font-weight: bold;\n}\n.delete-attorny-of-record-layout {\n  text-align: left;\n}\n\n.legal-representative-label {\n  text-align: right;\n}\n\n.foreign-country-link {\n  font-weight: normal;\n}\n#next-button {\n  margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
