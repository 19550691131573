import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AusgerechneterZins = () => {
  return (
    <html>
      <head>
        <title>Ausgerechneter Zins</title>
      </head>
      <body className="hauptforderungen ausgerechneter-zins">
        <HelpNavigationMenu />

        <h2>
          <a target="ausgerechneter_zins"></a>Ausgerechneter Zins
        </h2>

        <p className="Body-Text">
          Hier haben Sie die Möglichkeit einen schon ausgerechneten Zinsbetrag einzugeben. Im allgemeinen macht man dies, wenn die Eingabe
          von großen Zinsstaffeln im Bereiche &quot;laufende Zinsen&quot; nicht sinnvoll ist oder wenn eine Teilzahlung auf Zinsen
          verrechnet wurde. Die Berechnung der ausgerechneten Zinsen sollte den Antragsgegnern jedoch gesondert mitgeteilt werden.
        </p>

        <p className="Body-Text">
          Geben Sie in den Feldern &quot;vom&quot; und &quot;bis&quot; den Zeitraum ein, in dem die Zinsen angefallen sind.
        </p>

        <p className="Body-Text">Im Feld &quot;Betrag&quot; tragen Sie den ausgerechneten Betrag der Zinsen ein.</p>

        <p className="Body-Text">
          Bestätigen Sie Ihre Angaben mit Klick auf <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default AusgerechneterZins;
