import { IPersonDetails } from 'app/shared/reducers/requeststore.interface';

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};

export enum UrlPrefix {
  Dunning = '/mahnbescheid',
  Redelivery_dunning = '/neuzustellung_mahnbescheid',
  Objection = '/widerspruch',
  Enforcement = '/vollstreckungsbescheid',
  Redelivery_enforcement = '/neuzustellung_vollstreckungsbescheid',
  None = '/',
}

export enum RequestTypes {
  Dunning = 'dunning', // Mahnantrag
  Enforcement = 'enforcement', // Vollstreckungsbescheid
  Opposition = 'opposition',
  Objection = 'objection', // Widerspruch
  RedeliveryDunning = 'redeliveryDunning', // Neuzustellung Mahnantrag
  RedeliveryEnforcement = 'redeliveryEnforcement', // Neuzustellung Vollstreckungsbescheid
}

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

export const INITIAL_PERSON_DETAILS: IPersonDetails = {
  id: '',
  type: '',
  salutation: '',
  name: ['', '', '', ''],
  streetOrHouseNumber: '',
  postCode: '',
  city: '',
  foreignCountry: '',
  companyStreetOrHouseNumber: '',
  companyLegalOption: '',
  applicantCode: '',
  assets: '',
  isLawyerOnOwnBehalf: false,
  naturalPersonDesignationOption: '',
  naturalPersonDesignation: '',
  naturalPersonStreetOrHouseNumber: '',
  position: '',
  legalPersonDesignation: '',
  legalRepresentatives: {},
  tab: -1,
  innerTab: -1,
  isNatoTroopStatus: false,
  paymentsDone: false,
  payments: [],
};
