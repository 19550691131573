// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.barcode .barcode-image {
  float: right;
  margin: 5px 0 5px 10px;
  width: 114px;
  height: 162px;
  background-color: #ffffff;
}

.barcode .clear-both {
  clear: both;
}

.barcode h2 {
  font-size: 14pt;
  margin-top: 9pt;
  margin-left: 5px;
  margin-bottom: 7pt;
  font-weight: bold;
}

.barcode {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 10pt;
  color: #ffffff;
  background-color: #669966 !important;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding: 5px;
}

.barcode A {
  color: #ffffff;
  text-decoration: underline;
  font-weight: normal;
}

.barcode a:hover {
  color: #00ff00;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/dunning/barcode/barcode.css"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,kDAAA;EACA,eAAA;EACA,cAAA;EACA,oCAAA;EAGA,qBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;AACF","sourcesContent":[".barcode .barcode-image {\n  float: right;\n  margin: 5px 0 5px 10px;\n  width: 114px;\n  height: 162px;\n  background-color: #ffffff;\n}\n\n.barcode .clear-both {\n  clear: both;\n}\n\n.barcode h2 {\n  font-size: 14pt;\n  margin-top: 9pt;\n  margin-left: 5px;\n  margin-bottom: 7pt;\n  font-weight: bold;\n}\n\n.barcode {\n  font-family: Verdana, Arial, Helvetica, sans-serif;\n  font-size: 10pt;\n  color: #ffffff;\n  background-color: #669966 !important;\n  -ms-hyphens: auto;\n  -moz-hyphens: auto;\n  -webkit-hyphens: auto;\n  hyphens: auto;\n  padding: 5px;\n}\n\n.barcode A {\n  color: #ffffff;\n  text-decoration: underline;\n  font-weight: normal;\n}\n\n.barcode a:hover {\n  color: #00ff00;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
