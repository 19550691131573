import React, { useState } from 'react';
import { Button, Input, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideRequestStore } from 'app/shared/reducers/requeststore';
import { downloadFile } from 'app/shared/util/statics';

const Debug = () => {
  const requestStoreData = useAppSelector(state => state.requestStore);
  const [newState, setNewState] = useState('');
  const dispatch = useAppDispatch();

  const commitToState = () => {
    dispatch(overrideRequestStore(newState));
  };

  const dumpState = () => {
    const json = JSON.stringify(requestStoreData);
    const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });
    downloadFile(blob, 'requeststore.json');
  };

  return (
    <>
      <Row>
        <Input type={'textarea'} value={newState} onChange={e => setNewState(e.target.value)} />
      </Row>
      <Row>
        <Button onClick={commitToState}>Commit to Requeststore</Button>
      </Row>
      <Row>
        <Button onClick={dumpState}>Dump Requeststore</Button>
      </Row>
    </>
  );
};

export default Debug;
