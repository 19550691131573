import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';
import { RequestTypes } from 'app/config/constants';

const AllgemeineAngaben = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Allgemeine Angaben zum Antrag</title>
      </head>
      <body className="generic-help allgemeine-angaben">
        <HelpNavigationMenu />

        {type === RequestTypes.Objection ? <h2>Allgemeine Angaben</h2> : <h2>Allgemeine Angaben zum Antrag</h2>}

        <p>Hier können Sie dem Antragsgegner Ihr Geschäftszeichen mitteilen.</p>

        {type === RequestTypes.RedeliveryEnforcement ? (
          <div>
            <p>
              Hier können Sie dem Antragsgegner Ihr Geschäftszeichen mitteilen und festlegen, ob Sie den Vollstreckungsbescheid nunmehr
              selbst durch einen Gerichtsvollzieher zustellen lassen möchten.
            </p>
            <p>
              Möchten Sie den Vollstreckungsbescheid selber durch einen Gerichtsvollzieher zustellen lassen, so setzen Sie den
              entsprechenden Haken.
            </p>
            <p>
              Werden dem Antrag Anlagen hinzugefügt, setzen Sie den entsprechenden Haken. Bitte beachten Sie, dass dann bis zur Einführung
              des elektronischen Rechtsverkehrs nur der Barcode-Druck möglich ist.
            </p>
          </div>
        ) : type === RequestTypes.Objection ? (
          <div></div>
        ) : (
          <p>
            Werden dem Antrag Anlagen hinzugefügt, setzen Sie den Haken vor{' '}
            <span className="quotable">Anlage mit weiteren Angaben ist beigefügt</span>. Bitte beachten Sie, dass dann bis zur Einführung
            des elektronischen Rechtsverkehrs nur der Barcode-Druck möglich ist.
          </p>
        )}

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default AllgemeineAngaben;
