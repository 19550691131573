import './hinweis.css';
import React from 'react';

const Edge = () => {
  return (
    <html>
      <head>
        <title>Hinweis zu Microsoft Edge</title>
      </head>
      <body className="hinweis edge">
        <h1>Hinweis zu Microsoft Edge</h1>

        <div className="text">Mit dem in Microsoft Edge integrierten PDF Viewer können Sie den Barcodeantrag nicht korrekt ausdrucken.</div>

        <div className="text">
          Installieren Sie bitte den Adobe Acrobat Reader. In Microsoft Edge können Sie den Adobe Acrobat Reader nicht als Plug-in
          installieren. Speichern Sie den Barcode-Antrag im PDF-Format und öffnen ihn im Acrobat Reader.
        </div>

        <a className="closeButton" href="javascript:window.close(true)">
          Fenster schließen
        </a>
      </body>
    </html>
  );
};
export default Edge;
