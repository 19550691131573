import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';

const Geschaeftsnummer = () => {
  return (
    <html>
      <head>
        <title>Geschäftsnummer des Mahngerichts</title>
      </head>
      <body className="generic-help geschaeftsnummer">
        <HelpNavigationMenu />

        <h2>Geschäftsnummer des Mahngerichts</h2>

        <p>
          <strong>GNR-Mahngericht:</strong> Die auf dem letzten Bescheid vermerkte Geschäftsnummer des Mahngerichts/Amtsgerichts.
        </p>

        <p className="listHead">Wenn Sie die Geschäftsnummer des Gerichts hier nicht vollständig angeben können:</p>
        <ul>
          <li>Ist der Geschäftsnummer am Ende ein „N“ angefügt, lassen Sie dies einfach weg.</li>
          <li>Können Sie die Geschäftsnummer aus anderem Grund nicht vollständig angeben, nehmen Sie bitte Kontakt zum Gericht auf.</li>
        </ul>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Geschaeftsnummer;
