import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavDropdown } from '../menu-components';
import { Translate, translate } from 'react-jhipster';
import { useAppSelector } from 'app/config/store';

export const RedeliveryDunningRequestMenu = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  const menuEntries = new Map<string, string>();
  const mainLabelForTranslateKey = 'menu.request';
  const requestTypeFoTranslateKey = 'redeliveryDunning';
  const labelKey = `${mainLabelForTranslateKey}.${requestTypeFoTranslateKey}`;
  menuEntries.set(`${labelKey}.business-number-of-court`, '/geschaeftsnummer');
  menuEntries.set(`${labelKey}.dispatch-type`, '/versandart_auswahl');
  menuEntries.set(`${labelKey}.attorney-of-record`, '/rechtsbeistand');
  menuEntries.set(`${labelKey}.defendant-name`, '/antragsgegnerbezeichnung');
  menuEntries.set(`${labelKey}.defendant-address`, '/antragsgegneranschrift');
  menuEntries.set(`${labelKey}.expenses`, '/auslagen');
  menuEntries.set(`${labelKey}.service-on-legal-representative`, '/zustellung_vertreter');
  menuEntries.set(`${labelKey}.new-legal-representative`, '/neuer_vertreter');
  menuEntries.set(`${labelKey}.general-information`, '/allgemeine_angaben');
  return (
    <NavDropdown name={translate('menu.request.label')} id="request-dropdown">
      {[...menuEntries.entries()].map(([key, value]) => (
        <DropdownItem key={key} href={`${urlPrefix}${value}`}>
          <Translate contentKey={key} />
        </DropdownItem>
      ))}
    </NavDropdown>
  );
};
