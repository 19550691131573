import './bankverbindung.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Bankverbindung = () => {
  return (
    <html>
      <head>
        <title>Bankverbindung</title>
      </head>
      <body className="bankverbindung">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="bankverbindung"></a>Bankverbindung
        </h2>

        <p className="Body-Text">Hier haben Sie Möglichkeit dem Antragsgegner Ihre Bankverbindung mitzuteilen.</p>

        <p className="Body-Text">
          Dazu geben Sie im Feld &quot;BLZ/Konto-Nr.&quot; Ihre Bankleitzahl und Ihre Kontonummer ein oder (ab 01.12.2008) Ihre IBAN
          (International Bank Account Number) und BIC (Bank Identifier Code).
        </p>

        <p className="Body-Text">Beispiel:</p>

        <p className="List">BLZ/Konto-Nr.: 36000000 12345678</p>

        <p className="List">Kontozuordnung: Der erste/einzige Antragsteller ist Kontoinhaber.</p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Bankverbindung;
