import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';

const WegVerwalter = () => {
  return (
    <html>
      <head>
        <title>Einführung zum Online-Mahnantrag - Erfassen eines Sonstigen Antragsteller - WEG</title>
      </head>
      <body className="antragsgegner sonstige weg-verwalter">
        <HelpNavigationMenu />

        <h4 className="heading-4">
          <a target="weg_verwalter"></a>
          <i>WEG Verwalter als Antragsgegner</i>
        </h4>

        <p className="Body-Text">Im Feld &quot;Funktion&quot; ist die Funktion des Verwalters auszuwählen.</p>

        <p className="Body-Text">
          Im Feld &quot;Bezeichnung&quot; ist die komplette Bezeichnung der Verwaltungsgesellschaft anzugeben. Es stehen 4 Zeilen zur
          Verfügung.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Straße/Hausnummer&quot; tragen Sie den Straßennamen und die Hausnummer ein, in der die Verwaltungsgesellschaft ihren
          Sitz hat.
        </p>

        <p className="Body-Text">
          Geben Sie die Postleitzahl und den Ort ein. Das Auslandskennzeichen ist nur anzugeben, wenn der Wohnsitz nicht in Deutschland
          liegt. Befindet sich der Wohnsitz in Deutschland, so soll kein Auslandskennzeichen angegeben werden.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default WegVerwalter;
