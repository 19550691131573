import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Umfang = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Angaben zum Widerspruch</title>
      </head>
      <body className="generic-help umfang">
        <HelpNavigationMenu />

        <h2>Angaben zum Widerspruch</h2>

        <p>
          Wenn Sie gegen den Anspruch insgesamt Widerspruch erheben wollen (Gesamtwiderspruch), setzen Sie den Haken vor{' '}
          <span className="quotable">Ich widerspreche dem Anspruch insgesamt</span>.
        </p>

        <p>
          Bei Teilwiderspruch setzen Sie den Haken vor <span className="quotable">Ich widerspreche nur einem Teil des Anspruchs</span>. Den
          Teilwiderspruch können Sie auf der Folgeseite genauer Beschreiben indem Sie Angaben zum Umfang des Teilwiderpsruchs machen (Höhe
          der Hauptforderung, Zinsen, Verfahrenskosten oder Nebenforderungen).
        </p>

        <p className="Note">
          <u>Hinweis:</u> Für den Fall, dass der Widerspruchsumfang mit den angebotenen Feldern nicht hinreichend konkretisiert werden kann,
          senden Sie bitte ein separates Schreiben an das zuständige Mahngericht.
        </p>

        <p className="Note">
          <u>Hinweis:</u> Für die laufenden Zinsen können Sie zur Bezeichnung des nichtbestrittenen Teil des Zinsfußes auch einen vom
          jeweiligen Basiszinssatz der Deutschen Bundesbank abhängigen Zinssatz angeben.
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>
        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Umfang;
