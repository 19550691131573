import { useAppSelector } from 'app/config/store';
import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';

const Weg = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Einführung zum Online-Mahnantrag - Erfassen eines Sonstigen Antragsteller - WEG</title>
      </head>
      <body className="antragsteller sonstige weg">
        <HelpNavigationMenu />

        <h2>
          <a target="weg"></a>
          <i>WEG</i>
        </h2>

        <p className="Body-Text">
          Sie können Ihre Rechte als Verwalter in eigenem Namen (Prozessstandsschafter), als Gemeinschaft der Wohnungseigentümer oder als
          Wohnungseigentümer geltend machen.
        </p>

        <p className="Body-Text">
          Bei der <a href={`hilfe${urlPrefix}/glossweg`}>WEG</a> wird deshalb zunächst abgefragt, ob der Verwalter den Antrag im eigenem
          Namen stellt, die WEG selbst den Antrag stellt oder ob die WohnungseigentÜmer den Antrag stellen.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Weg;
