import './edaDownload.scss';
import React, { useState } from 'react';
import { useAppSelector } from 'app/config/store';
import { Translate } from 'react-jhipster';
import { printAndSignType } from '../printAndSign';
import { Button, Form, Label, Row, FormGroup, Input } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import DunningNavigation from 'app/shared/components/UI/navigation/dunning-navigation';
import { processAndDownloadEda } from 'app/shared/util/statics';
import { setPrintAndSign as setPrintAndSignToStore } from 'app/shared/reducers/requeststore';
import { useDispatch } from 'react-redux';
import AlertModal from '../../alertModal/alertModal';

export const EdaDownloadLayout = (props: { activeTab: printAndSignType; navigateToTab: (index: number) => void }) => {
  const requestStoreData = useAppSelector(state => state.requestStore);
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  const natoExceptionChecked = useAppSelector(state => state.requestStore.applicationGeneralInformation?.natoException);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [printAndSign, setPrintAndSign] = useState({
    type: props.activeTab,
    readInstructions: false,
    readNotesForBarcodeAndPrint: false,
    readNotesForBrowser: false,
  });

  function handleOnBackClick() {
    navigate(urlPrefix + '/uebersicht');
  }

  function handleOnNextCick() {
    dispatch(
      setPrintAndSignToStore({
        ...printAndSign,
      }),
    );
    navigate(urlPrefix + '/ende');
  }

  function handleOnPrintoutClick() {
    // TODO: Put logic for 'Ausdruck' button - to download an Overview as an *.pdf
  }
  const handleChange = e => {
    const { id, value, checked, type } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setPrintAndSign({ ...printAndSign, [id]: newValue });
  };

  const handleOnEdaDownload = () => {
    if (natoExceptionChecked) {
      setShowModal(true);
      return;
    }
    processAndDownloadEda(requestStoreData);
  };
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <Form className="eda-download-form-layout">
        <Translate contentKey="generic.print-and-sign.intro-text"> EDA-Download </Translate>
        <ul className="list-items-layout">
          <strong>
            <li className="list-item">
              <Translate contentKey="generic.print-and-sign.main-text-first-point"></Translate>
            </li>
          </strong>
          <strong>
            <li className="list-item">
              <Translate contentKey="generic.print-and-sign.main-text-second-point"></Translate>
              <p className="sub-text">
                <Translate contentKey="generic.print-and-sign.main-text-second-point-sub-text"></Translate>
              </p>
            </li>
          </strong>
          <strong>
            <li className="list-item">
              <Translate contentKey="generic.print-and-sign.main-text-third-point"></Translate>
            </li>
          </strong>
          <strong>
            <li className="list-item">
              <Translate contentKey="generic.print-and-sign.main-text-fourth-point"></Translate>
            </li>
          </strong>
          <strong>
            <li className="list-item">
              <Translate contentKey="generic.print-and-sign.main-text-fifth-point"></Translate>
            </li>
          </strong>
        </ul>

        <FormGroup check>
          <Label check>
            <Input id="readInstructions" type="checkbox" name="optionOne" readOnly onChange={handleChange} />
            <Translate contentKey="generic.print-and-sign.read-instructions-text" />
          </Label>
        </FormGroup>
        <Row className="download-description">
          <strong>
            <Translate contentKey="generic.print-and-sign.download-description"></Translate>
            <Button className="saveButton" name="saveButton" onClick={handleOnEdaDownload}>
              <Translate contentKey="generic.print-and-sign.save"></Translate>
            </Button>
          </strong>
        </Row>
        <Row>
          <span>
            <Translate contentKey="generic.print-and-sign.printout-files-description-first-part"></Translate>
            <Link to="" id="print-and-sign-printout" onClick={handleOnPrintoutClick}>
              <Translate contentKey="generic.print-and-sign.printout"></Translate>
            </Link>{' '}
            <Translate contentKey="generic.print-and-sign.printout-files-description-last-part"></Translate>
          </span>
        </Row>
      </Form>
      <Row>
        <DunningNavigation handleOnBackClick={handleOnBackClick} handleOnNextClick={handleOnNextCick} registeredValidations={{}} />
      </Row>

      <AlertModal isOpen={showModal} toggle={toggleModal} bodyContentKey="alert-modal.error-body-nato-exception" />
    </div>
  );
};
export default EdaDownloadLayout;
