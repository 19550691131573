import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Bankverbindung = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Erfassen eines Prozeßbevollmächtigten</title>
      </head>
      <body className="generic-help bankverbindung">
        <HelpNavigationMenu />

        <h2>Erfassen der Bankverbindung eines Prozessbevollmächtigten</h2>

        <p>Möchten Sie dem Antragsgegner Ihre Bankverbindung mitteilen, so geben Sie hier die erforderlichen Daten ein.</p>

        <p>
          In das Feld <span className="quotable">IBAN</span> geben Sie die IBAN Ihrer Bankverbindung ein. Im Allgemeinen beginnt eine
          deutsche IBAN mit den Buchstaben DE und besteht aus 22 Stellen.
        </p>

        <p>
          In das Feld <span className="quotable">BIC</span> geben Sie die 8- oder 11-stellige BIC Ihrer Bankverbindung ein.
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Bankverbindung;
