// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-navigation-menu .button-menu-a {
  padding: 1px 30px 2px 30px;
  font-weight: bold;
  text-decoration: none;
  border: 2px solid;
  cursor: pointer;
  border-bottom-right-radius: 10px;
  border-color: #fef9ec;
  color: #ffffff !important;
}

.help-navigation-menu.redeliveryDunning .button-menu-a {
  background-color: #f3b812;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #fad56b), color-stop(1, #f3b812));
}

.help-navigation-menu.enforcement .button-menu-a {
  background-color: #0098f8;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #71c5fc), color-stop(1, #0098f8));
}

.help-navigation-menu.redeliveryEnforcement .button-menu-a {
  background-color: #ff6c55;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ff9180), color-stop(1, #ff6c55));
}

.help-navigation-menu.objection .button-menu-a {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #d39a71), color-stop(1, #a66b3f));
  background-color: #a66b3f;
}

.help-navigation-menu a {
  font-weight: bold;
}

.help-navigation-menu a:hover {
  text-decoration: none;
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/generic/helpNavigationMenu/helpNavigationMenu.css"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,iBAAA;EACA,qBAAA;EACA,iBAAA;EACA,eAAA;EAGA,gCAAA;EACA,qBAAA;EACA,yBAAA;AACF;;AAEA;EACE,yBAAA;EACA,8GAAA;AACF;;AACA;EACE,yBAAA;EACA,8GAAA;AAEF;;AACA;EACE,yBAAA;EACA,8GAAA;AAEF;;AACA;EACE,8GAAA;EACA,yBAAA;AAEF;;AACA;EACE,iBAAA;AAEF;;AAAA;EACE,qBAAA;EACA,YAAA;AAGF","sourcesContent":[".help-navigation-menu .button-menu-a {\n  padding: 1px 30px 2px 30px;\n  font-weight: bold;\n  text-decoration: none;\n  border: 2px solid;\n  cursor: pointer;\n  -webkit-border-bottom-right-radius: 10px;\n  -moz-border-radius-bottomright: 10px;\n  border-bottom-right-radius: 10px;\n  border-color: #fef9ec;\n  color: #ffffff !important;\n}\n\n.help-navigation-menu.redeliveryDunning .button-menu-a {\n  background-color: #f3b812;\n  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #fad56b), color-stop(1, #f3b812));\n}\n.help-navigation-menu.enforcement .button-menu-a {\n  background-color: #0098f8;\n  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #71c5fc), color-stop(1, #0098f8));\n}\n\n.help-navigation-menu.redeliveryEnforcement .button-menu-a {\n  background-color: #ff6c55;\n  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ff9180), color-stop(1, #ff6c55));\n}\n\n.help-navigation-menu.objection .button-menu-a {\n  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #d39a71), color-stop(1, #a66b3f));\n  background-color: #a66b3f;\n}\n\n.help-navigation-menu a {\n  font-weight: bold;\n}\n.help-navigation-menu a:hover {\n  text-decoration: none;\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
