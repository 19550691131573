import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';

const Antragsteller = () => {
  return (
    <html>
      <head>
        <title>Auslagen des Antragstellers für dieses Verfahren</title>
      </head>
      <body className="auslagen-nebenforderungen antragstellar">
        <HelpNavigationMenu />

        <h2>
          <a className="antragstellar"></a>Auslagen des Antragstellers für dieses Verfahren
        </h2>

        <p className="Body-Text">
          Die in den Mahnbescheid aufzunehmenden Gerichts- und Anwaltskosten (Rechtsbeistandskosten) berechnet das Gericht.<p></p>
          Hier sind daher nur andere Auslagen des Antragstellers anzugeben, und zwar nur solche, die in unmittelbarem Zusammenhang mit der
          Vorbereitung und Durchführung des Mahnverfahrens stehen und zur zweckentsprechenden Rechtsverfolgung notwendig sind.
        </p>

        <p className="Body-Text">Im Feld &quot;Vordruck/Porto&quot; geben Sie die entstandenen Kosten ein.</p>

        <p className="Body-Text">
          Im Feld &quot;sonstige Auslagen&quot; haben Sie die Möglichkeit weitere Auslagen anzugeben und zu spezifizieren.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Antragsteller;
