import './eIDidentity.scss';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Translate } from 'react-jhipster';
import { Container, Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import DunningNavigation from 'app/shared/components/UI/navigation/dunning-navigation';
import { getIdentityCard, sendToEGVP } from 'app/shared/reducers/eIDIdentityCard';
import { setIdentificationDocument } from 'app/shared/reducers/requeststore';

export const ElDidentityCardLayout = () => {
  const requestStoreData = useAppSelector(state => state.requestStore);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  const identityCard = useAppSelector(state => state.eIDIdentityCardStore.identityCard);
  const egvp = useAppSelector(state => state.eIDIdentityCardStore.egvp);

  const [iAm, setIAm] = useState({
    applicant: false,
    representative: false,
    authorized: false,
  });

  function getQueryParameter(name) {
    const regExp = new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)');
    if ((name = regExp.exec(location.search))) {
      return decodeURIComponent(name[1]);
    }
  }

  function handleSendForFeeClick() {
    const code = getQueryParameter('code');
    dispatch(setIdentificationDocument({ identityCard, iAm }));
    dispatch(sendToEGVP({ requestStoreData, identityCard, iAm, code }));
  }

  function handleOnBackClick() {
    navigate(urlPrefix + '/uebersicht');
  }

  function handleOnNextClick() {
    navigate(urlPrefix + '/ende');
  }

  function handleOnPrintoutClick() {
    // TODO: Put logic for 'Ausdruck' button - to download an Overview as an *.pdf
  }

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    const { id, checked } = e.target;
    setIAm({ ...iAm, [id]: checked });
  }

  function redirect() {
    navigate(urlPrefix + '/drucken_eidredirect');
  }

  useEffect(() => {
    if (!identityCard) {
      const oidcCode = getQueryParameter('code');
      if (oidcCode) {
        dispatch(getIdentityCard({ code: oidcCode, redirectUri: window.location.href.split('?')[0] }));
      }
    }
  }, [identityCard]);

  return (
    <div className="eld-identity-layout">
      {!identityCard && (
        <ol>
          <Row>
            <Col md={2}></Col>
            <Col>
              <strong>
                <li>
                  <Translate contentKey="generic.print-and-sign.read-instructions"></Translate>
                  <Link to="">
                    <Translate contentKey="generic.print-and-sign.online-transfer-help"></Translate>
                  </Link>
                </li>
              </strong>
            </Col>
          </Row>
          <Row>
            <Col md={2}></Col>
            <Col>
              <strong>
                <li>
                  <Translate contentKey="generic.print-and-sign.card-reader-ready-for-operation"></Translate>
                </li>
              </strong>
            </Col>
          </Row>
          <Row>
            <Col md={2}></Col>
            <Col>
              <strong>
                <li>
                  <Translate contentKey="generic.print-and-sign.place-id-card-in-the-card-reader"></Translate>
                </li>
              </strong>
            </Col>
          </Row>
          <Row>
            <Col md={2}></Col>
            <Col>
              <strong>
                <li>
                  <Translate contentKey="generic.print-and-sign.apply-for-an-order-for-payment"></Translate>
                  <Button className="transfer-button" id="transfer-button" name="transfer-button" onClick={redirect}>
                    <Translate contentKey="generic.print-and-sign.transfer"></Translate>
                  </Button>
                </li>
              </strong>
            </Col>
          </Row>
          <Row>
            <Col md={2}></Col>
            <Col>
              <strong>
                <li>
                  <span>
                    <Translate contentKey="generic.print-and-sign.if-necessary-create-printout-first-part"></Translate>{' '}
                    <Link to="" id="printout-link" onClick={handleOnPrintoutClick}>
                      <Translate contentKey="generic.print-and-sign.printout"></Translate>{' '}
                    </Link>
                    <Translate contentKey="generic.print-and-sign.if-necessary-create-printout-second-part"></Translate>
                  </span>
                </li>
              </strong>
            </Col>
          </Row>
        </ol>
      )}
      {identityCard && !egvp && (
        <Form>
          <Container>
            <Row style={{ marginBottom: '1.5rem' }}>
              <Col md={3}></Col>
              <Col md={9} style={{ fontWeight: 'bold' }}>
                <Translate contentKey="generic.open-id-connect.sender-details" />
              </Col>
            </Row>
            <Row>
              <Col md={3} style={{ textAlign: 'right', paddingRight: '0px' }}>
                <Translate contentKey="generic.open-id-connect.sender-familyname" />
              </Col>
              <Col md={9}>
                <Label style={{ fontWeight: 'bold', marginLeft: '0px', marginBottom: '0px' }}>{identityCard.familyName}</Label>
              </Col>
            </Row>
            <Row>
              <Col md={3} style={{ textAlign: 'right', paddingRight: '0px' }}>
                <Translate contentKey="generic.open-id-connect.sender-givenname" />
              </Col>
              <Col md={9}>
                <Label style={{ fontWeight: 'bold', marginLeft: '0px', marginBottom: '0px' }}>{identityCard.givenName}</Label>
              </Col>
            </Row>
            <Row>
              <Col md={3} style={{ textAlign: 'right', paddingRight: '0px' }}>
                <Translate contentKey="generic.open-id-connect.sender-birthdate" />
              </Col>
              <Col md={9}>
                <Label style={{ fontWeight: 'bold', marginLeft: '0px', marginBottom: '0px' }}>{identityCard.birthdate}</Label>
              </Col>
            </Row>
            <Row>
              <Col md={3} style={{ textAlign: 'right', paddingRight: '0px' }}>
                <Translate contentKey="generic.open-id-connect.sender-streetaddress" />
              </Col>
              <Col md={9}>
                <Label style={{ fontWeight: 'bold', marginLeft: '0px', marginBottom: '0px' }}>{identityCard.address.streetAddress}</Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: '1.5rem' }}>
              <Col md={3} style={{ textAlign: 'right', paddingRight: '0px' }}>
                <Translate contentKey="generic.open-id-connect.sender-postalcodecity" />
              </Col>
              <Col md={9}>
                <Label style={{ fontWeight: 'bold', marginLeft: '0px', marginBottom: '0px' }}>
                  {identityCard.address.postalCode + ' ' + identityCard.address.locality}
                </Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: '1.5rem' }}>
              <Col md={3}></Col>
              <Col md={9} style={{ marginLeft: '0px' }}>
                <FormGroup check style={{ paddingLeft: '0px' }}>
                  <Label check>
                    <Input type="radio" id="applicant" name="iam" checked={iAm.applicant} readOnly onChange={handleOnChange} />{' '}
                    <Translate contentKey="generic.open-id-connect.i-am-applicant" />
                  </Label>
                </FormGroup>
                <FormGroup check style={{ paddingLeft: '0px' }}>
                  <Label check>
                    <Input type="radio" id="representative" name="iam" checked={iAm.representative} readOnly onChange={handleOnChange} />{' '}
                    <Translate contentKey="generic.open-id-connect.i-am-representative" />
                  </Label>
                </FormGroup>
                <FormGroup check style={{ paddingLeft: '0px' }}>
                  <Label check>
                    <Input type="radio" id="authorized" name="iam" checked={iAm.authorized} readOnly onChange={handleOnChange} />{' '}
                    <Translate contentKey="generic.open-id-connect.i-am-authorized" />
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: '1.5rem' }}>
              <Col md={3}></Col>
              <Col md={9}>
                <Translate contentKey="generic.open-id-connect.court-costs" />
              </Col>
            </Row>
            <Row style={{ marginBottom: '1.5rem' }}>
              <Col md={3}></Col>
              <Col md={9}>
                <b>
                  <Translate contentKey="generic.open-id-connect.send-for-fee" />
                </b>
                &nbsp;
                <Button name="send-for-fee" size="sm" onClick={handleSendForFeeClick}>
                  <Translate contentKey="generic.open-id-connect.send-for-fee-button" />
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
      {identityCard && egvp && (
        <Container>
          <Row>
            <Col md={2}></Col>
            <Col>
              <strong>
                <Translate contentKey="generic.open-id-connect.egvp-success"></Translate>
              </strong>
            </Col>
          </Row>
          <Row>
            <Col md={2}></Col>
            <Col>
              <strong>
                <span>
                  <Translate contentKey="generic.open-id-connect.printout-prefix"></Translate>{' '}
                  <Link to="" id="printout-link" onClick={handleOnPrintoutClick}>
                    <Translate contentKey="generic.print-and-sign.printout"></Translate>{' '}
                  </Link>
                  <Translate contentKey="generic.open-id-connect.printout-suffix"></Translate>
                </span>
              </strong>
            </Col>
          </Row>
        </Container>
      )}
      <DunningNavigation
        handleOnNextClick={handleOnNextClick}
        handleOnBackClick={handleOnBackClick}
        registeredValidations={{}}
      ></DunningNavigation>
    </div>
  );
};
export default ElDidentityCardLayout;
