import './ansprechpartner.scss';
import React from 'react';

const Ansprechpartner = () => {
  return (
    <div>
      <head>
        <title>Ansprechpartner Mahngerichte</title>
      </head>
      <body className="ansprechpartner">
        <h1>Ansprechpartner Mahngerichte</h1>

        <div className="text">
          Bei <strong>fachlichen</strong> - nicht technischen! - <strong>Fragen zum gerichtlichen Mahnverfahren</strong>, zu rechtlichen
          Problemen bei der Antragstellung oder insbesondere zu bereits beantragten Mahnverfahren können Sie sich an das zuständige
          Mahngerichte wenden. Zuständig ist das Gericht des Bundeslandes, in dem Sie als Antragsteller Ihren Sitz oder Wohnsitz haben (bei
          Ausland Berlin):
        </div>

        <section id="BW">
          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Baden-Württemberg</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>Stuttgart</td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>70154 Stuttgart</td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>Zentrale des Mahngerichts Stuttgart</td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:07119213567">0711 921-3567</a>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section id="BAY">
          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Bayern</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>Coburg</td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>
                  Zentrales Mahngericht
                  <br />
                  bei dem Amtsgericht Coburg
                  <br />
                  Heiligkreuzstrasse 22
                  <br />
                  96441 Coburg
                </td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>Alle Rechtspfleger des Zentralen Mahngerichts</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>
                  <a href="mailto:Poststelle.Zentrales.Mahngericht@ag-co.bayern.de">Poststelle.Zentrales.Mahngericht@ag-co.bayern.de</a>
                </td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:095618785">09561 878-5</a>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section id="BER-BRB">
          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Berlin und Brandenburg</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>
                  Zentrales Mahngericht
                  <br />- Berlin-Brandenburg -
                </td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>13343 Berlin</td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>wechselnd zuständige Service-Einheit</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>
                  Kontaktformular unter
                  <br />
                  <a
                    href="http://www.berlin.de/sen/justiz/gerichte/ag/wedd/aumav-rechtshinweis.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.berlin.de/ag-wedding
                  </a>
                </td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:030901560">030 90156-0 (aus Inland)</a>
                  <br />
                  <a href="tel:+4930901560">+49 30 90156 0 (Ausland)</a>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section id="HB">
          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Bremen</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>Bremen</td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>
                  Amtsgericht Bremen <br /> - Mahnabteilung - <br /> Ostertorstraße 25 - 31 <br /> 28195 Bremen
                </td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>Service-Telefon</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>
                  <a href="mailto:office@amtsgericht.bremen.de">office@amtsgericht.bremen.de</a>
                </td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:04213616115">0421 361-6115 (09.00 - 12.30 Uhr)</a>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section id="HH-MBV">
          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Hamburg / Mecklenburg-Vorpommern</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>
                  Gemeinsames Mahngericht
                  <br />
                  der Länder Hamburg und
                  <br />
                  Mecklenburg-Vorpommern
                </td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>
                  Max-Brauer-Allee 89
                  <br />
                  22765 Hamburg
                </td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>Service-Center</td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:040428111462">040 42811-1462</a>
                  <br />
                  Sprechzeit: Mo.-Fr. 9:00-12:00 Uhr
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section id="HES">
          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Hessen</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>Hünfeld</td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>
                  Hauptstr. 24
                  <br />
                  36088 Hünfeld
                </td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>jeder Rechtspfleger</td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:0665260001">06652 600-01</a>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section id="NDS">
          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Niedersachsen</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>
                  Amtsgericht Uelzen
                  <br />- Zentrales Mahngericht -
                </td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>
                  Rosenmauer 2<br />
                  29525 Uelzen
                </td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>-</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:058188510">0581 8851-0</a>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section id="NRW">
          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Nordrhein-Westfalen</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>AG Euskirchen, ZEMA II</td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>Kölner Str. 40-42</td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>Zentralgeschäftsstelle</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:022519512445">02251 951-2445</a>, <a href="tel:022519512493">-2493</a>, <a href="tel:022519512446">-2446</a>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Nordrhein-Westfalen</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>Amtsgericht Hagen - Mahnabteilung -</td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>
                  Postanschrift: 58081 Hagen
                  <br />
                  Lieferanschrift: Hagener Str. 145, 58099 Hagen
                </td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>Zentralgeschäftsstelle</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:02331967631">02331 967-631</a>; <a href="tel:02331967832">02331 967-832</a>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section id="RPF-SLD">
          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Rheinland-Pfalz / Saarland</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>
                  Gemeinsames Mahngericht
                  <br />
                  der Länder Rheinland-Pfalz
                  <br />
                  und Saarland
                </td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>
                  St.-Veit-Str. 38
                  <br />
                  56727 Mayen
                </td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>Zentrale des AG Mayen</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>
                  <a href="mailto:agmy@ko.jm.rlp.de">agmy@ko.jm.rlp.de</a>
                </td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:026514030">02651 403-0</a>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section id="SAH-SAC-THU">
          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Sachsen-Anhalt, Sachsen, Thüringen</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>
                  Gemeinsames Mahngericht
                  <br />
                  der Länder Sachsen-Anhalt,
                  <br />
                  Sachsen und Thüringen
                </td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>
                  Lehrter Straße 15
                  <br />
                  39418 Staßfurt
                </td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>über Vermittlung</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>
                  <a href="mailto:mahngericht@justiz.sachsen-anhalt.de">mahngericht@justiz.sachsen-anhalt.de</a>
                </td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:039258760">03925 876-0</a>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section id="SH">
          <table className="ansprechpartner-table">
            <thead>
              <tr>
                <th colSpan={2}>Schleswig-Holstein</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahngericht</td>
                <td>
                  Amtsgericht Schleswig
                  <br />
                  Zentrales Mahngericht
                </td>
              </tr>
              <tr>
                <td>Anschrift</td>
                <td>
                  Lollfuß 78
                  <br />
                  24837 Schleswig
                </td>
              </tr>
              <tr>
                <td>Ansprechpartner</td>
                <td>Auskunfts-Hotline</td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:046218150">04621 815-0</a>
                  <br />
                  Sprechzeit: Mo.-Fr. 9:00-12:00 Uhr
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <a className="closeButton" href="javascript:window.close(true)">
          Fenster schließen
        </a>
      </body>
    </div>
  );
};

export default Ansprechpartner;
