import { translate } from 'react-jhipster';
import { ValidationProps } from 'app/shared/components/validated/validatedComponent';
import { Validation } from './validation';

export class FieldEmptyValidation extends Validation {
  // eslint-disable-next-line @typescript-eslint/require-await
  static async validate(props: ValidationProps): Promise<string[]> {
    const errors = [];

    if (props.newValue === '' && !props.isOptional) {
      errors.push(translate('error.missing-value'));
    }

    return errors;
  }
}
export default FieldEmptyValidation;
