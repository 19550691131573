import './gloss.css';
import React from 'react';

const GlossRVG = () => {
  return (
    <html>
      <head>
        <title>RVG</title>
      </head>
      <body className="gloss rvg">
        <h5 className="heading-5">
          <a target="glossrvg"></a>RVG
        </h5>

        <p className="Definition">Rechtsanwaltsvergütungsgesetz</p>
      </body>
    </html>
  );
};

export default GlossRVG;
