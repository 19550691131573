import basicSideContainer from 'app/shared/layout/side-container/basic-side-container';
import { typeSelector } from 'app/shared/layout/side-container/side-container-slice';
import { useAppSelector } from 'app/config/store';
import errorSideContainer from 'app/shared/layout/side-container/error-side-container';

export const SideContainer = () => {
  const type = useAppSelector(typeSelector);
  switch (type) {
    case 'BASIC':
      return basicSideContainer();
    case 'ERROR':
      return errorSideContainer();
    default:
      throw new Error(`Unknown sidecontainer type ${type}`);
  }
};
export default SideContainer;
