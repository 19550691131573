import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { useAppSelector } from 'app/config/store';

const Verbraucher = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Erfassen eines Prozeßbevollmächtigten</title>
      </head>
      <body className="rechtsbeistand verbraucher">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="rechtsbeistand"></a>Erfassen eines Prozessbevollmächtigten (Verbraucherzentrale, /-verband)
        </h2>

        <p className="Note">
          <u>Hinweis:</u> Hier dürfen Sie nur Eingaben machen, wenn es sich bei Ihrem Unternehmen um eine Verbraucherzentrale oder einen
          anderen mit öffentlichen Mitteln geförderten Verbraucherverband handelt und es sich in diesem Fall um die Einziehung von
          Forderungen von Verbrauchern im Rahmen Ihres Aufgabenbereichs handelt (§ 79 Abs. 2 Nr. 3 der Zivilprozessordnung).
        </p>

        <p className="Body-Text">
          Falls der Antragsteller <strong>nicht</strong> zum Vorsteuerabzug berechtigt ist, markieren Sie bitte das zugehörige Kästchen.
        </p>

        <p className="Body-Text">
          In das Feld &quot;Kennziffer&quot; geben Sie, falls vorhanden, die Ihnen vom Gericht zugewiesene{' '}
          <a href={`hilfe${urlPrefix}/antragsteller/kennziffer`}>Prozessvertreterkennziffer</a> ein (8-stellig). Eine Kennziffer ist eine
          vom zuständigen Mahngericht auf Antrag vergebene Nummer, unter der die Daten des Prozessbevollmächtigten bei Gericht hinterlegt
          sind. Die Nutzung einer Kennziffer wird bei häufiger Antragstellung empfohlen. Das Online-Mahnverfahren kann aber auch ohne eine
          Kennziffer durchgeführt werden.<div>Die Kennziffer ist grundsätzlich bundesweit bei den Mahngerichten verwendbar.</div>
          Lediglich das Mahngericht des Landes Niedersachsen (Amtsgericht Uelzen) akzeptiert keine Kennziffern anderer Bundesländer.
        </p>
        <p className="Body-Text">Sofern Ihnen keine Kennziffer zugewiesen wurde, geben Sie in den folgenden Feldern Ihre Daten ein:</p>

        <p className="Body-Text">Im Feld &quot;Rechtsform&quot; wählen Sie die zutreffende Rechtsform aus. </p>

        <p className="Body-Text">Im Feld &quot;vollst. Bezeichnung&quot; geben Sie Ihre komplette Bezeichnung ein. </p>

        <p className="Body-Text">Im Feld &quot;Straße, Hausnummer&quot; geben Sie Ihre Adresse ein.</p>

        <p className="Body-Text">
          Geben Sie die Postleitzahl und den Ort ein. Das Auslandskennzeichen ist nur anzugeben, wenn der Sitz nicht in Deutschland ist.
          Befindet sich der Wohnsitz in Deutschland, so soll kein Auslandskennzeichen angegeben werden.
        </p>

        <p className="Body-Text">
          <i>Bespiel:</i>
        </p>

        <p className="List">eingetragener Verein</p>

        <p className="List">Verbraucherzentrale Musterkreis e. V</p>

        <p className="List">Musterstraße 12</p>

        <p className="List">88888 Musterstadt</p>

        <p className="List">36000001 9876543 </p>

        <p className="List">Musterbank AG</p>

        <p className="Body-Text">
          Haben Sie alle Eingaben zum Prozessbevollmächtigten gemacht, bestätigen Sie die Eingaben mit Klick auf die{' '}
          <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Verbraucher;
