import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';

const Inkasso = () => {
  return (
    <html>
      <head>
        <title>Erfassen eines Prozeßbevollmächtigten</title>
      </head>
      <body className="rechtsbeistand inkasso">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="rechtsbeistand"></a>Erfassen eines Prozessbevollmächtigten (Inkasso-/Kreditdienstleister)
        </h2>

        <p className="Note">
          <u>Hinweis:</u> Hier dürfen Sie nur Eingaben machen, wenn es sich bei Ihnen oder Ihrem Unternehmen um eine registrierte Person
          nach § 10 Abs. 1 Satz 1 Nr. 1 des Rechtsdienstleistungsgesetzes (RDG) handelt.
        </p>

        <p className="Body-Text">
          In das Feld &quot;Vergütung&quot; geben Sie, falls zutreffend, die Ihnen zustehende Vergütung für die Vertretung im gerichtlichen
          Mahnverfahren - ggf. einschl. Auslagenpauschale und Mehrwertsteuer - ein. Bitte beachten Sie hierbei, dass ein Gläubiger die
          Kosten, die ihm ein Inkassodienstleister für seine Tätigkeit berechnet hat, von seinem Schuldner gem. § 13e RDG nur bis zur Höhe
          der Vergütung als Schaden ersetzt verlangen kann, die einem Rechtsanwalt für diese Tätigkeit nach den Vorschriften des
          Rechtsanwaltsvergütungsgesetzes zustehen würde.
        </p>

        <p className="Body-Text">
          Falls der Antragsteller <strong>nicht</strong> zum Vorsteuerabzug berechtigt ist, markieren Sie bitte das zugehörige Kästchen.
        </p>

        <p className="Body-Text">
          In das Feld &quot;Kennziffer&quot; geben Sie, falls vorhanden, die Ihnen vom Gericht zugewiesene{' '}
          <a href="erfasseneinerkennziffer">Prozess­vertreter­kennziffer</a> ein (8-stellig). Eine Kennziffer ist eine vom zuständigen
          Mahngericht auf Antrag vergebene Nummer, unter der die Daten des Prozessbevollmächtigten bei Gericht hinterlegt sind. Die Nutzung
          einer Kennziffer wird bei häufiger Antragstellung empfohlen. Das Online-Mahnverfahren kann aber auch ohne eine Kennziffer
          durchgeführt werden.<div>Die Kennziffer ist grundsätzlich bundesweit bei den Mahngerichten verwendbar.</div>
          Lediglich das Mahngericht des Landes Niedersachsen (Amtsgericht Uelzen) akzeptiert keine Kennziffern anderer Bundesländer.
        </p>
        <p className="Body-Text">Sofern Ihnen keine Kennziffer zugewiesen wurde, geben Sie in den folgenden Feldern Ihre Daten ein:</p>

        <p className="Body-Text">
          Im Feld &quot;Rechtsform&quot; wählen Sie die zutreffende Rechtsform aus. Handelt es sich bei Ihrem Unternehmen um ein
          Einzelunternehmen bzw. sind Sie eingetragener Einzelkaufmann (e.K.), so lassen Sie bitte dieses Feld frei. Tragen Sie aber Ihre
          komplette Firmenbezeichnung mit ausgeschriebenem Vor- und Nachnamen des Inhabers im Feld &quot;vollst. Bezeichnung&quot; ein.
        </p>

        <p className="Body-Text">
          Im Feld &quot;vollst. Bezeichnung&quot; geben Sie Ihre komplette Firma ein. Handelt es sich bei Ihrem Unternehmen um ein
          Einzelunternehmen bzw. sind Sie eingetragener Einzelkaufmann (e.K.), so tragen Sie Ihre komplette Firmenbezeichnung mit
          ausgeschriebenem Vor- und Nachnamen des Inhabers ein. In diesem Falle lassen Sie bitte das Feld &quot;Rechtsform&quot; leer.
        </p>

        <p className="Body-Text">Im Feld &quot;Straße, Hausnummer geben Sie Ihre Adresse ein.</p>

        <p className="Body-Text">
          Geben Sie die Postleitzahl und den Ort ein. Das Auslandskennzeichen ist nur anzugeben, wenn der Sitz nicht in Deutschland ist.
          Befindet sich der Wohnsitz in Deutschland, so soll kein Auslandskennzeichen angegeben werden.
        </p>

        <p className="Body-Text">
          <i>Beispiel juristische Person:</i>
        </p>
        <p className="List">GmbH</p>
        <p className="List">Musterinkasso GmbH</p>
        <p className="List">Musterstraße 12</p>
        <p className="List">88888 Musterstadt</p>
        <p className="List">36000001 9876543 </p>
        <p className="List">Musterbank AG</p>
        <div>
          <p className="Body-Text">
            <i>Beispiel Einzelunternehmen:</i>
          </p>
          <p className="List">&nbsp;</p>
          <p className="List">Inkassobüro Michael Muster</p>
          <p className="List">Musterstraße 12</p>
          <p className="List">88888 Musterstadt</p>
          <p className="List">36000001 9876543 </p>
          <p className="List">Musterbank AG</p>
        </div>

        <p className="Body-Text">
          Haben Sie alle Eingaben zum Prozessbevollmächtigten gemacht, bestätigen Sie die Eingaben mit Klick auf die{' '}
          <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Inkasso;
