import { useAppSelector } from 'app/config/store';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import './rechtsbeistand.css';

const Rechtsbeistand = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Erfassen eines Prozeßbevollmächtigten</title>
      </head>
      <body className="rechtsbeistand">
        <HelpNavigationMenu />
        <h2 className="heading-2">
          <a target="rechtsbeistand"></a>Erfassen eines Prozessbevollmächtigten
        </h2>
        <p className="Body-Text">Bitte wählen Sie aus, zu welcher Gruppe Prozessbevollmächtigter Sie gehören:</p>
        <p className="Body-Text"></p>
        <ul>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/eigen`}>Rechtsanwalt/Rechtsbeistand in eigener Sache</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/fremd`}>Rechtsanwalt/Rechtsbeistand</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/gesellschaft`}>Rechtsanwalts-Gesellschaft</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/herr_frau`}>Herr / Frau</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/inkasso`}>Inkasso-/Kreditdienstleister</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/verbraucher`}>Verbraucherzentrale, /-verband</a>
          </li>
        </ul>
        <p></p>
        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Rechtsbeistand;
