import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons/faSquareCheck';
import { faSquare } from '@fortawesome/pro-solid-svg-icons/faSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';

const LaufendeZinsen = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Laufende Zinsen</title>
      </head>
      <body className="hauptforderungen laufende-zinsen">
        <HelpNavigationMenu />

        <h2>
          <a target="laufende_zinsen"></a>Laufende Zinsen
        </h2>

        <p className="Body-Text">
          Hier haben Sie die Möglichkeit die Verzinsung Ihrer Forderung(en) einzugeben. Im Falle der Katalognummer 83 Säumniszuschläge, vgl.
          unten.
        </p>

        <p className="Body-Text">Im Feld &quot;Zinssatz&quot; geben Sie den Zinssatz an, mit dem verzinst wird.</p>

        <p className="Body-Text">
          Wenn Sie einen festen Zinssatz geltend machen möchten, tragen Sie bitte den Zinssatz ein und lassen das Kästchen rechts daneben
          leer.<p></p>
          <p></p>
          Möchten Sie einen variablen Zinssatz gelten machen, gilt Folgendes:<p></p>
          Bezugsgröße des variablen Zinssatzes ist immer der Basiszinssatz, eine Tabelle finden Sie{' '}
          <a href={`hilfe${urlPrefix}/basiszins`}>hier</a>. Tragen Sie bitte die Differenz zum Basiszinssatz ein und setzen Sie in dem Feld
          rechts daneben ein Häkchen. In dem Fall wird der von Ihnen angegebene Zinssatz zum jeweiligen/aktuellen Basiszinssatz addiert.
        </p>

        <p className="Body-Text">
          <i>Beispiel:</i>
        </p>

        <p className="List">
          Mit der Eintragung<p></p>
          5 % <FontAwesomeIcon className="image-icon" icon={faSquare} /> -Punkte über dem Basiszins <p></p> Häkchen ist nicht gesetzt)
          <p></p>
          beantragen Sie eine Verzinsung mit 5% Zins p.a., Ihr Zinssatz bleibt unverändert bei 5%.
        </p>

        <p className="List">
          Mit der Eintragung<p></p>
          5 % <FontAwesomeIcon className="image-icon" icon={faSquareCheck} /> -Punkte über dem Basiszins<p></p> (Häkchen ist gesetzt)<p></p>
          beantragen Sie eine Verzinsung mit 5 Prozentpunkten über dem jeweils geltenden Basiszinssatz, Ihr Zinssatz passt sich der
          Entwicklung des Basiszinssatzes an.
        </p>

        <p className="Body-Text">
          Welcher Zinssatz Ihnen zusteht, ergibt sich aus dem zugrunde liegenden Anspruch (z.B. Vertrag, Vereinbarung, etc.).
        </p>

        <p className="Body-Text">
          Im Allgemeinen wird es sich um eine jährliche Verzinsung handeln, Sie können jedoch auch monatliche oder tägliche Verzinsung
          wählen.
        </p>

        <p className="Body-Text">
          Im Feld &quot;zu verzinsender Betrag&quot; tragen Sie den Betrag ein, aus dem die Zinsen berechnet werden sollen. Wenn Sie keinen
          Betrag eingeben wird automatisch der Forderungsbetrag angenommen.
        </p>

        <p className="Body-Text">
          Geben Sie in den Feldern &quot;vom&quot; und &quot;bis&quot; den Zeitraum ein, in dem der Zinssatz gelten soll. Wenn Sie kein
          vom-Datum angeben, werden die Zinsen ab Zustellung des Mahnbescheids berechnet. Das bis-Datum ist nur erforderlich, wenn Sie
          unterschiedliche Prozentsätze über mehrere Zeiträume angeben wollen.
        </p>

        <p className="Body-Text">
          &quot;Bei Katalognummer 83, Prämienrückstände für Krankenversicherungsbeiträge&quot; werden an Stelle von Zinsen Säumniszuschläge
          erhoben. Diese sind in ihrer Höhe auf 1% je angefangenen Monat gedeckelt, vgl. § 193 Abs. 6 VVG.
        </p>

        <p className="note">
          <b>Achtung:</b> Zinszeiträume dürfen sich nicht überschneiden, es sei denn, die Verzinsung betrifft nur einen Teil des Anspruchs.
        </p>

        <p className="Body-Text">
          Diese Angaben bestätigen Sie mit Klick auf die <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default LaufendeZinsen;
