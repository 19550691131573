import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';

const MahnantragGestellt = () => {
  return (
    <html>
      <head>
        <title>Auswahl Neuer Prozessbevollmächtigter</title>
      </head>
      <body className="generic-help wait">
        <HelpNavigationMenu />

        <h2>Sie haben den Antrag auf Erlass des Mahnbescheids gestellt?</h2>

        <p>Sind Sie Prozessbevollmächtigter und werden in diesem Verfahren erstmals benannt, wählen Sie die Nein-Schaltfläche.</p>

        <p>
          Sind Sie Prozessbevollmächtigter und haben in diesem Verfahren schon den Antrag auf Erlass des Mahnbescheids gestellt, wählen Sie
          die Ja-Schaltfläche.
        </p>

        <p>Sind Sie selber der Antragsteller, wählen Sie die Ja-Schaltfläche.</p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default MahnantragGestellt;
