import './gloss.css';
import React from 'react';

const GlossVVRvg = () => {
  return (
    <html>
      <head>
        <title>VV RVG</title>
      </head>
      <body className="gloss vvrvg">
        <h5 className="heading-5">
          <a target="glossvvrvg"></a>VV RVG
        </h5>

        <p className="Definition">Vergütungsverzeichnis (des) RVG</p>
      </body>
    </html>
  );
};
export default GlossVVRvg;
