import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';

const Andere = () => {
  return (
    <html>
      <head>
        <title>Andere Nebenforderungen</title>
      </head>
      <body className="auslagen-nebenforderungen andere">
        <HelpNavigationMenu />

        <h2>
          <a target="andere"></a>Andere Nebenforderungen
        </h2>

        <p className="Body-Text">
          Im Feld &quot;Mahnkosten&quot; geben Sie die Kosten ein, die für das vorgerichtlich Mahnverfahren angefallen sind. Dazu gehören{' '}
          <b>nicht</b> die Kosten für diesen Antrag auf Erlass eines Mahnbescheids.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Auskünfte&quot; geben Sie alle Kosten an, die für angeforderte Auskünfte (z.B. Einwohnermeldeamtsanfragen)
          entstanden sind.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Bankrücklastkosten&quot; geben Sie alle entstandenen Rücklastkosten ein, die während des vorgerichtlichen
          Mahnverfahrens angefallen sind.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Inkassokosten&quot; können Sie die Kosten eintragen, die bei Inanspruchnahme eines Inkassohauses angefallen sind.
        </p>

        <p className="Body-Text">
          Verzinsung von Nebenforderungen: Es kann die Verzinsung der einzelnen Nebenforderungsbeträge beantragt werden, falls ein solcher
          Zinsanspruch besteht.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Zinssatz&quot; geben Sie den Zinssatz an, mit dem verzinst wird. Sie haben auch die Möglichkeit den Zinssatz
          variabel, abhängig vom Basiszinssatz, anzugeben. In diesem Fall wird der von Ihnen angegebene Zinssatz zum jeweiligen/aktuellen
          Basiszinssatz addiert. Geben Sie bitte den jährlichen Zinssatz an. Sollte eine abweichende Zinsvereinbarung getroffen sein, z.B.
          monatlich oder täglich, dann rechnen Sie den Zinssatz auf jährliche Verzinsung um. Geben Sie in den Feldern &quot;vom&quot; und
          &quot;bis&quot; den Zeitraum ein, in dem der Zinssatz gelten soll. Wenn Sie kein vom-Datum angeben, werden die Zinsen ab
          Zustellung des Mahnbescheids berechnet. Die Angabe eines bis-Datums ist nicht zwingend erforderlich.
        </p>

        <p className="Body-Text">
          Soweit die Berechnung der Vergütung durch das Gericht erfolgt (nur ges. Gebührenanspruch der Rechtsanwälte):
        </p>

        <p className="Body-Text">
          Im Feld &quot;Anwaltsvergütung für vorgerichtl. Tätigkeit&quot; können Sie vorgerichtliche Anwaltsvergütungen eintragen, z.B. die
          Geschäftsgebühr nach Nr. 2300 oder 2302 VV RVG in voller Höhe, also einschl. des auf die Verfahrensgebühr nach Nr. 3305 VV RVG
          anzurechnenden Teilbetrags (damit der anrechnungspflichtige Betrag vom Gericht von der Verfahrensgebühr abgezogen werden kann,
          muss dieser &quot;Minderungsbetrag&quot; unter &quot;Auf die Verfahr.gebühr Nr. 3305 VV RVG ist anzurechnen:&quot; angegeben
          werden, s. Erläuterungen unten).
        </p>

        <p className="Body-Text">
          Soweit das Feld &quot;Gegenstandswert der vorgerichtl. Tätigkeit&quot; angezeigt wird, können Sie bei Prozessvertretung durch
          Rechtsanwälte und Rechtsbeistände einen Gegenstandswert für die außergerichtliche Tätigkeit angeben, wenn dieser von dem
          Gegenstandswert (&quot;Streitwert&quot;) abweicht, der in das gerichtliche Mahnverfahren übergeht, z.B. bei vorgerichtlicher
          Teilzahlung des Schuldners auf die Forderung.
        </p>

        <p className="Body-Text">
          Mit der Angabe im Feld &quot;Vorgerichtl. Tätigkeit umfangreich/schwierig&quot; versichern Sie den besonderen Umfang / die
          besondere Schwierigkeit der vorgerichtlichen Tätigkeit. Als Folge kann eine Geschäftsgebühr nach Nr 2300 VV RVG mit einem höheren
          Faktor als 1,3 (max 2,5, vgl. Vergütungsverzeichnis des RVG) geltend gemacht werden. Bitte beachten Sie, dass sich in diesem Fall
          auch der Faktor für die Berechnung des auf die Gebühr nach Nr. 3305 VV RVG anrechnungspflichtigen Betrags
          (&quot;Minderungsbetrag&quot;) ändern kann (die Hälfte des Faktors der geltend gemachten Gebühr, max. 0,75, vgl.
          Vergütungsverzeichnis des RVG).
        </p>

        <p className="Body-Text">
          Im Feld &quot;Auf die Verfahr.gebühr Nr. 3305 VV RVG ist anzurechnen:&quot; ist der Betrag anzugeben, um den sich nach der
          Vorbemerkung 3 Abs. 4 zu Nr. 2300 / 2302 VV RVG die im Mahnverfahren nach Nr. 3305 VV RVG entstehende Verfahrensgebühr vermindert
          (= anrechnungspflichtiger Teil der Geschäftsgebühr nach Nr. 2300 / 2302 VV RVG - ohne Auslagen und MWSt.).
        </p>

        <p className="Body-Text">
          Sonstige Nebenforderungen anlegen/bearbeiten: Durch Aufruf der Funktion können weitere, insbesondere durch den Verzug des
          Antragsgegners entstandene vorgerichtliche Kosten und ggf. deren Verzinsung angegeben werden, zum Beispiel:
        </p>

        <p className="List">Versandkosten 7,00</p>

        <p className="List">Kontoführungskosten 5,00</p>

        <p className="List">EMA-Anfrage 6,00</p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Andere;
