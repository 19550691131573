import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';

const Ausland = () => {
  return (
    <html>
      <head>
        <title>Erfassen eines Antragsgegners</title>
      </head>
      <body className="antragsteller ausland">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="ausland"></a>Antragsteller im Ausland
        </h2>

        <p className="Body-Text">
          Hat der Antragsteller seinen Sitz im Ausland, so geben Sie bitte das korrekte Auslandskennzeichen an. Wenn Sie bei der Erfassung
          auf den Begriff &quot;Ausl.Kz.&quot; klicken, erhalten Sie eine entsprechende Auswahlliste.
        </p>

        <p className="Body-Text">
          Zuständig ist dann grundsätzlich das Amtsgericht Wedding in Berlin. Sie müssen also in der Bundeslandauswahl{' '}
          <strong>Berlin</strong> auswählen.
        </p>

        <p className="Body-Text">
          Sollte die Postleitzahl Buchstaben enthalten und/oder mehr als 5 Stellen haben, so geben Sie bitte im Feld Postleitzahl
          &apos;00000&apos; an und im Feld Ort die Postleitzahl und die Ortsbezeichnung.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Ausland;
