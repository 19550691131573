import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';
import { RequestTypes } from 'app/config/constants';

const Antragstellerart = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Auswahl Neuer Prozessbevollmächtigter</title>
      </head>
      <body className="generic-help antragstellerart">
        <HelpNavigationMenu />
        {type === RequestTypes.Objection ? (
          <div>
            <h2>Widerspruch</h2>

            <p>Als Antragsgegner, der im Mahnbescheid benannt ist, wählen Sie die obere Weiter-Schaltfläche.</p>

            <p>Als gesetzlicher Vertreter des im Mahnbescheid benannten Antragsgegners wählen Sie die mittlere Weiter-Schaltfläche.</p>

            <p>Als Prozessbevollmächtigter des im Mahnbescheid benannten Antragsgegners wählen Sie die untere Weiter-Schaltfläche.</p>
          </div>
        ) : (
          <div>
            {' '}
            <h2>Neuer Prozessbevollmächtigter?</h2>
            <p>Sind Sie Prozessbevollmächtigter und in diesem Verfahren schon benannt, wählen Sie die obere Weiter-Schaltfläche.</p>
            <p>Sind Sie selber der Antragsteller, wählen Sie die mittlere Weiter-Schaltfläche.</p>
            <p>
              Sind Sie Prozessbevollmächtigter und werden in diesem Verfahren erstmals benannt, wählen Sie die untere Weiter-Schaltfläche.
            </p>
          </div>
        )}

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Antragstellerart;
