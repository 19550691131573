import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Barcode = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Barcodeausdruck (PDF)</title>
      </head>
      <body className="generic-help barcode">
        <HelpNavigationMenu />
        <h2>Barcodedruck (PDF)</h2>
        <p className="Note">
          <strong>Hinweis:</strong> Wenn Sie den Browser Google Chrome verwenden und den Barcode-Antrag nutzen möchten, lesen Sie bitte den{' '}
          <a href={`hilfe${urlPrefix}/hinweis_chrome`} target="_blank" rel="noreferrer">
            Hinweis zu Google Chrome
          </a>
          .
        </p>
        <p className="Note">
          <strong>Hinweis:</strong> Wenn Sie den Browser Mozilla Firefox verwenden und den Barcode-Antrag nutzen möchten, lesen Sie bitte
          den{' '}
          <a href={`hilfe${urlPrefix}/hinweis_firefox`} target="_blank" rel="noreferrer">
            Hinweis zu Mozilla Firefox
          </a>
          .
        </p>
        <p className="Note">
          <strong>Hinweis:</strong> Wenn Sie den Browser Microsoft Edge verwenden und den Barcode-Antrag nutzen möchten, lesen Sie bitte den{' '}
          <a href={`hilfe${urlPrefix}/hinweis_edge`} target="_blank" rel="noreferrer">
            Hinweis zu Microsoft Edge
          </a>
          .
        </p>
        <p>
          Je nach Browser- und Acrobat-Reader-Unterstützung haben Sie die Möglichkeit den Barcode-Antrag zu drucken (der Barcode-Antrag
          öffnet sich im Browser) und/oder zu speichern. Wenn Sie den Antrag speichern, dann fragt Ihr Browser nach, wie Sie mit der
          heruntergeladenen PDF-Datei verfahren möchten. Sie können sie sofort oder später mit dem Acrobat-Reader öffnen. Die
          heruntergeladenen Dateien finden Sie in Ihrem Browser jederzeit im Menü unter dem Punkt Downloads oder dem Punkt Extras →
          Downloads. Heruntergeladene Barcode-Antragsdateien{' '}
          <u>
            <b>müssen von Ihnen ausgedruckt</b>
          </u>
          , unterschrieben und{' '}
          <u>
            <b>per Post</b>
          </u>{' '}
          geschickt werden. Eine elektronisch übermittelte pdf-Datei würde bei den Gerichten eine Reihe manueller Eingriffe in die Abläufe
          erforderlich machen und wäre deshalb für die maschinelle Bearbeitung i.S.d. § 690 Abs. 3 Satz 1 ZPO ungeeignet. Ein so
          übermittelter Antrag würde{' '}
          <u>
            <b>kostenpflichtig zurückgewiesen</b>
          </u>
          .
        </p>
        <p>Klicken Sie auf das Druckersymbol in der Bedienungsleiste des Adobe Acrobat Readers um alle Seiten zu drucken.</p>
        <p>
          Haben Sie den Barcode-Ausdruck hiernach zur Post gegeben, übersenden Sie den Antrag <strong>keinesfalls</strong> zusätzlich über
          das Internet (EGVP), anderenfalls entstehen zusätzliche Kosten.
        </p>
        <p>
          <strong>Achtung!</strong>
          <p></p>Beachten Sie auch im eigenen Interesse folgende Hinweise zum Ausdruck des Barcode-Antrags. Die Nichtbeachtung dieser
          Hinweise kann die Bearbeitung des Antrages verzögern, zu Beanstandungen und sogar zur kostenpflichtigen Zurückweisung des Antrages
          insgesamt führen (§§ 691, 690 Abs. 3 ZPO).
        </p>
        <ul>
          <li>Benutzen Sie ausschließlich Standard-Druck/Kopier-Papier der Größe DIN A4 in weiß mit der Flächenmasse 80g/qm (DIN 6730).</li>
          <li>
            Verwenden Sie schwarze Druckerfarbe, andere Farben sind nicht immer maschinell lesbar.<p></p>Die Druckoption &quot;Farbe schwarz
            ausgeben&quot; im Acrobat-Druckmenü darf <strong>nicht</strong> gewählt sein.
          </li>
          <li>
            Benutzen Sie eine Druckereinstellung, die mit normaler Druckqualität arbeitet (kein Eco-, Entwurf- oder Optimaldruck);<p></p>
            Mindestdruckauflösung: 300 dpi.
          </li>
          <li>Achten Sie auf einen Ausdruck in 100% Größe (keine automatische Anpassung).</li>
          <li>Verwenden Sie keinen beidseitigen Druck!</li>
          <li>Verwenden Sie nur Adobe Acrobat Reader ab Version 6.0 aufwärts (www.adobe.com)!</li>
          <li>Vermeiden Sie nach dem Ausdruck ein Verwischen der Druckfarbe!</li>
          <li>Verbinden Sie nach dem Ausdruck die Einzelseiten fest miteinander (Heften/Klammern).</li>
          <li>Vermeiden Sie Knicke oder Falze des ausgedruckten Barcodes bei Kuvertierung o.ä.!</li>
          <li>Senden Sie nur ein Exemplar des Antrages ein!</li>
          <li>Beachten Sie die weiteren Hinweise im Deckblatt des Ausdruckes!</li>
        </ul>
        Zum Verständnis sei das Vorgehen bei Gericht kurz beschrieben: Die Heftung wird entfernt, es werden Stapel von mehreren Anträgen
        gebildet und alle Blätter (Deckblatt, Klarschriftausdruck und Barcode) werden im Scanner eingelesen.
        <p></p>
        <p>
          Haben Sie den Antrag erfolgreich gedruckt, betätigen Sie die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>{' '}
          um den Antrag zu beenden.
        </p>
        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Barcode;
