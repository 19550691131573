import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';

const Eda = () => {
  return (
    <html>
      <head>
        <title>Download der EDA-Datei</title>
      </head>
      <body className="generic-help eda">
        <HelpNavigationMenu />

        <h2>Download der EDA-Datei</h2>

        <p className="Note">
          <strong>Hinweis:</strong> Nach dem Download müssen Sie selbst für die Übermittlung der Daten an das zuständige Mahngericht Sorge
          tragen.
          <div></div>Änderungen des Antrags sind nicht zulässig. Wenn Sie die Daten nach dem Download ändern, zerstören Sie die Integrität
          der Datei, sie kann vom Mahngericht nicht mehr verarbeitet werden. Wenn Sie Daten ändern müssen, erstellen Sie unbedingt einen
          neuen Antrag.
        </p>

        <p>
          Zum Download klicken Sie in der Anwendung auf die Speichern-Schaltfläche.
          <div></div>
          Ihr Internet-Browser wird Sie fragen, ob Sie die Datei mit einem Programm öffnen möchten oder ob Sie die Datei speichern möchten.
          Bietet Ihnen Ihr Browser diese Möglichkeit nicht und öffnet stattdessen ein Programm, so haben Sie in Ihrem Betriebssystem die
          Dateiendung &quot;eda&quot; mit dem Öfnnen eines Programms verknüpft.
          <p></p>
          <p></p>
          Bei einigen Browsern können Sie den Speicherort auswählen. Wenn Ihnen Ihr Browser diese Möglichkeit nicht bietet, so wird er die
          Datei in seinem Standard-Download-Ordner ablegen.
          <div></div>
          Um herauszubekommen, wo Ihr Browser die Datei gespeichert hat, bemühen Sie bitte die Hilfe Ihres Browsers.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Eda;
