import './gloss.css';
import React from 'react';

const GlossRaineiEigenersache = () => {
  return (
    <html>
      <head>
        <title>RA in eig. Sache</title>
      </head>

      <body className="gloss raineieigenersache">
        <h5 className="heading-5">
          <a target="glossraineieigenersache"></a>RA in eig. Sache
        </h5>

        <p className="Definition">Rechtsanwalt in eigener Sache</p>
      </body>
    </html>
  );
};

export default GlossRaineiEigenersache;
