// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bundeslandauswahl {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 10pt;
  color: #ffffff;
  background-color: #669966 !important;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding: 5px;
}

.bundeslandauswahl H2 {
  font-size: 14pt;
  margin-top: 9pt;
  margin-bottom: 7pt;
  font-weight: bold;
}

.bundeslandauswahl .Body-Text {
  line-height: 14pt;
  margin-top: 5pt;
  text-align: justify;
}

.bundeslandauswahl .next-button {
  font-size: 10px;
  width: 80px;
  height: 30px;
  background-color: none !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/dunning/bundeslandauswahl/bundeslandauswahl.css"],"names":[],"mappings":"AAAA;EACE,kDAAA;EACA,eAAA;EACA,cAAA;EACA,oCAAA;EAGA,qBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AACA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,iCAAA;AAEF","sourcesContent":[".bundeslandauswahl {\n  font-family: Verdana, Arial, Helvetica, sans-serif;\n  font-size: 10pt;\n  color: #ffffff;\n  background-color: #669966 !important;\n  -ms-hyphens: auto;\n  -moz-hyphens: auto;\n  -webkit-hyphens: auto;\n  hyphens: auto;\n  padding: 5px;\n}\n\n.bundeslandauswahl H2 {\n  font-size: 14pt;\n  margin-top: 9pt;\n  margin-bottom: 7pt;\n  font-weight: bold;\n}\n\n.bundeslandauswahl .Body-Text {\n  line-height: 14pt;\n  margin-top: 5pt;\n  text-align: justify;\n}\n.bundeslandauswahl .next-button {\n  font-size: 10px;\n  width: 80px;\n  height: 30px;\n  background-color: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
