// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.impressum h1 {
  margin: 0 0 5px 0;
  font-size: 180%;
  background-color: #666;
  text-align: center;
  padding: 4px;
}

.impressum div.text {
  margin: 0 0 5px 0;
  padding: 5px;
  background-color: #666;
}

.impressum * {
  color: White;
}

.impressum a.closeButton {
  margin: 5px 0 5px 0;
  float: right;
  padding: 3px;
  background-color: #666;
  font-size: 80%;
  text-decoration: none;
  border: 2px solid #eee;
  border-bottom-right-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/dataProtection/imprint/impressum.css"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,YAAA;EACA,sBAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,mBAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EACA,cAAA;EACA,qBAAA;EACA,sBAAA;EAGA,gCAAA;AAGF","sourcesContent":[".impressum h1 {\n  margin: 0 0 5px 0;\n  font-size: 180%;\n  background-color: #666;\n  text-align: center;\n  padding: 4px;\n}\n\n.impressum div.text {\n  margin: 0 0 5px 0;\n  padding: 5px;\n  background-color: #666;\n}\n.impressum * {\n  color: White;\n}\n.impressum a.closeButton {\n  margin: 5px 0 5px 0;\n  float: right;\n  padding: 3px;\n  background-color: #666;\n  font-size: 80%;\n  text-decoration: none;\n  border: 2px solid #eee;\n  -webkit-border-bottom-right-radius: 10px;\n  -moz-border-radius-bottomright: 10px;\n  border-bottom-right-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
