import React from 'react';
import './inhaltsverzeichnis.css';
import HelpNavigationMenu from 'app/shared/oldPages/help/dunning/helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Inhaltsverzeichnis = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Inhaltsverzeichnis</title>
      </head>
      <body className="help">
        <HelpNavigationMenu hideBack></HelpNavigationMenu>

        <h2 className="heading-2">
          <a target="inhaltsverzeichnis"></a>Inhaltsverzeichnis
        </h2>

        <ul className="list">
          <li>
            <a href={`hilfe${urlPrefix}/einfuehrung`}>Einführung</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/bedienung`}>Bedienung</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/bundeslandauswahl`}>Bundeslandauswahl</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/antragstellerart`}>Antragstellerauswahl</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/`}>Erfassen eines Prozessbevollmächtigten</a>
            <ul className="list">
              <li>
                <a href={`hilfe${urlPrefix}/rechtsbeistand/eigen`}>Rechtsanwalt/Rechtsbeistand in eigener Sache</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/rechtsbeistand/fremd`}>Rechtsanwalt/Rechtsbeistand</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/rechtsbeistand/gesellschaft`}>Rechtsanwalts-Gesellschaft</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/rechtsbeistand/herr_frau`}>Herr / Frau</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/rechtsbeistand/inkasso`}>Reg. Inkassounternehmen</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/rechtsbeistand/verbraucher`}>Verbraucherzentrale, /-verband</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/rechtsbeistand/rechtsbeistand_vertretung`}>Gesetzlicher Vertreter eines Proz.bevollm.</a>
              </li>
            </ul>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/antragsteller`}>Erfassen eines Antragstellers</a>
            <ul className="list">
              <li>
                <a href={`hilfe${urlPrefix}/antragsteller/ausland`}>Antragsteller im Ausland</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/antragsteller/natuerliche_person`}>Erfassen einer Natürlichen Person</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/antragsteller/firma`}>Erfassen einer Firma</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/antragsteller/kennziffer`}>Erfassen einer Kennziffer</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/antragsteller/sonstige`}>Erfassen eines Sonstigen Antragsteller</a>
                <ul className="list">
                  <li>
                    <a href={`hilfe${urlPrefix}/antragsteller/sonstige/pka`}>Partei kraft Amtes (PKA)</a>
                  </li>
                  <li>
                    <a href={`hilfe${urlPrefix}/antragsteller/sonstige/anwalt_eigen`}>RA in eigener Sache</a>
                  </li>
                  <li>
                    <a href={`hilfe${urlPrefix}/antragsteller/sonstige/weg`}>WEG</a>
                  </li>
                  <li>
                    <a href={`hilfe${urlPrefix}/antragsteller/sonstige/weg_verwalter`}>WEG Verwalter als Antragsteller</a>
                  </li>
                  <li>
                    <a href={`hilfe${urlPrefix}/antragsteller/sonstige/weg_antragsteller`}>WEG als Antragsteller</a>
                  </li>
                  <li>
                    <a href={`hilfe${urlPrefix}/antragsteller/sonstige/weitere`}>Weitere</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/antragsgegner`}>Erfassen eines Antragsgegners</a>
            <ul className="list">
              <li>
                <a href={`hilfe${urlPrefix}/antragsgegner/ausland`}>Antragsgegner im Ausland</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/antragsgegner/natuerliche_person`}>Erfassen einer Natürlichen Person</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/antragsgegner/firma`}>Erfassen einer Firma</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/antragsgegner/sonstige`}>Erfassen eines Sonstigen Antragsgegner</a>
                <ul className="list">
                  <li>
                    <a href={`hilfe${urlPrefix}/antragsgegner/sonstige/pka`}>Partei kraft Amtes</a>
                  </li>
                  <li>
                    <a href={`hilfe${urlPrefix}/antragsgegner/sonstige/weg`}>WEG</a>
                  </li>
                  <li>
                    <a href={`hilfe${urlPrefix}/antragsgegner/sonstige/weg_verwalter`}>WEG Verwalter als Antragsgegner</a>
                  </li>
                  <li>
                    <a href={`hilfe${urlPrefix}/antragsgegner/sonstige/weg_antragsgegner`}>WEG als Antragsgegner</a>
                  </li>
                  <li>
                    <a href={`hilfe${urlPrefix}/antragsgegner/sonstige/weitere`}>Weitere</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand_vertretung`}>Erfassen eines gesetzlichen Vertreters</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/hauptforderungen`}>Angaben zu Hauptforderung und Zinsen</a>
            <ul className="list">
              <li>
                <a href={`hilfe${urlPrefix}/hauptforderungen/katalognummer`}>Katalog-Nummer</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/hauptforderungen/sonstiges`}>Sonstiger Anspruch</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/hauptforderungen/ausgerechneter_zins`}>Ausgerechneter Zins</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/hauptforderungen/laufende_zinsen`}>Laufende Zinsen</a>
              </li>
            </ul>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/auslagen_nebenforderungen`}>Auslagen und Nebenforderungen</a>
            <ul className="list">
              <li>
                <a href={`hilfe${urlPrefix}/auslagen_nebenforderungen/antragsteller`}>Auslagen des Antragstellers für dieses Verfahren</a>
              </li>
              <li>
                <a href={`hilfe${urlPrefix}/auslagen_nebenforderungen/andere`}>Andere Nebenforderungen</a>
              </li>
            </ul>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/prozessgerichte`}>Prozessgerichte</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/allgemeine_angaben`}>Allgemeine Angaben zum Antrag</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/bankverbindung`}>Bankverbindung</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/uebersicht`}>MB-Antrag Übersicht</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/barcode`}>Barcode (PDF)</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/eda`}>EDA-Download</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/eid`}>eID-Personalausweis</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/glossar`}>Glossar</a>
          </li>
        </ul>
        <HelpNavigationMenu hideBack></HelpNavigationMenu>
      </body>
    </html>
  );
};

export default Inhaltsverzeichnis;
