import React from 'react';
import './impressum.css';
const Impressum = () => {
  return (
    <html className="impressum">
      <body>
        <h1>Impressum</h1>

        <div className="text">
          Verantwortlich für die Seite&nbsp;
          <a href="http://www.Online-Mahnantrag.de" target="online_mahnantrag">
            www.Online-Mahnantrag.de
          </a>
          :
        </div>

        <div className="text">
          Justizministerium Baden-Württemberg
          <div>Schillerplatz 4</div>
          <div>70173 Stuttgart </div>
          <div>E-Mail: poststelle@jum.bwl.de </div>
        </div>
        <div className="text">
          Die Pflege und Weiterentwicklung der Software erfolgt im Auftrag durch die beim Justizministerium Baden-Württemberg eingerichtete
          Koordinierungsstelle für die Pflege und Weiterentwicklung des automatisierten Mahnverfahrens.
          <div>E-Mail: poststelle@jum.bwl.de</div>
        </div>

        <a className="closeButton" href="javascript:window.close(true)">
          Fenster schließen
        </a>
      </body>
    </html>
  );
};

export default Impressum;
