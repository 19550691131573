import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Gesetzlichevertreter = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Geschäfts­nummer des Mahngerichts</title>
      </head>
      <body className="generic-help gesetzlichevertreter">
        <HelpNavigationMenu />

        <h2>Erfassen des gesetzlichen Vertreters eines Prozessbevollmächtigten</h2>

        <p>
          Wählen Sie die zu Ihrer Rechtsform passende Funktion des gesetzlichen Vertreters.<p></p>
        </p>
        <p>
          Die namentliche Bezeichnung des gesetzlichen Vertreters ist nicht zwingend erforderlich. Wollen Sie hierzu Angaben machen, so
          geben Sie bitte einen ausgeschrieben Vornamen und den Nachnamen ohne Abkürzungen ein.
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Gesetzlichevertreter;
