import { DropdownValidation, registerValidations, Validation } from 'app/shared/validations/validation';
import React, { SetStateAction } from 'react';
import { SelectOptionActionMeta } from 'react-select';
import { DropDownBoxProps } from 'app/shared/components/validated/drop-down-box/validatedDropdown';
import { ValidatedInputProps } from './validatedInput';

export type GeneralValidatedProps = {
  name: string;
  externalErrors?: string[];
  isOptional?: boolean;
};

export type ValidatedInputBaseProps<T> = GeneralValidatedProps & {
  onBlur?: React.FocusEventHandler<T>;
  customValidations?: ((props: ValidationProps) => Promise<string[]>)[];
  validations: (typeof Validation)[];
  registeredValidations: RegisteredValidationMap;
  setRegisteredValidations: React.Dispatch<SetStateAction<RegisteredValidationMap>>;
  validationKey?: string;
};

export type ValidatedDropdownBaseProps = GeneralValidatedProps & {
  onChange?: (newValue: SelectNewValue, actionMeta: SelectActionMeta) => void;
  customValidations?: ((props: ValidationProps) => Promise<string[]>)[];
  validations?: (typeof DropdownValidation)[];
  validationKey?: string;
  disabled?: boolean;
};

export type RegisteredValidationMap = { [key: string]: RegisteredValidation };

export interface RegisteredValidation {
  externalErrors: string[];
  maxLength: number;
  name: string;
  value: any;
  validations: (typeof Validation)[];
  customValidations: ((props: ValidationProps) => Promise<string[]>)[];
  setErrors: React.Dispatch<SetStateAction<string[]>>;
  errors: string[];
  isOptional: boolean;
  validationKey?: string;
}

export const getErrorsForTooltip = (errors: string[]) => {
  return errors.map(e => {
    return [React.createElement('span', {}, e), React.createElement('br')];
  });
};

export function generateBlur<T extends HTMLInputElement>(props: ValidatedInputProps, errors, setErrors) {
  return async (e: React.FocusEvent<T>) => {
    if (props.onBlur) props.onBlur(e);
    const validationsProps: ValidationProps = {
      name: props.name,
      newValue: e.target.value,
      isOptional: props.isOptional,
      errors,
      setErrors,
      validations: props.validations,
      customValidations: props.customValidations,
      maxLength: props.maxLength,
    };

    const foundErrors = [...(props.externalErrors || []), ...(await runValidations(validationsProps))];

    setErrors(foundErrors);
  };
}

export type SelectNewValue = {
  id: string;
  label: string;
  value: string;
  name: string;
};

export type SelectActionMeta = SelectOptionActionMeta<SelectNewValue>;

export function generateDropdownChange(props: ValidatedDropdownBaseProps, onChange, errors, setErrors) {
  return (newValue: SelectNewValue, actionMeta: SelectActionMeta) => {
    if (onChange) onChange(newValue, actionMeta);

    const dropdownValidationProps = {
      name: props.name,
      newValue: newValue.value,
      actionMeta,
      errors,
      setErrors,
      validations: props.validations,
      customValidations: props.customValidations,
    };

    runValidations(dropdownValidationProps);
  };
}

export interface ValidationProps {
  name: string;
  errors: string[];
  setErrors: React.Dispatch<SetStateAction<string[]>>;
  newValue: string;
  actionMeta?: SelectActionMeta;
  isOptional?: boolean;
  validations: (typeof Validation)[];
  customValidations?: ((props: ValidationProps) => Promise<string[]>)[];
  maxLength?: number;
  renderTrigger?: string;
}

export async function runValidations(props: ValidationProps): Promise<string[]> {
  const foundErrors = [];

  if (props.customValidations && props.customValidations.length > 0) {
    for (const validation of props.customValidations) {
      foundErrors.push(...(await validation(props)));
    }
  }

  if (props.validations) {
    for (const validation of props.validations) {
      foundErrors.push(...(await validation.validate(props)));
    }
  }

  props.setErrors(foundErrors);
  return foundErrors;
}

export function registerValidationComponent(
  props: ValidatedInputProps | DropDownBoxProps,
  errors: string[],
  setErrors: React.Dispatch<SetStateAction<string[]>>,
) {
  if ((props.validations && props.validations.length > 0) || (props.customValidations && props.customValidations.length > 0)) {
    const runValidation =
      props.registeredValidations[props.name] !== undefined && props.registeredValidations[props.name].isOptional !== props.isOptional;

    const maxLength = 'maxLength' in props ? props.maxLength : undefined;

    registerValidations(props, errors, setErrors);

    if (runValidation) {
      // In case some fields change their optionality based on other values (like on the attorneyOfRecord page)
      runValidations({
        name: props.name,
        errors,
        setErrors,
        newValue: props.value as string,
        isOptional: props.isOptional,
        validations: props.validations,
        customValidations: props.customValidations,
        maxLength,
      });
    }
  }
}

export const getDomProps = props => {
  const DOMprops = { ...props };
  delete DOMprops['isOptional'];
  delete DOMprops['externalErrors'];
  delete DOMprops['customValidations'];
  delete DOMprops['validations'];
  delete DOMprops['setRegisteredValidations'];
  delete DOMprops['registeredValidations'];
  delete DOMprops['validationKey'];
  delete DOMprops['maskOptions'];
  return DOMprops;
};
