import React from 'react';
import { contentSelector } from 'app/shared/layout/side-container/side-container-slice';
import { useAppSelector } from 'app/config/store';
import { Translate } from 'react-jhipster';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface BasicSideContainerContent {
  description: string;
  button?: {
    show: boolean;
    text?: string;
    icon?: IconProp;
  };
}
export const BasicSideContainer = () => {
  const content: BasicSideContainerContent = useAppSelector(contentSelector);
  return (
    <div id={'basic_side_container'}>
      <p>
        <Translate contentKey={content.description} />
      </p>
      {content.button?.show && (
        <Button>
          {content.button.icon && <FontAwesomeIcon icon={content.button.icon} />}
          {content.button.text && (
            <div style={{ marginLeft: content.button.icon ? '5px' : '0px', display: 'inline-flex' }}>
              <Translate contentKey={content.button.text} />
            </div>
          )}
        </Button>
      )}
    </div>
  );
};
export default BasicSideContainer;
