import React from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-jhipster';
import { Button, Col, Row, Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { RegisteredValidationMap, runValidations, ValidationProps } from 'app/shared/components/validated/validatedComponent';

export interface IDunningNavigation {
  handleOnBackClick?: () => void;
  handleOnNextClick?: () => void;
  nameSuffix?: string;
  hideBack?: boolean;
  hideNext?: boolean;
  registeredValidations: RegisteredValidationMap;
}

const DunningNavigation = (props: IDunningNavigation) => {
  const navigate = useNavigate();
  const defaultBack = () => navigate(-1);

  const nextFunction = async () => {
    const foundErrors = [];

    for (const registered of Object.values(props.registeredValidations)) {
      const { value, validations, customValidations, errors, setErrors } = registered;

      const validationProps: ValidationProps = {
        name: registered.name,
        newValue: value,
        validations,
        customValidations,
        errors,
        setErrors,
        isOptional: registered.isOptional,
        maxLength: registered.maxLength,
      };

      foundErrors.push(...(await runValidations(validationProps)));
    }

    if (foundErrors.length > 0) return;

    props.handleOnNextClick();
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col xs={6}>
          {!props.hideBack && (
            <Button
              name={'zurück' + (props.nameSuffix || '')}
              id={'back-button' + (props.nameSuffix || '')}
              onClick={props.handleOnBackClick || defaultBack}
            >
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> <Translate contentKey="generic.back" />
            </Button>
          )}
        </Col>

        <Col xs={6} className="next-button-col">
          {!props.hideNext && (
            <Button
              name={'weiter' + (props.nameSuffix || '')}
              id={'next-button' + (props.nameSuffix || '')}
              onClick={() => {
                nextFunction();
              }}
            >
              <Translate contentKey="generic.next" /> <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DunningNavigation;
