import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';

const Kennziffer = () => {
  return (
    <html>
      <head>
        <title>Erfassen einer Kennziffer</title>
      </head>
      <body className="antragsteller kennziffer">
        <HelpNavigationMenu />

        <h2>
          <a target="kenziffer"></a>Erfassen einer Kennziffer
        </h2>

        <p className="Body-Text">
          Sie, als Antragsteller (aber auch als Rechtsanwalt i.e.S. und als Antragsteller-Prozessvertreter), haben die Möglichkeit eine
          Antragsteller-Kennziffer zu beantragen. Diese Kennziffer können Sie formlos bei dem für Sie zuständigen Mahngericht bzw. zentralen
          Mahngericht beantragen. Das Gericht wird Ihnen Ihre Kennziffer mitteilen.
        </p>

        <p className="Body-Text">Haben Sie eine solche Kennziffer als Antragsteller erhalten, so geben Sie diese wie folgt ein: </p>

        <p className="Body-Text">
          Sie wählen beim Erfassen des Antragstellers den Reiter &quot;Kennziffer&quot; aus. In dieser Registerkarte geben Sie die Ihnen vom
          Gericht vorgegebene Antragstellerkennziffer ein (8-stellig).
        </p>

        <p className="Body-Text">
          Weitere Angaben zum Antragsteller müssen dann nicht mehr gemacht werden, da Sie durch die Beantragung und der dabei gemachten
          Angaben der Antragstellerkennziffer beim Gericht bekannt sind.
        </p>

        <p className="Body-Text">Für die Erteilung einer Kennziffer entstehen keine Kosten.</p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Kennziffer;
