import './hinweis.css';
import React from 'react';

const Chrome = () => {
  return (
    <html>
      <head>
        <title>Hinweis zu Google Chrome</title>
      </head>
      <body className="hinweis chrome">
        <h1>Hinweis zu Google Chrome</h1>

        <div className="text">Mit dem in Google Chrome integrierten PDF Viewer können Sie den Barcodeantrag nicht korrekt ausdrucken.</div>

        <div className="text">
          In den neueren Versionen von Google Chrome (ab September 2015) können Sie den Adobe Acrobat Reader nicht mehr als Plug-in
          installieren. Speichern Sie dann den Barcode-Antrag im PDF-Format und öffnen ihn im Acrobat Reader.
        </div>

        <div className="text">
          In den älteren Versionen von Google Chrome (bis August 2015) können Sie den Adobe Acrobat Reader incl. Plug-in installieren und
          den Google Chrome PDF Viewer folgendermaßen deaktivieren: <div></div>
          Geben Sie in die Google Chrome Adressleiste &quot;about:plugins&quot; ein und klicken unterhalb von &quot;Chrome PDF Viewer&quot;
          auf deaktivieren. Das Plug-in für den Adobe Acrobat Reader wird automatisch aktiviert, falls es installiert ist.
        </div>

        <a className="closeButton" href="javascript:window.close(true)">
          Fenster schließen
        </a>
      </body>
    </html>
  );
};

export default Chrome;
