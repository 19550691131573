import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import './antragsgegner.css';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';

const Antragsgegner = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Erfassen eines Antragsgegners</title>
      </head>
      <body className="antragsgegner">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="antragsgegner"></a>Erfassen eines Antragsgegners
        </h2>

        <p className="Body-Text">
          In der Maske Angaben zum Antragsgegner wird festgelegt welche Rechtsform der Antragsgegner hat. Es ist eine der folgenden
          Zuweisungsmöglichkeiten auszuwählen: Herr/Frau (natürliche Person), Firma oder sonstige. Im Folgenden wird festgelegt wer der
          Antragsgegner ist.
        </p>

        <p className="Body-Text">
          Haben Sie die entsprechenden Antragsgegnerdaten eingegeben, so bestätigen Sie die Eingabe mit Klick auf die{' '}
          <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
        </p>

        <p className="Body-Text">
          Sie werden gefragt, ob für den Antragsgegner noch ein{' '}
          <a href={`hilfe${urlPrefix}/rechtsbeistand_vertretung`}>gesetzlicher Vertreter</a> erfasst werden soll. Hier haben Sie die
          Möglichkeiten <b>JA</b> oder <b>NEIN</b>. Bei einigen Rechtsformen entfällt diese Abfrage, weil die Angabe eines gesetzlichen
          Vertreters Pflicht ist. In diesen Fällen werden Sie automatisch zur Erfassung der erforderlichen Angaben aufgefordert.
        </p>

        <p className="Body-Text">
          Nachdem Sie einen Antragsgegner erfasst haben, werden Sie gefragt, ob Sie einen weiteren Antragsgegner erfassen möchten. Auch hier
          besteht die Wahlmöglichkeit zwischen <b>JA</b> oder <b>NEIN</b>.
        </p>

        <p className="RelatedHead">
          <p></p>
        </p>
        <p className="RelatedHead">
          <b>Siehe auch:</b>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsgegner/ausland`}>Antragsgegner im Ausland</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsgegner/natuerliche_person`}>Erfassen einer Natürlichen Person</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsgegner/firma`}>Erfassen einer Firma</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsgegner/sonstige`}>Erfassen eines Sonstigen Antragsgegners</a>
        </p>
        <p className="RelatedHead">
          <p></p>
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Antragsgegner;
