import { IRootState } from 'app/config/store';
import { useSelector } from 'react-redux';
import { IInitialState } from 'app/shared/reducers/requeststore.interface';
import axios from 'axios';

export const currencyFormatter = Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'code',
  minimumFractionDigits: 2,
});

export const percentageFormatter = Intl.NumberFormat('de-DE', {
  style: 'percent',
  maximumFractionDigits: 3,
});

export const currencySuffix = 'EUR';
export const currencyPlaceholder = `0 ${currencySuffix}`;

export const percentageSuffix = '%';
export const percentagePlaceholder = `0 ${percentageSuffix}`;

export const useBaseUrl = () => {
  return useSelector((state: IRootState) => state.requestStore.urlPrefix);
};

export const defaultTextLength = 35;
export const splitText = (input, maxLength) => {
  const splitString = input.split('\n');
  for (let i = 0; i < splitString.length; i++) {
    if (splitString[i].length > maxLength) {
      const overflow = splitString.substring(maxLength);
      splitString[i] = splitString[i].substring(0, maxLength);
      insertIntoArray(splitString, i + 1, overflow);
    }
  }
};

export const joinText = (items: string[], separator: string): string => {
  return items.join(separator);
};

export const insertIntoArray = (array: any[], index: number, ...items: any[]): any[] => {
  return [...array.slice(0, index), items, ...array.slice(index)];
};

export const downloadFile = (content, name) => {
  const href = URL.createObjectURL(content);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const processAndDownloadEda = (requestStoreData: IInitialState) => {
  processAndDownloadFile(requestStoreData, '/eda/process_' + requestStoreData.type + '?debug=true');
};

export const processAndDownloadBarcode = (requestStoreData: IInitialState) => {
  processAndDownloadFile(requestStoreData, '/barcode/process_' + requestStoreData.type + '?debug=true');
};

const processAndDownloadFile = (requestStoreData: IInitialState, processUrl: string) => {
  axios
    .request({
      url: processUrl,
      data: requestStoreData,
      method: 'POST',
      responseType: 'blob',
    })
    .then(response => {
      const filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0].replace('"', '');
      const extension = response.headers['content-disposition'].split('.')[1].split(';')[0].replace('"', '');

      downloadFile(response.data, filename + '.' + extension);
    })
    .catch(exception => {
      downloadFile(exception.response.data, 'exception.zip');
    });
};

export const getCourt = (angelegenheit: string, postCode: string, city: string, isManual: boolean) => {
  const method = isManual ? 'getCourt' : 'findCourt';
  const url = `/configuration/${method}?angelegenheit=${angelegenheit}&zipCode=${postCode}&city=${city}`;

  return axios.request({
    method: 'get',
    url,
  });
};

export interface IStateHotline {
  states: string[];
  hotlineNumber: string;
}

export const lkzToStates = new Map<string, IStateHotline>([
  ['01', { states: ['sh'], hotlineNumber: '04621 815-0 Sprechzeit: Mo.-Fr. 9:00-12:00 Uhr' }],
  ['02', { states: ['hh', 'mv'], hotlineNumber: '040 42811-1462, -1580, -1658 u. -3568. Sprechzeit: Mo.-Fr. 9:00-' }],
  ['03', { states: ['ni'], hotlineNumber: '0581 8851-0' }],
  ['04', { states: ['hb'], hotlineNumber: '0421 361-6115 (09.00 - 12.30 Uhr)' }],
  ['05', { states: ['nw'], hotlineNumber: '0211 837-1940' }],
  ['06', { states: ['he'], hotlineNumber: '06652 600-01' }],
  ['07', { states: ['rp', 'sl'], hotlineNumber: '02651 403-0' }],
  ['08', { states: ['bw'], hotlineNumber: '0711 921-3567' }],
  ['09', { states: ['by'], hotlineNumber: '09561 878-5' }],
  ['11', { states: ['be', 'bb'], hotlineNumber: '030 90156-0' }],
  ['23', { states: ['st', 'sn', 'th'], hotlineNumber: '03925 876-0' }],
]);

export const stateToLKZ = new Map<string, string>([
  ['sh', '01'],
  ['hh', '02'],
  ['mv', '02'],
  ['ni', '03'],
  ['hb', '04'],
  ['nw', '05'],
  ['he', '06'],
  ['rp', '07'],
  ['sl', '07'],
  ['bw', '08'],
  ['by', '09'],
  ['be', '11'],
  ['bb', '11'],
  ['st', '23'],
  ['sn', '23'],
  ['th', '23'],
]);

export const getDunningCourtDescriptionData = () => {
  const url = '/configuration/getDunningCourtDescriptionData';
  return axios.request({
    method: 'get',
    url,
  });
};
