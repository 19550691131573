// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basiszins {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 100%;
  background-color: #aaa;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 10pt;
  display: flex;
  justify-content: center;
}

.basiszins table {
  border: 3px solid #cccccc;
  display: table;
  box-sizing: border-box;
  text-indent: initial;
  unicode-bidi: isolate;
  border-spacing: 2px;
  background-color: #666666;
}

.basiszins tr {
  display: table-row;
  vertical-align: inherit;
  unicode-bidi: isolate;
  border-color: inherit;
}

.basiszins td {
  padding: 2px 5px 2px 5px;
  border: 3px solid #cccccc;
}

.basiszins th {
  padding: 2px 5px 2px 5px;
  border: 3px solid #cccccc;
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
  unicode-bidi: isolate;
}

.basiszins tbody {
  display: table-row-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-color: inherit;
}

.basiszins h1 {
  margin: 0 0 5px 0;
  text-align: center;
  padding: 4px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/dunning/basiszins/basisZins.css"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,sBAAA;EACA,YAAA;EACA,8BAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;AACF;;AACA;EACE,yBAAA;EACA,cAAA;EACA,sBAAA;EACA,oBAAA;EACA,qBAAA;EACA,mBAAA;EACA,yBAAA;AAEF;;AACA;EACE,kBAAA;EACA,uBAAA;EACA,qBAAA;EACA,qBAAA;AAEF;;AACA;EACE,wBAAA;EACA,yBAAA;AAEF;;AACA;EACE,wBAAA;EACA,yBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,4BAAA;EACA,qBAAA;AAEF;;AACA;EACE,wBAAA;EACA,sBAAA;EACA,qBAAA;EACA,qBAAA;AAEF;;AACA;EACE,iBAAA;EACA,kBAAA;EACA,YAAA;AAEF","sourcesContent":[".basiszins {\n  text-align: center;\n  margin-left: auto;\n  margin-right: auto;\n  font-size: 100%;\n  background-color: #aaa;\n  color: white;\n  font-family: Arial, sans-serif;\n  font-size: 10pt;\n  display: flex;\n  justify-content: center;\n}\n.basiszins table {\n  border: 3px solid #cccccc;\n  display: table;\n  box-sizing: border-box;\n  text-indent: initial;\n  unicode-bidi: isolate;\n  border-spacing: 2px;\n  background-color: #666666;\n}\n\n.basiszins tr {\n  display: table-row;\n  vertical-align: inherit;\n  unicode-bidi: isolate;\n  border-color: inherit;\n}\n\n.basiszins td {\n  padding: 2px 5px 2px 5px;\n  border: 3px solid #cccccc;\n}\n\n.basiszins th {\n  padding: 2px 5px 2px 5px;\n  border: 3px solid #cccccc;\n  display: table-cell;\n  vertical-align: inherit;\n  font-weight: bold;\n  text-align: -internal-center;\n  unicode-bidi: isolate;\n}\n\n.basiszins tbody {\n  display: table-row-group;\n  vertical-align: middle;\n  unicode-bidi: isolate;\n  border-color: inherit;\n}\n\n.basiszins h1 {\n  margin: 0 0 5px 0;\n  text-align: center;\n  padding: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
