import React from 'react';
import './bedienung.css';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { faBackward } from '@fortawesome/pro-solid-svg-icons/faBackward';
import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons/faUserTie';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faMoneyBill } from '@fortawesome/pro-solid-svg-icons/faMoneyBill';
import { faFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Bedienung = () => {
  return (
    <div className="bedienung">
      <HelpNavigationMenu />
      <h2 className="heading-2">
        <a href="#bundeslandauswahl"></a>Bedienung
      </h2>
      <p className="Body-Text">
        Mit der Weiter-Schaltfläche vom Online-Mahnantrag <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> bestätigen
        Sie Ihre Eingabe und gelangen so zum nächsten Teilschritt. Wenn Sie nicht die{' '}
        <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche betätigen, werden Ihre Eingaben nicht übernommen.
      </p>
      <p className="Body-Text">
        Mit dem Zurück-Button von Online-Mahnantrag <FontAwesomeIcon className="image-icon" icon={faBackward} /> springen Sie zum Anfang des
        vorherigen Schrittes. D.h., wenn Sie sich in der Erfassungmaske für laufende Zinsen (ein Teilschritt von &quot;Anspruch/Forderung
        erfassen&quot;) befinden, springen Sie zum Beginn von &quot;Antragsgegner erfassen&quot;.
      </p>
      <p className="note">
        <em>Hinweis:</em> Benutzen bitte nicht den Zurück-Button des Browsers. Der Zurück-Button des Browsers hat eine andere Funktion. Der
        Browser kennt nicht den Status der Anwendung. Falsche Anzeigen sind die Folge.
      </p>
      <p className="Body-Text">Hier noch einmal alle Schritte:</p>
      <ol>
        <li>
          Schritt: <FontAwesomeIcon className="image-icon" icon={faUserTie} style={{ color: '#327775' }} /> Prozessbevollmächtigten erfassen
          (sofern vorhanden)
        </li>
        <li>
          Schritt: <FontAwesomeIcon className="image-icon" icon={faUser} style={{ color: '#327775' }} /> Antragstellerdaten erfassen
        </li>
        <li>
          Schritt: <FontAwesomeIcon className="image-icon" icon={faUser} style={{ color: '#791311' }} /> Antragsgegner erfassen
        </li>
        <li>
          Schritt: <FontAwesomeIcon className="image-icon" icon={faFileInvoiceDollar} style={{ color: 'black' }} /> Anspruch/Forderung
          erfassen
        </li>
        <li>
          Schritt: <FontAwesomeIcon className="image-icon" icon={faMoneyBill} style={{ color: 'black' }} /> Auslagen und Nebenforderung
          erfassen (sofern vorhanden)
        </li>
        <li>
          Schritt: <FontAwesomeIcon className="image-icon" icon={faBook} style={{ color: 'black' }} /> Allgemeine Angaben zum Antrag
        </li>
        <li>
          Schritt: <FontAwesomeIcon className="image-icon" icon={faBuilding} style={{ color: 'black' }} /> Überprüfen der Antragsdaten
        </li>
        <li>
          Schritt: <FontAwesomeIcon className="image-icon" icon={faPrint} style={{ color: 'black' }} /> Druck/Antragsabgabe
        </li>
      </ol>
      <p className="Body-Text">Sie können natürlich jederzeit durch Verwendung des Menues zum gewünschten Schritt springen.</p>
      <p className="Body-Text">
        Sie können zu jedem Schritt aus dem Menue die Hilfe aufrufen. Zu den Teilschritten erhalten Sie eine detaillierte Hilfe über den
        dort jeweils angebotenen Hilfe-Button.
      </p>
      <p className="Body-Text">
        Sollten Sie eine <b>Fehlermeldung</b> erhalten, so korrigieren Sie Ihre Eingabe und betätigen erneut die{' '}
        <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
      </p>
      <p className="Body-Text">
        Sollten Sie eine <b>Warnung</b> erhalten, so können Sie diese durch erneutes Betätigen der{' '}
        <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche überspringen und Ihre Eingaben übernehmen. Sie
        sollten die Warnmeldung nur dann überspringen, wenn Sie sicher sind, dass Ihre Eingaben korrekt sind. Andernfalls ändern Sie bitte
        Ihre Eingaben und betätigen erneut die <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
      </p>
      <p className="note">
        <em>Hinweis:</em> Vermeiden Sie bei der Arbeit mit dem Online-Mahnantrag Arbeitspausen von mehr als 30 Minuten. Wenn Sie länger als
        30 Minuten nicht mit dem Online-Mahnantrag arbeiten, wird die Session auf dem Server beendet und die von Ihnen eingegebenen Daten
        gehen verloren.
      </p>
      <HelpNavigationMenu />
    </div>
  );
};

export default Bedienung;
