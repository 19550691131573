import './eid.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Eid = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Personalausweis</title>
      </head>
      <body className="eid">
        <HelpNavigationMenu />

        <a target="eid"></a>

        <h2 className="heading-2" title="Personalausweis">
          Online-Übertragung mit Online-Ausweisfunktion (eID)
        </h2>

        <h3 className="heading-5">
          <a target="voraussetzungen"></a>Voraussetzungen für die Online-Übertragung unter Nutzung des neuen Personalausweises oder des
          elektronischen Aufenthaltstitels
        </h3>
        <p className="Body-Text">
          <img
            src="content/images/personal-id.png"
            alt="Logo Personalausweis"
            title="Logo Personalausweis - Quelle: Bundesministerium des Innern"
            className="ausweis-logo"
          />
          Sie benötigen einen <a href={`hilfe${urlPrefix}/eid`}>Personalausweis</a> oder einen elektronischen Aufenthaltstitel mit
          aktivierter Online-Ausweisfunktion (eID-Funktion) oder eine eID-Karte für Unionsbürger, ein NFC-fähiges Smartphone oder ein
          USB-Kartenlesegerät und die <a href={`hilfe${urlPrefix}/eid`}>AusweisApp</a>.
        </p>
        <div className="clearBoth"></div>
        <p></p>

        <p className="note">
          <strong>Hinweis:</strong> Erklärungen und Prozesshandlungen können in einem Verfahren nach der Zivilprozessordnung nur von
          volljährigen Personen abgegeben oder vorgenommen werden, §52 ZPO. Diese Funktion steht daher Minderjährigen nicht zur Verfügung.
        </p>

        <p className="Body-Text">
          Der Online-Mahnantrag verwendet zur Übermittlung der Daten die Anwendung Autent ID Connect der Governikus KG. Autent ID Connect
          übermittelt die ausgelesenen Daten aus der AusweisApp an den Online-Mahnantrag. Bitte wählen sie dort die AusweisApp-Funktion und
          folgen Sie den weiteren Anweisungen.
        </p>

        <p className="Body-Text">
          Nach der Übermittlung Ihrer Ausweisdaten können Sie die AusweisApp schließen. Im Online-Mahnantrag geben Sie noch an, ob Sie der
          Antragsteller, der gesetzliche Vertreter oder ein Bevollmächtigter des Antragstellers sind. Durch betätigen der
          Versenden-Schaltfläche wird Ihr Antrag an das zuständige Mahngericht versendet.
        </p>

        <h3 className="heading-5">
          <a target="perso"></a>Neuer Personalausweis
        </h3>
        <p className="Body-Text">
          Seit 1. November 2010 gibt es den Personalausweis in einem anderen Format und mit neuen Funktionen. Die Online-Ausweisfunktion
          können Sie, sofern noch nicht geschehen, bei Ihrem Bürgeramt aktivieren lassen.
          <div></div>Weitere Einzelheiten finden Sie im<div></div>{' '}
          <a href="https://www.personalausweisportal.de/Webs/PA/DE/startseite/startseite-node.html" target="_blank" rel="noreferrer">
            Personalausweisportal des Bundesinnenministeriums
          </a>
          .
        </p>

        <h3 className="heading-5">
          <a target="app"></a>AusweisApp
        </h3>
        <p className="Body-Text">
          Die AusweisApp ist eine Software, die Sie auf Ihrem Computer installieren, um sich mit Ihrem Personalausweis bzw. Ihrem
          elektronischen Aufenthaltstitel online auszuweisen.
          <p></p>
          <p></p>
          Weitere Informationen und die AusweisApp erhalten Sie unter:
          <a href="https://www.ausweisapp.bund.de" target="_blank" rel="noreferrer">
            https://www.ausweisapp.bund.de
          </a>
          .<p></p>
          <p></p>
          Falls Schwierigkeiten bei der Nutzung der AusweisApp (Installation, Aufruf, Verbindung) auftreten, können Sie sich an das
          Support-Team für die AusweisApp wenden. Montags bis Freitags in der Zeit von 9.00 Uhr bis 17.00 Uhr werden Ihre Fragen bearbeitet
          und beantwortet. Ausgenommen sind gesetzliche Feiertage.
          <div></div>
          Mail: <a href="mailto:support@ausweisapp.de">support@ausweisapp.de</a>
        </p>

        <table className="back-to-top-table">
          <tbody>
            <tr>
              <td className="back-to-top-cell">
                <a className="small" href={`hilfe${urlPrefix}/eid`}>
                  nach oben
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Eid;
