import './rechtsbeistandVertretung.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RechtsbeistandVertretung = () => {
  return (
    <html>
      <head>
        <title>Erfassen eines gesetzlichen Vertreters</title>
      </head>
      <body className="rechtsbeistand-vertretung">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="rechtsbeistand_vertretung"></a>Erfassen eines gesetzlichen Vertreters
        </h2>

        <p className="Body-Text">Sie sehen den Antragsteller/Antragsgegner, für den ein gesetzlicher Vertreter erfasst werden soll.</p>

        <p className="Body-Text">
          In dem Feld &quot;Funktion&quot; wählen Sie aus der vorgegebenen Liste die Funktion aus, die der gesetzliche Vertreter ausfüllt.
        </p>

        <p className="Body-Text">
          <b>Sonderfälle:</b> Bei der Rechtsform GmbH &amp; Co KG (oder ähnlichen Rechtsformen) wird beim ersten gesetzlichen Vertreter
          keine Funktionsliste angezeigt. Es müssen 2 gesetzliche Vertreter eingegeben werden. Der erste gesetzliche Vertreter ist der
          persönlich haftende Gesellschafter der GmbH &amp; Co KG, also die GmbH. Als zweiten gesetzlichen Vertreter geben Sie bitte den
          gesetzlichen Vertreter der GmbH ein.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Vor- und Nachnamen&quot; geben Sie den kompletten Vor- und Nachnamen (ohne Abkürzungen) des gesetzlichen Vertreters
          an.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Straße/Hausnummer&quot; und &quot;PLZ/Ort/Ausl.Kz.&quot; geben Sie die Adresse des gesetzlichen Vertreters ein. Das
          Auslandskennzeichen ist nur anzugeben, wenn der Wohnsitz nicht in Deutschland liegt (nur beim gesetzlichen Vertreter eines
          Antragstellers). Befindet sich der Wohnsitz in Deutschland, so soll kein Auslandskennzeichen angegeben werden.
        </p>

        <p className="Body-Text">
          Haben Sie alle Eingaben getätigt so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
        </p>

        <p className="Body-Text">
          Je nach Rechtsform des Antragstellers/Antragsgegners sind nicht alle Angaben erforderlich. Sollten Sie einen notwendigen Eintrag
          nicht gemacht haben, dessen Angabe aber erforderlich ist, so werden Sie darauf hingewiesen.
        </p>

        <p className="Body-Text">
          Sie werden gefragt, ob Sie einen weiteren gesetzlichen Vertreter erfassen möchten. Hier haben Sie die Möglichkeiten <b>JA</b> oder{' '}
          <b>NEIN</b>. Bei einigen Rechtsformen entfällt diese Abfrage, weil die Angabe eines weiteren gesetzlichen Vertreters nicht
          möglich/sinnvoll ist.
        </p>

        <p className="Body-Text">
          <i>Beispiel:</i>
        </p>

        <p className="List">Amtsvormund</p>

        <p className="List">Manfred Muster</p>

        <p className="List">Musterstr. 89</p>

        <p className="List">
          88888 Musterstadt<p></p>&nbsp;
        </p>

        <p className="Body-Text">
          <strong>Sonderfall Wohnungeigentümergemeinschaft:</strong> Wird die WEG durch eine juristische Person als Verwalter gesetzlich
          vertreten, so ist zunächst die Rechtsform und der vollständige Firmenname der Verwaltungsgesellschaft einzutragen. Handelt es sich
          dabei um eine GmbH &amp; Co KG , so ist im Firmenname neben der GmbH &amp; Co KG auch die vertretende GmbH zu bezeichnen.
          Eintragungsbeispiel: &quot;HausConsult GmbH &amp; Co KG, vertr. d. d. HausVermögens-GmbH&quot;. Verfahren Sie in gleicher Wei-se
          bei ähnlichen Rechtsformen, z.B. AG &amp; Co KG, OHG &amp; Co KG usw.
        </p>

        <p className="Body-Text">
          Den gesetzlichen Vertreter der Verwaltungsgesellschaft oder der Komplementärin erfassen Sie bitte als weiteren gesetzlichen
          Vertreter mit Funktion und Name.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default RechtsbeistandVertretung;
