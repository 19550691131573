import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavDropdown } from './menu-components';
import { Translate, translate } from 'react-jhipster';
import { useAppSelector } from 'app/config/store';
import { useDispatch } from 'react-redux';
import { setPrintAndSignType } from 'app/shared/reducers/requeststore';
import { printAndSignType } from 'app/modules/generic/printAndSign/printAndSign';
import { RequestTypes } from 'app/config/constants';

export const PrintAndSignMenu = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  const requestType = useAppSelector(state => state.requestStore.type);
  const dispatch = useDispatch();
  const menuEntries = new Map<string, string>();
  const mainLabelForTranslateKey = 'menu.print-and-sign';
  const barcodeLabel = `${mainLabelForTranslateKey}.${getBarcodeTranslationLabel()}`;
  menuEntries.set(`${mainLabelForTranslateKey}.data-overview`, '/uebersicht');
  menuEntries.set(barcodeLabel, '/drucken_signieren');
  menuEntries.set(`${mainLabelForTranslateKey}.eda-download`, '/drucken_signieren');
  menuEntries.set(`${mainLabelForTranslateKey}.eld-staff-selection`, '/drucken_signieren');

  function getBarcodeTranslationLabel() {
    if (requestType === RequestTypes.Dunning || requestType === RequestTypes.Objection) {
      return 'barcode';
    } else if (
      requestType === RequestTypes.RedeliveryDunning ||
      requestType === RequestTypes.Enforcement ||
      requestType === RequestTypes.RedeliveryEnforcement
    ) {
      return 'barcode-pdf';
    }
  }

  function handleOnClick(key: string) {
    if (key === barcodeLabel) {
      dispatch(setPrintAndSignType(printAndSignType.barcode));
    } else if (key === `${mainLabelForTranslateKey}.eda-download`) {
      dispatch(setPrintAndSignType(printAndSignType.edaDownload));
    } else if (key === `${mainLabelForTranslateKey}.eld-staff-selection`) {
      dispatch(setPrintAndSignType(printAndSignType.eldStaffSelection));
    }
  }

  return (
    <NavDropdown name={translate('menu.print-and-sign.label')} id="print-and-sign-dropdown">
      {[...menuEntries.entries()].map(([key, value]) => (
        <DropdownItem key={key} href={`${urlPrefix}${value}`} onClick={() => handleOnClick(key)}>
          <Translate contentKey={key} />
        </DropdownItem>
      ))}
    </NavDropdown>
  );
};
