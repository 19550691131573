// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hauptforderungen.katalognummern table {
  border: 2px solid;
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  unicode-bidi: isolate;
  border-spacing: 2px;
  border-color: gray;
}

.hauptforderungen.katalognummern tr {
  border: 2px solid black;
  display: table-row;
  vertical-align: inherit;
  unicode-bidi: isolate;
  border-color: inherit;
}

.hauptforderungen.katalognummern th,
.hauptforderungen.katalognummern td {
  border: 1px solid #000;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/dunning/hauptforderungen/katalognummer/katalognummern/katalognummern.css"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;EACA,yBAAA;EACA,sBAAA;EACA,oBAAA;EACA,qBAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,uBAAA;EACA,kBAAA;EACA,uBAAA;EACA,qBAAA;EACA,qBAAA;AACF;;AAEA;;EAEE,sBAAA;AACF","sourcesContent":[".hauptforderungen.katalognummern table {\n  border: 2px solid;\n  display: table;\n  border-collapse: separate;\n  box-sizing: border-box;\n  text-indent: initial;\n  unicode-bidi: isolate;\n  border-spacing: 2px;\n  border-color: gray;\n}\n\n.hauptforderungen.katalognummern tr {\n  border: 2px solid black;\n  display: table-row;\n  vertical-align: inherit;\n  unicode-bidi: isolate;\n  border-color: inherit;\n}\n\n.hauptforderungen.katalognummern th,\n.hauptforderungen.katalognummern td {\n  border: 1px solid #000;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
