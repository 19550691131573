import React from 'react';
import './programminfo.css';
import copyrightData from '../../../../copyright/copyright.json';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CopyrightData {
  library: string;
  licenseName: string;
  version: string;
  author: string;
  copyright: string;
  licenseLink: string;
}

const ProgrammInfo = () => {
  const data: CopyrightData[] = [...copyrightData].sort((a, b) => a.library.localeCompare(b.library));
  return (
    <div className="programminfo">
      <ol>
        {data.map((currentCopyrightData, index) => (
          <li key={index}>
            <h4>
              {currentCopyrightData.library} v{currentCopyrightData.version}
            </h4>
            <div>{currentCopyrightData.licenseName}</div>
            {currentCopyrightData.author !== '' && currentCopyrightData.author && (
              <div>
                {currentCopyrightData.copyright} {currentCopyrightData.author}
              </div>
            )}
            {currentCopyrightData.author === '' && !currentCopyrightData.author && <div>{currentCopyrightData.copyright}</div>}
            <div>
              <a className="link" href={currentCopyrightData.licenseLink} target="_blank" rel="noreferrer">
                Quelle aufrufen <FontAwesomeIcon className="arrow-up-icon" icon={faArrowUpRightFromSquare} />
              </a>
            </div>
            <hr className="line"></hr>
          </li>
        ))}
      </ol>
    </div>
  );
};
export default ProgrammInfo;
