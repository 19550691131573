import React from 'react';
import './datenschutz.css';
const Datenschutz = () => {
  return (
    <html>
      <head>
        <title>Datenschutz</title>
        <link rel="stylesheet" type="text/css" href="css/application.css" />
      </head>

      <body className={'datenschutz'}>
        <article>
          <h1>Hinweise zum Datenschutz und Widerrufsrecht</h1>
          <div className="text">
            Die bundesdeutschen Mahngerichte vertreten durch die Koordinierungsstelle für das automatisierte Mahnverfahren beim Ministerium
            der Justiz und für Europa Baden-Württemberg nimmt den Schutz Ihrer persönlichen Daten sehr ernst. Aus diesem Grunde haben wir
            Maßnahmen getroffen, die sicherstellen, dass die Vorschriften über den Datenschutz sowohl von uns als auch von unseren externen
            Dienstleistern beachtet werden.
          </div>
        </article>

        <article>
          <h2>Grundsätzliche Angaben zur Datenverarbeitung und Rechtsgrundlagen</h2>
          <div className="text">
            Die nachfolgende Erklärung gibt Ihnen einen Überblick darüber, wie die bundesdeutschen Mahngerichte den Schutz Ihrer Daten auf
            dieser Website gewährleistet und welche Art von Daten zu welchem Zweck und in welchem Umfang innerhalb des Online-Angebots und
            der verbundenen Webseiten, Funktionen und Inhalte erhoben werden.
          </div>
          <div className="text">
            Die Datenschutzerklärung gilt unabhängig von den verwendeten Domains, Systemen, Plattformen und Geräten (z.B. Desktop oder
            Mobile) auf denen das Angebot ausgeführt wird.
          </div>
          <div className="text">
            Begrifflichkeiten, wie z.B. &bdquo;personenbezogene Daten&ldquo; oder deren &bdquo;Verarbeitung&ldquo; verwenden wir
            entsprechend der Definitionen im Art. 4 der Datenschutz-Grundverordnung (
            <a href="http://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX:02016R0679-20160504" target="dsgvo">
              DS-GVO
            </a>
            ).
          </div>
          <div className="text">
            Im Zuge der Weiterentwicklung unseres Internetangebotes und der eingesetzten Technologien können auch Änderungen dieser
            Datenschutzerklärung erforderlich werden. Daher empfehlen wir Ihnen, sich die Datenschutzerklärung ab und zu erneut
            durchzulesen.
          </div>
          <div className="text">
            Wir behalten uns vor, die Datenschutzerklärung zu ändern, um sie an geänderte Rechtslagen, oder bei der Änderung des Dienstes
            oder der Datenverarbeitung anzupassen. Sofern Einwilligungen der Nutzer erforderlich sind oder Bestandteile der
            Datenschutzerklärung Regelungen des Vertragsverhältnisses mit den Nutzern enthalten, erfolgen die Änderungen nur mit Zustimmung
            der Nutzer.
          </div>
        </article>

        <article>
          <h2>Sicherheitsmaßnahmen</h2>
          <div className="text">
            Wir treffen organisatorische, vertragliche und technische Sicherheitsmaßnahmen entsprechend dem Stand der Technik, um
            sicherzustellen, dass die Vorschriften der Datenschutzgesetze eingehalten werden und um damit die durch uns verarbeiteten Daten
            gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den Zugriff unberechtigter Personen zu
            schützen.
          </div>
        </article>

        <article>
          <h2>Freiwillige persönliche Angaben</h2>
          <div className="text">
            Um einen Antrag auf Erlass eines Mahnbescheids oder auch einen der Folgeanträge zu stellen, müssen entsprechend der Vorgaben aus
            der Zivilprozessordnung (ZPO), personenbezogene Angaben machen. Die übermittelten (personenbezogenen) Daten speichern und
            verwenden wir ausschließlich zur Bearbeitung in dem Mahnverfahren, für das ihre Angabe erfolgte. Eine Weitergabe an Dritte
            erfolgt nur, wenn das Verfahren wegen wechselnder Zuständigkeit für das Verfahren an ein anderes Gericht abgegeben werden muss.
            Auch dort werden die Daten ausschließlich für die Bearbeitung des Verfahrens verwendet.
          </div>
          <div className="text">
            Automatisch gespeicherte Protokolldaten werden nach Ablauf von 30 Minuten nach Beenden der Session gelöscht.
          </div>
          <div className="text">
            Alle Anträge, die Sie auf dieser Webseite stellen können, können Sie auch mit anderen Softwareprodukten erzeugen oder, sofern
            Sie nicht der Nutzungsverpflichtung nach $ 702 ZPO unterliegen, unter Verwendung des vorgeschriebenen Vordrucks stellen. Ebenso
            können Sie statt den elektronischen Kontaktmöglichkeiten (z.B. E-Mail oder Kontaktformular) andere Wege der Kontaktaufnahme
            nutzen. Insofern bleibt die Freiwilligkeit der Angabe der persönlichen Daten jederzeit gewahrt. Es entstehen Ihnen keine
            Nachteile, wenn Sie die Daten, die über den gesetzlich vorgeschriebenen Mindestinhalt eines Antrags hinausgehen (z.B.
            Bankverbindung des Antragstellers), nicht angeben. Sie müssen dann allenfalls auf andere Wege der Informationsvermittlung
            zurückgreifen.
          </div>
        </article>

        <article>
          <h2>Datenverarbeitung auf dieser Internetseite</h2>
          <div className="text">
            Zu den im Rahmen dieses Onlineangebotes verarbeiteten personenbezogenen Daten gehören Bestandsdaten (z.B. Namen und Adressen und
            elektronische Kontaktadressen für die Übermittlung der Antragsdaten), Nutzungsdaten (z.B. die besuchten Seiten unseres
            Online-Angebotes) und Inhaltsdaten (z.B. Eingaben in den Formularen).
          </div>
          <div className="text">
            Personenbezogene Daten verarbeiten wir nur unter Einhaltung der einschlägigen Datenschutzbestimmungen. Das bedeutet, dass wir
            Ihre Daten nur im Rahmen der Durchführung des betroffenen Mahnverfahrens verarbeiten.
          </div>
          <div className="text">
            Bei jedem Besuch einer Internetseite werden Daten erhoben und ausgetauscht. Im Auftrag der Mahngerichte erhebt und speichert der
            technische Betreiber der Anwender Fa. Governikus GmbH & Co. KG, Hochschulring 4, 28359 Bremen, automatisch Daten
            (Logfile-Informationen), die Ihr Browser an unseren Server übermittelt. Dies sind:
            <ul>
              <li>IP-Adresse des anfragenden Rechners,</li>
              <li>Anwendungsseite, die durch den Nutzer aufgerufen wurde,</li>
              <li>Sessiondaten (alle eingegebenen Daten) und </li>
              <li>erzeugte EDA-Daten (bei Verwendung EGVP) bzw. Barcode-PDF (bei Verwendung Barcode). </li>
            </ul>
            Die Anwendung selbst wird im Auftrag der Fa. governikus gewartet und gepflegt durch die Fa. Aryza GmbH, Niederkasseler Lohweg
            18, 40547 Düsseldorf. Sie wird im Rahmen von Anwendungsfehlern tätig, erhält jedoch nur mit Ihrer Einwilligung Kenntnis der von
            Ihnen erfassten personenbezogenen Daten.
          </div>
          <div className="text">
            Die Mahngerichte erheben diese Daten, um ihre gerichtlichen Aufgaben wahrzunehmen, Art. 6 Abs. 1 lit. e){' '}
            <a href="http://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX:02016R0679-20160504" target="dsgvo">
              DS-GVO
            </a>
            .
          </div>
          <div className="text">
            Die Mahngerichte werten Daten daneben für statistische Zwecke aus, um die Nachfrage des Webangebots zu messen. Die Mahngerichte
            haben keine Möglichkeit, diese Daten einer bestimmten Person zuzuordnen. Eine Zusammenführung dieser Daten mit anderen
            Datenquellen wird nicht vorgenommen. Die IP-Adressen werden für die Dauer des Besuchs für die Beantwortung der Abfragen erfasst
            und nicht über das Ende des Besuchs hinaus gespeichert. Daten, die beim Zugriff auf das Internetangebot protokolliert wurden,
            werden an Dritte nur übermittelt, soweit wir gesetzlich oder durch Gerichtsentscheidung dazu verpflichtet sind oder die
            Weitergabe im Falle von Angriffen auf die Internetinfrastruktur des Webauftritts zur Rechts- oder Strafverfolgung erforderlich
            ist. Eine Weitergabe in anderen Fällen erfolgt nicht.
          </div>
        </article>

        <article>
          <h2>Cookies</h2>
          <div className="text">
            Aus technischen Gründen und um die Anwendung nutzerfreundlicher zu gestalten, werden so genannte Session-Cookies verwendet.
            Session-Cookies sind kleine Informationseinheiten, die ein Anbieter im Arbeitsspeicher des Computers des Besuchers speichert. In
            einem Session-Cookie wird eine zufällig erzeugte eindeutige Identifikationsnummer abgelegt, eine sogenannte Session-ID. Außerdem
            enthält ein Cookie die Angabe über seine Herkunft und die Speicherfrist. Diese Cookies können keine anderen Daten speichern. Mit
            der Session-ID werden der Nutzungsverlauf und das Eingabeergebnis zusammengestellt bzw. der Übermittlungsweg angezeigt. Die
            Session-Cookies sind nur für die Zeitdauer des aktuellen Besuchs (max. 2 Stunden) auf unserer Internetseite abgelegt. Sie werden
            gelöscht, wenn Sie die Nutzung unseres Onlineangebots beendet haben und den Browser schließen.
          </div>
        </article>

        <article>
          <h2>Umgang mit Cookies</h2>
          <div className="text">
            Cookies lassen sich mit allen Internetbrowsern steuern. Die meisten Browser sind so eingestellt, dass alle Cookies akzeptiert
            werden, ohne die Benutzer zu fragen. Wenn Sie den vollen Funktionsumfang der Website nutzen möchten, sollten Sie Ihren Browser
            so einstellen, dass Session-Cookies akzeptiert werden. Falls Sie nicht möchten, dass Cookies auf Ihrem Rechner gespeichert
            werden, sollten Sie die entsprechende Option in den Systemeinstellungen des Browsers deaktivieren.
          </div>
          <div className="text">Wenn Sie alle Cookies ablehnen, kann die Anwendung nicht genutzt werden.</div>
          <div className="text">
            Sie haben alternativ die Möglichkeit, alle oder einzelne auf Ihrem Rechner gespeicherte Cookies zu löschen.
            Einstellmöglichkeiten Ihres Browsers dazu finden Sie in der Regel unter dem Menüpunkt &quot;Optionen&quot;.
          </div>
          <div className="text">
            Wir setzen keine Cookies zur pseudonymen Reichweitenmessung ein. Sie können dem Einsatz von Cookies, die der Reichweitenmessung
            und Werbezwecken dienen, generell über die Deaktivierungsseite der Netzwerkwerbeinitiative
            (http://optout.networkadvertising.org/) und zusätzlich die US-amerikanische Webseite (http://www.aboutads.info/choices) oder die
            europäische Webseite (http://www.youronlinechoices.com/uk/your-ad-choices/) widersprechen.
          </div>
        </article>

        <article>
          <h2>Kontaktaufnahme mit der Justiz in Baden-Württemberg</h2>
          <div className="text">
            Bei der Kontaktaufnahme mit uns (per E-Mail aus der Liste der Ansprechpartner oder dem Impressum) werden die Angaben des Nutzers
            zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b){' '}
            <a href="http://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX:02016R0679-20160504" target="dsgvo">
              DS-GVO
            </a>{' '}
            verarbeitet.
          </div>
          <div className="text">
            Wenn Sie uns eine E-Mail senden, so werden diese Mail und Ihre E-Mail-Adresse ausschließlich für die Korrespondenz mit Ihnen
            verwandt. Nach Erfüllung bzw. Beantwortung der Anfrage werden Ihre Angaben gelöscht.
          </div>
        </article>

        <article>
          <h2>Auskunftsrecht</h2>
          <div className="text">
            Sie haben jederzeit das Recht formlos und ohne Begründung Auskunft über die bezüglich Ihrer Person gespeicherten Daten zu
            bekommen. Dies gilt auch für deren Herkunft und Empfänger sowie den Zweck der Speicherung. Diese Auskunft erhalten Sie
            kostenlos. Auskunft über die gespeicherten Daten gibt Ihnen die verantwortliche Stelle des jeweiligen Gerichts bzw. der
            jeweiligen Justizbehörde.
          </div>
          <div className="text">
            Die Angaben zur verantwortlichen Stelle finden Sie auf den Internetauftritten der Mahngerichte oder im Impressum des
            Internetauftritts.
          </div>
        </article>

        <article>
          <h2>Berichtigungsrecht, Widerrufsrecht und weitere Rechte</h2>
          <div className="text">
            Von Gesetzes wegen haben Sie das Recht auf Berichtigung unrichtiger Daten, Einschränkung der Verarbeitung und Löschung ihrer
            personenbezogenen Daten. Nachdem es sich hier um in Antragsportal handelt, wollen Sie die Korrektur im Rahmen der Eingabe
            vornehmen. Prüfen Sie hierzu am Ende der Eingabe den Antragsinhalt.
          </div>
          <div className="text">
            Im Fall der Annahme einer unrechtmäßigen Datenverarbeitung können Sie jederzeit Beschwerde bei der zuständigen Aufsichtsbehörde
            [
            <a href="https://www.baden-wuerttemberg.datenschutz.de" target="Datenschutz">
              https://www.baden-wuerttemberg.datenschutz.de
            </a>
            ] einreichen. Ebenso können Sie Einwilligungen, grundsätzlich mit Auswirkung für die Zukunft, widerrufen. Der Widerruf ist nur
            insoweit möglich, als die Daten nicht zur Bearbeitung des betroffenen Mahnverfahrens gesetzlich erhoben werden müssen
            (Mindestinhalt des Antrags).
          </div>
        </article>

        <article>
          <h2>Widerspruchsrecht</h2>
          <div className="text">
            Nutzer können der künftigen Verarbeitung ihrer personenbezogenen Daten entsprechend den gesetzlichen Vorgaben jederzeit
            widersprechen. Der Widerspruch ist nur insoweit möglich, als die Daten nicht zur Bearbeitung des betroffenen Mahnverfahrens
            gesetzlich erhoben werden müssen (Mindestinhalt des Antrags).
          </div>
        </article>

        <article>
          <h2>Löschen von Daten</h2>
          <div className="text">
            Die bei uns gespeicherten Daten werden gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der
            Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten der Nutzer nicht gelöscht werden, weil sie
            für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt.
          </div>
        </article>

        <article style={{ display: 'none' }}>
          <h2>Prüfen Sie Ihre Daten</h2>
          <div className="text">
            Sie können sich mit jedem Internetbrowser anzeigen lassen, wenn Cookies gesetzt werden und, was sie enthalten. Detaillierte
            Informationen bietet das
          </div>
        </article>

        <article style={{ display: 'none' }}>
          <h2>Webtracking</h2>
          <div className="text">
            Zur bedarfsgerechten Gestaltung und laufenden Verbesserung des Webauftrits analysiert unser beauftragter Betreiber, die Fa.
            governuikus GmbH & Co KG, mit Hilfe des Web-Analyse-Werkzeugs &quot;eMetrics Tracking&quot; der Firma WiredMinds AG, Stuttgart.
            Wir nutzen &bdquo;eMetrics Tracking&ldquo; auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse und
            Optimierung unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. e){' '}
            <a href="http://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX:02016R0679-20160504" target="dsgvo">
              DS-GVO
            </a>
            ).
          </div>
          <div className="text">
            für die Analyse werden Daten wie etwa Besuchszeit, besuchte Seiten, Verweildauer, regionale Herkunft in anonymisierter Form
            erhoben und verarbeitet. Die Nutzungsprofile werden anonymisiert unter Nutzung von Pseudonymen erzeugt. Dabei können auch
            Cookies verwendet werden, die Daten in anonymisierter Form sammeln und speichern. Die Identifizierung der Besucher anhand der
            erhobenen Daten ist ausgeschlossen.
          </div>
          <div className="text">
            Sie können der Erhebung und Nutzung Ihrer Besuchsdaten widersprechen und damit das Website-Tracking unterbinden. Besuchen Sie
            dazu bitte den folgenden Link:{' '}
            <a href="http://www.webstatistik-bw.de/" target="webstatistikbw">
              http://www.webstatistik-bw.de/
            </a>
          </div>
          <div className="text">
            Der Widerspruch (sog. &ldquo;Opt-Out) gilt für den verwendeten Browser und wird dort mittels Cookies hinterlegt.
          </div>
        </article>

        <article>
          <h2>Kontakt</h2>
          <div className="text">
            Verantwortliche Stelle für diese Webseite ist die Koordinierungsstelle für das automatisierte Mahnverfahren beim Ministerium der
            Justiz und für Europa Baden-Württemberg, vgl. Impressum.
          </div>
          <div className="text">
            Es steht Ihnen frei, jederzeit den dort zuständigen, behördlichen Datenschutzbeauftragten anzurufen. Seine Kontaktinformationen
            finden Sie auf der{' '}
            <a href="https://www.justiz-bw.de/,Lde/Startseite/Ministerium/Datenschutz" target="justiz_bw">
              Datenschutzseite
            </a>{' '}
            des Internetauftritts des Ministeriums der Justiz und für Europa Baden-Württemberg.{' '}
          </div>
        </article>

        <article>
          <h2>Verantwortung für das Internetangebot</h2>
          <div className="text">Die Angaben zu den Verantwortlichen für das Internetangebot finden Sie im Impressum.</div>
        </article>

        <a className="closeButton" href="javascript:window.close(true)">
          Fenster schlie&szlig;en
        </a>
      </body>
    </html>
  );
};

export default Datenschutz;
