import React, { useState } from 'react';
import { Translate } from 'react-jhipster';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from 'react-redux';
import { getPrintAndSign } from 'app/shared/reducers/requeststore';
import EdaDownloadLayout from './edaDownload/edaDownload';
import BarcodeLayout from './barcode/barcode';
import ElDidentityCardLayout from './eIDidentityCard/eIDidentityCard';

export enum printAndSignType {
  barcode,
  edaDownload,
  eldStaffSelection,
}

export const PrintAndSign = () => {
  const printAndSignCurrentType = useSelector(getPrintAndSign)?.type;
  const [selectedTab, setSelectedTab] = useState(printAndSignCurrentType);

  const navigateToTab = index => {
    setSelectedTab(index);
  };

  return (
    <div className="tabs-container">
      <Tabs selectedIndex={selectedTab} onSelect={index => setSelectedTab(index)}>
        <TabList className="tabs-list">
          <div>
            <div>
              <Tab className={selectedTab === 0 ? 'active-tab' : 'tab'} name={'Barcode'}>
                <Translate contentKey="generic.print-and-sign.barcode"> Barcode </Translate>
              </Tab>
              <Tab className={selectedTab === 1 ? 'active-tab' : 'tab'} name={'EDA-Download'}>
                <Translate contentKey="generic.print-and-sign.eda-download"> EDA-Download </Translate>
              </Tab>
              <Tab className={selectedTab === 2 ? 'active-tab' : 'tab'} name={'elD-Personalausw'}>
                <Translate contentKey="generic.print-and-sign.eld-staff-selection"> elD-Personalausw </Translate>
              </Tab>
            </div>
          </div>
        </TabList>
        <div>
          <TabPanel>
            <BarcodeLayout activeTab={printAndSignType.barcode}></BarcodeLayout>
          </TabPanel>
          <TabPanel>
            <EdaDownloadLayout activeTab={printAndSignType.edaDownload} navigateToTab={navigateToTab}></EdaDownloadLayout>
          </TabPanel>
          <TabPanel>
            <ElDidentityCardLayout></ElDidentityCardLayout>
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default PrintAndSign;
