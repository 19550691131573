// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-menu {
  margin-left: 5px;
  height: 40px;
  width: 40px;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/dunning/helpNavigationMenu/helpNavigationMenu.css"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;AACF","sourcesContent":[".icon-menu {\n  margin-left: 5px;\n  height: 40px;\n  width: 40px;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
