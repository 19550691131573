import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';

const Nato = () => {
  return (
    <html>
      <head>
        <title>Zusatzabkommen zum NATO-Truppenstatut</title>
      </head>
      <body className="antragsgegner nato">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="nato"></a>Zusatzabkommen zum NATO-Truppenstatut
        </h2>

        <p className="Body-Text">
          Das Zusatzabkommen zum NATO-Truppenstatut gilt für Mitglieder einer Truppe, eines zivilen Gefolges oder Angehörige, innerhalb der
          Staaten Belgien, Frankreich, Kanada, Niederlande, Großbritannien und Nordirland und der USA. Hier muss das Gericht besondere
          Zustellverfahren beachten. Sofern der Antragsgegner unter das Zusatzabkommen fällt, können Sie im Folgenden weitere Ihnen bekannte
          Angaben zur zuständigen Verbindungsstelle und/oder die Personalnummer des Antragsgegners mitteilen.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Nato;
