import { useAppSelector } from 'app/config/store';
import React from 'react';

const UrkundenMahnverfahren = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Urkunden-Mahnverfahren</title>
      </head>
      <body className="hauptforderungen urkunden-mahnverfahren">
        <h5 className="heading-5">
          <a target="urkunden_mahnverfahren"></a>Urkunden-Mahnverfahren
        </h5>

        <p className="Definition">
          Kommt es zum streitigen Verfahren (etwa bei Widerspruch oder Einspruch des Antragsgegners), so mündet das Urkunden-Mahnverfahren
          in einen Urkundenprozess (siehe{' '}
          <a href="https://dejure.org/gesetze/ZPO" target="_blank" rel="noreferrer">
            ZPO
          </a>{' '}
          §592 und folgende)
        </p>

        <p className="Definition">
          Sämtlichen zur Begründung des Anspruchs erforderlichen Tatsachen müssen durch Urkunden bewiesen werden können.
        </p>

        <p className="Definition">
          Will der Antragsteller ein Urkunden-Mahnverfahren durchführen, ist dieses im Antrag auf Erlass des Mahnbescheides gesondert
          kenntlich zu machen.
        </p>

        <p className="RelatedHead">
          <b>Siehe auch:</b>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/hauptforderungen/scheck_mahnverfahren`}>Scheck-Mahnverfahrens</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/hauptforderungen/wechsel_mahnverfahren`}>Wechsel-Mahnverfahrens</a>
        </p>
      </body>
    </html>
  );
};

export default UrkundenMahnverfahren;
