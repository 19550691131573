import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Firma = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Einführung zum Online-Mahnantrag - Erfassen eines Antragsgegners - Erfassen einer Firma</title>
      </head>
      <body className="antragsgegner firma">
        <HelpNavigationMenu />

        <h2>
          <a target="firma"></a>Erfassen einer Firma
        </h2>

        <p className="Body-Text">Wählen Sie aus der vorgegebenen Liste die Rechtsform des Antragsgegners aus.</p>

        <p className="Note">
          Hinweis: Sollte die gesuchte Rechtsform nicht aufgeführt sein, so schauen Sie bitte auch unter dem Punkt Sonstige-&gt;Weitere,
          dort finden Sie z.B. auch die Körperschaft des öffentlichen Rechts.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Firmenname&quot; tragen Sie den vollständigen Namen der Firma ein, z.B. &quot;ABC GmbH&quot;. Für den Firmennamen
          stehen 4 Zeilen zur Verfügung.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Straße/Hausnummer&quot; tragen Sie den Straßennamen und die Hausnummer ein, in der die Firma ihren Sitz hat.
        </p>

        <p className="Body-Text">Geben Sie die Postleitzahl und den Ort ein.</p>

        <p className="Body-Text">
          <i>Beispiel:</i>
        </p>

        <p className="List">Mustermann Stahl GmbH</p>

        <p className="List">GmbH</p>

        <p className="List">Musterstraße 14</p>

        <p className="List">88888 Musterstadt</p>

        <p className="Note">
          Hinweis: Die GmbH in Gründung ist, je nach Fortschritt des Gründungs- und Eintragungsakts, in unterschiedlicher Weise parteifähig.
          Sie kann deshalb mit der Rechtsform &quot;GbR&quot; (im Feld &quot;Firmenname&quot; trägt man dann die vollständige Bezeichnung
          ein, z.B. &quot;ABC GmbH i. Gr.&quot;) oder mit der Rechtsform &quot;GmbH in Gründung&quot; (die dann wie eine GmbH einzutragen
          ist) eingetragen werden.
        </p>

        <p className="Note">
          Hinweis: Einzelfirma nur max. 1 Inhaber; bei mehreren Inhabern handelt es sich um eine GbR. Bei einer Einzelfirma muss es sich um
          einen eingetragenen Kaufmann handeln. Es muss die im Handelsregister eingetragene Bezeichnung mit dem Zusatz &quot;eingetragener
          Kaufmann&quot; oder &quot;e.K.&quot; angegeben werden. Ohne Eintragung im Handelsregister beim Amtsgericht muss die Eintragung als
          natürliche Person (Herr, Frau) erfolgen, bei Bedarf mit einem auf das Gewerbe hinweisenden Zusatz hinter dem Nachnamen.
        </p>

        <p className="RelatedHead">
          <p></p>
        </p>
        <p className="RelatedHead">
          <b>Siehe auch:</b>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsgegner/ausland`}>Antragsgegner im Ausland</a>
        </p>
        <p className="RelatedHead">
          <p></p>
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Firma;
