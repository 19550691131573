import './uebersicht.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

const Uebersicht = () => {
  return (
    <html>
      <head>
        <title>MB-Antrag Übersicht</title>
      </head>
      <body className="uebersicht">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="uebersicht"></a>MB-Antrag Übersicht
        </h2>

        <p className="Body-Text">Hier können Sie alle von Ihnen erfassten Ein- bzw. Angaben im Überblick sehen oder auch bearbeiten.</p>

        <p className="Body-Text">
          Im Falle eines Erfassungsfehlers besteht über die{' '}
          <Button className="edit-button" name="bearbeiten" disabled>
            bearbeiten
          </Button>
          -Schaltfläche die Möglichkeit, die Angaben zu korrigieren. Auch auf evtl. Warnmeldungen kann so reagiert werden. Sind alle Angaben
          korrekt, bestätigen Sie die Übersicht mit Klick auf die <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} />
          -Schaltfläche.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Uebersicht;
