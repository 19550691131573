import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';

const Pka = () => {
  return (
    <html>
      <head>
        <title>Partei kraft Amtes</title>
      </head>
      <body className="antragsgegner sonstige pka">
        <HelpNavigationMenu />

        <h2>
          <a target="pka"></a>
          <i>Partei kraft Amtes</i>
        </h2>

        <p className="Body-Text">Wählen Sie aus der vorgegebenen Liste die Rechtsform des Antragsgegners aus.</p>

        <p className="Body-Text">Im Feld &quot;Vornamen&quot; geben Sie den vollständigen Vornamen ohne Abkürzung ein.</p>

        <p className="Body-Text">Im Feld &quot;Nachnamen&quot; geben Sie den vollständigen Nachnamen ohne Abkürzung ein.</p>

        <p className="Body-Text">
          Im Feld &quot;Straße/Hausnummer&quot; tragen Sie den Straßennamen und die Hausnummer ein, in der der Antragsgegner seinen Sitz
          hat.
        </p>

        <p className="Body-Text">Geben Sie die Postleitzahl und den Ort ein.</p>

        <p className="Body-Text">
          Im Feld &quot;Betreutes Vermögen&quot; geben Sie das betreute Vermögen an. Für die Eingabe des betreuten Vermögens stehen 2
          Zeilöen zur Verfügung.
        </p>

        <p className="Body-Text">
          <i>Beispiel: </i>
        </p>

        <p className="List">Konkursverwalter</p>

        <p className="List">Manfred</p>

        <p className="List">Mustermann</p>

        <p className="List">Musterstraße 16</p>

        <p className="List">88888 Musterstadt</p>

        <p className="List">Vermögen der Fa. Stein GmbH, Steinweg 2, 88888 Musterstadt</p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Pka;
