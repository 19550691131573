import './barcode.scss';
import React, { useState } from 'react';
import { useAppSelector } from 'app/config/store';
import { Translate } from 'react-jhipster';
import { printAndSignType } from '../printAndSign';
import { Button, Label, Row, Col, Input, FormGroup } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import DunningNavigation from 'app/shared/components/UI/navigation/dunning-navigation';
import { setPrintAndSign as setPrintAndSignToStore } from 'app/shared/reducers/requeststore';
import { useDispatch } from 'react-redux';
import { browserName } from 'react-device-detect';
import { processAndDownloadBarcode } from 'app/shared/util/statics';

export const BarcodeLayout = (props: { activeTab: printAndSignType }) => {
  // TODO Implement navigation to all Link's components when the following pages are created in our app:
  // Help for barcode and print - https://www.online-mahnantrag.de/omahn/hilfe/barcodepdf.htm
  // Help for browser - https://www.online-mahnantrag.de/omahn/hilfe/googlechrome.htm (depends on what browser, different content for available browsers)
  // Help for lawyers - https://www.online-mahnantrag.de/omahn/hilfe/anwaelte2018.htm

  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  const requestStoreData = useAppSelector(state => state.requestStore);
  const [printAndSign, setPrintAndSign] = useState({
    type: props.activeTab,
    readInstructions: false,
    readNotesForBarcodeAndPrint: false,
    readNotesForBrowser: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Any supported browser names can be declared in this constant so when help is available to be displayed correctly.
  const browserNameMapping = {
    Chrome: 'Google Chrome',
    Firefox: 'Mozilla Firefox',
    Opera: 'Opera',
    IE: 'Microsoft Internet Explorer',
    Edge: 'Microsoft Edge',
  };
  const detectedBrowserName = browserName ? browserNameMapping[browserName] : undefined;

  function downloadAndPrint() {
    processAndDownloadBarcode(requestStoreData);
  }

  function handleOnNext() {
    dispatch(
      setPrintAndSignToStore({
        ...printAndSign,
      }),
    );
    navigate(urlPrefix + '/ende');
  }

  function handleOnBack() {
    navigate(urlPrefix + '/uebersicht');
  }

  const handleChange = e => {
    const { id, checked } = e.target;
    setPrintAndSign({ ...printAndSign, [id]: checked });
  };

  return (
    <div>
      <Row className="mb-5"></Row>
      <ol>
        <strong>
          <Row className="mb-2">
            <Col md={2}></Col>
            <Col>
              <li>
                <Translate contentKey="generic.print-and-sign.read-instructions">Read Instructions for barcode and print </Translate>
                <Link to="" id="barcode-print">
                  <Translate contentKey="generic.print-and-sign.barcode-print"></Translate>
                </Link>
              </li>
            </Col>
          </Row>
        </strong>
        <Row className="mb-3">
          <Col md={2}></Col>
          <Col>
            <FormGroup check className="read-instructions-form-check">
              <Label check>
                <Input
                  id="readNotesForBarcodeAndPrint"
                  type="checkbox"
                  name="read-notes-for-barcode-and-print"
                  readOnly
                  onChange={handleChange}
                />
                <Translate contentKey="generic.print-and-sign.read-follow-instructions-agreement" />
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {detectedBrowserName !== undefined && (
          <>
            <Row className="mb-2">
              <Col md={2}></Col>
              <Col>
                <Link to="" id="note-for-browser">
                  <Translate contentKey="generic.print-and-sign.important-note-for-browser"></Translate>
                  {` ${browserNameMapping[browserName]}`}
                </Link>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={2}></Col>
              <Col>
                <FormGroup check className="read-instructions-form-check">
                  <Label check>
                    <Input id="readNotesForBrowser" type="checkbox" name="read-notes-for-browser" readOnly onChange={handleChange} />
                    <Translate contentKey="generic.print-and-sign.important-note-for-browser" />
                    {` ${browserNameMapping[browserName]}`} <Translate contentKey="generic.print-and-sign.read" />
                    {'.'}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <Row className="mb-3">
          <Col md={2}></Col>
          <Col>
            <Link to="" id="use-for-lawyers">
              <Translate contentKey="generic.print-and-sign.use-for-lawyers"></Translate>
            </Link>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={2}></Col>
          <Col md={7}>
            <strong>
              <li>
                <span>
                  <Translate contentKey="generic.print-and-sign.download-app-for-order-payment"></Translate>
                  <span style={{ textDecoration: 'underline' }}>
                    <Translate contentKey="generic.print-and-sign.exclusively"></Translate>
                  </span>{' '}
                  <Translate contentKey="generic.print-and-sign.dispatch-on-paper-on-post"></Translate>
                </span>
              </li>
            </strong>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={5}></Col>
          <Col>
            <Button className="download-and-print-button" name="download-and-print-button" onClick={() => downloadAndPrint()}>
              <Translate contentKey="generic.print-and-sign.download-and-print"></Translate>
            </Button>
          </Col>
          <Col md={3}></Col>
        </Row>
        <Row className="mb-5">
          <Col md={2}></Col>
          <Col>
            <li className="barcode-twice-number-li">
              <Translate contentKey="generic.print-and-sign.print-barcode-twice"></Translate>
            </li>
          </Col>
          <Col md={3}></Col>
        </Row>
        <DunningNavigation handleOnNextClick={handleOnNext} handleOnBackClick={handleOnBack} registeredValidations={{}} />
      </ol>
    </div>
  );
};
export default BarcodeLayout;
