import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';

const Ausland = () => {
  return (
    <html>
      <head>
        <title>Erfassen eines Antragsgegners</title>
      </head>
      <body className="antragsgegner ausland">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="ausland"></a>Antragsgegner im Ausland
        </h2>

        <p className="Body-Text">
          Hat der Antragsgegner seinen Sitz im Ausland, so geben Sie bitte das korrekte Auslandskennzeichen an. Wenn Sie bei der Erfassung
          auf den Begriff &quot;Ausl.Kz.&quot; klicken, erhalten Sie eine entsprechende Auswahlliste.
        </p>

        <p className="Body-Text">
          Sollte die Postleitzahl Buchstaben enthalten und/oder mehr als 5 Stellen haben, so geben Sie bitte im Feld Postleitzahl
          &apos;00000&apos; an und im Feld Ort die Postleitzahl und die Ortsbezeichnung.
        </p>

        <p className="Body-Text">
          Da das Prozessgericht bei Antragsgegnern im Ausland nicht vorbelegt werden kann, müssen Sie später in der Datenübersicht ein
          Prozessgericht angeben. Wenn Ihnen die Adresse des Prozessgerichtes unbekannt ist, können Sie unter{' '}
          <a href="http://www.justiz.de/OrtsGerichtsverzeichnis/index.php" target="_blank" rel="noreferrer">
            http://www.justiz.de/OrtsGerichtsverzeichnis/index.php
          </a>{' '}
          nachschlagen.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Ausland;
