import './gloss.css';
import React, { useState } from 'react';
import ForeignCountryDialog from 'app/modules/dunning/attorney/foreign-country-dialog/foreignCountryModal';
import { Link } from 'react-router-dom';

const GlossAuslkz = () => {
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };
  <ForeignCountryDialog showModal={showModal} handleClose={handleToggleModal} />;

  return (
    <html>
      <head>
        <title>Ausl.Kz</title>
      </head>
      <body className="gloss auslkz">
        <h5 className="heading-5">
          <a target="glossauslkz"></a>Ausl.Kz
        </h5>

        <p className="Definition">Auslandskennzeichen</p>

        <p className="Definition">
          <Link
            className="foreign-country-link"
            to=""
            onClick={handleToggleModal}
            id="label-attornyOfRecord.foreign-country"
            title="Liste mit möglichen Auslandskennzeichen"
          >
            <ForeignCountryDialog showModal={showModal} handleClose={handleToggleModal} />
            Hier finden Sie eine Liste der möglichen Auslandskennzeichen.
          </Link>
        </p>
      </body>
    </html>
  );
};

export default GlossAuslkz;
