import axios from 'axios';
import { createAsyncThunk, createSlice, AsyncThunk, AsyncThunkAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'app/config/store';

export interface IInitialState {
  loading: boolean;
  states: object;
  error: string;
  descriptions: object;
  consumerAssociations: object;
  legalForms: object;
  miscLegalForms: object;
  salutations: object;
  naturalPersons: object;
  legalPersonFunctions: object;
  functions: object;
  dialogOptions: object;
  mainRequirementsCatalog: object;
  mainRequirementsCatalogForCheckDunningProcedure: object;
  informationAboutTheClaimForCheckDunningProcedure: object;
  informationAboutTheClaim: object;
  damageCatalogTypes: object;
  otherExpenses: object;
  interestRates: object;
  legalRep: object;
  furtherExpences: object;
  deliveryToLegalRepresentatives: object;
  potentialNewTrialCourt: object;
  respondentDesignationLegalCourt: object;
  bankDetails: object;
  legalRepSmall: object;
  baseInterest: object;
}

export const initialState: IInitialState = {
  loading: false,
  states: {},
  error: '',
  descriptions: {},
  consumerAssociations: {},
  legalForms: {},
  miscLegalForms: {},
  salutations: {},
  naturalPersons: {},
  legalPersonFunctions: {},
  functions: {},
  dialogOptions: {},
  mainRequirementsCatalog: {},
  mainRequirementsCatalogForCheckDunningProcedure: {},
  informationAboutTheClaimForCheckDunningProcedure: {},
  informationAboutTheClaim: {},
  damageCatalogTypes: {},
  otherExpenses: {},
  interestRates: {},
  legalRep: {},
  furtherExpences: {},
  deliveryToLegalRepresentatives: {},
  potentialNewTrialCourt: {},
  respondentDesignationLegalCourt: {},
  bankDetails: {},
  legalRepSmall: {},
  baseInterest: {},
};

export enum DropdownConfigKeys {
  states = 'states',
  descriptions = 'descriptions',
  consumerAssociations = 'consumerAssociations',
  legalForms = 'legalForms',
  miscLegalForms = 'miscLegalForms',
  salutations = 'salutations',
  naturalPersons = 'naturalPersons',
  legalPersonFunctions = 'legalPersonFunctions',
  functions = 'functions',
  dialogOptions = 'dialogOptions',
  mainRequirementsCatalog = 'mainRequirementsCatalog',
  mainRequirementsCatalogForCheckDunningProcedure = 'mainRequirementsCatalogForCheckDunningProcedure',
  informationAboutTheClaimForCheckDunningProcedure = 'informationAboutTheClaimForCheckDunningProcedure',
  informationAboutTheClaim = 'informationAboutTheClaim',
  damageCatalogTypes = 'damageCatalogTypes',
  otherExpenses = 'otherExpenses',
  interestRates = 'interestRates',
  legalRep = 'legalRep',
  furtherExpences = 'furtherExpences',
  deliveryToLegalRepresentatives = 'deliveryToLegalRepresentatives',
  potentialNewTrialCourt = 'potentialNewTrialCourt',
  respondentDesignationLegalCourt = 'respondentDesignationLegalCourt',
  bankDetails = 'bankDetails',
  legalRepSmall = 'legalRepSmall',
  baseInterest = 'baseInterest',
}

const thunkMap = new Map<DropdownConfigKeys, AsyncThunk<any, any, Record<string, unknown>>>();
Object.values(DropdownConfigKeys).forEach(key => {
  thunkMap.set(
    key,
    createAsyncThunk(`configuration/${key}`, (subType?: string) => {
      const encodedSubtype = encodeURIComponent(subType || '');
      const url = subType ? `configuration/dropdown/${key}?subtype=${encodedSubtype}` : `configuration/dropdown/${key}`;
      return axios.get(url).then(response => {
        return {
          key,
          subType,
          data: response.data,
        };
      });
    }),
  );
});

export function fetchDropdownConfig(key: DropdownConfigKeys, subType?: string): AsyncThunkAction<any, any, Record<string, unknown>> {
  return thunkMap.get(key)(subType);
}

export function getDropdownConfig(key: DropdownConfigKeys, subType?: string): any[] {
  return useAppSelector(state => {
    if (state.configurationStore[key]) {
      return state.configurationStore[key][subType || 'default'] || [];
    }
    return [];
  });
}

export const configurationSlice = createSlice({
  name: 'ConfigurationState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    thunkMap.forEach((thunk, key) => {
      builder
        .addCase(thunk.pending, state => {
          state.loading = true;
        })
        .addCase(thunk.fulfilled, (state, action) => {
          state.loading = false;
          const payload = action.payload;
          state[key] = {
            ...state[key],
            [payload.subType || 'default']: payload.data,
          };
          state.error = '';
        })
        .addCase(thunk.rejected, (state, action) => {
          state.loading = false;
          state[key] = initialState[key];
          state.error = action.error.message;
        });
    });
  },
});

export default configurationSlice.reducer;
