import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';

const WegAntrasgegner = () => {
  return (
    <html>
      <head>
        <title>Einführung zum Online-Mahnantrag - Erfassen eines Sonstigen Antragsgegners - WEG</title>
      </head>

      <body className="antragsgegner sonstige weg-antrasgegner">
        <HelpNavigationMenu />

        <h4 className="heading-4">
          <a target="weg_antrasgegner"></a>
          <i>WEG als Antragsgegner</i>
        </h4>

        <p className="Body-Text">Die Wohnungseigentümergemeinschaft kann als Partei angegeben werden.</p>

        <p className="Body-Text">
          Im Feld &quot;vollst. Bezeichnung&quot; muss die Bezeichnung &quot;Wohnungseigentümergemeinschaft&quot; oder &quot;Gemeinschaft
          der Wohnungseigentümer&quot; gefolgt von einer bestimmten Angabe des gemeinschaftlichen Grundstücks (z.B. nach postalische
          Anschrift oder Grundbuchbezeichnung, evtl. Flurstücksnummer) angegeben werden.
        </p>

        <p className="Body-Text">
          In den Feldern &quot;Straße/Hausnummer&quot; und &quot;PLZ/Ort/AuslKz&quot; ist die vollständige Anschrift der Gemeinschaft auch
          dann einzutragen, wenn sie bereits in der Parteibezeichnung der WEG vollständig enthalten ist. Die Angabe eines
          Auslandkennzeichens ist nicht zulässig.
        </p>

        <p className="Body-Text">
          Nachrichten und Bescheide des Gerichts werden bei Wohnungseigentümergemeinschaften immer an den gesetzlichen Vertreter
          (Miteigentümer oder Verwalter) versandt, der deshalb zusätzlich und mit vollständiger Anschrift angegeben werden muss.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default WegAntrasgegner;
