import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';

export const PrivacyPolicyMenu = () => {
  function handleOnClick() {
    window.open('/datenschutz', '_blank');
  }

  return (
    <NavItem name={translate('menu.privacy-policy')} id="privacy-policy-menu-item">
      <NavLink href="#" onClick={handleOnClick} id="privacy-policy-nav-link">
        <Translate contentKey="menu.privacy-policy" />
      </NavLink>
    </NavItem>
  );
};
