import './basisZins.css';
import React from 'react';

const BasisZins = () => {
  return (
    <html>
      <head>
        <title>Ihr Mahnbescheid im Internet</title>
      </head>
      <body className="basiszins">
        <table>
          <tbody>
            <tr>
              <th colSpan={2}>
                <h1>Basiszinssätze</h1>(ab 01.01.2002<div></div>gem. § 247 Abs. 1 BGB )
              </th>
            </tr>
            <tr>
              <th>Datum</th>
              <th>Zinssatz</th>
            </tr>

            <tr>
              <td>01.07.2024</td>
              <td>3,37 %</td>
            </tr>

            <tr>
              <td>01.01.2024</td>
              <td>3,62 %</td>
            </tr>

            <tr>
              <td>01.07.2023</td>
              <td>3,12 %</td>
            </tr>

            <tr>
              <td>01.01.2023</td>
              <td>1,62 %</td>
            </tr>

            <tr>
              <td>01.07.2022</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.01.2022</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.07.2021</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.01.2021</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.07.2020</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.01.2020</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.07.2019</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.01.2019</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.07.2018</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.01.2018</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.07.2017</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.01.2017</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.07.2016</td>
              <td>-0,88 %</td>
            </tr>

            <tr>
              <td>01.01.2016</td>
              <td>-0,83 %</td>
            </tr>

            <tr>
              <td>01.07.2015</td>
              <td>-0,83 %</td>
            </tr>

            <tr>
              <td>01.01.2015</td>
              <td>-0,83 %</td>
            </tr>

            <tr>
              <td>01.07.2014</td>
              <td>-0,73 %</td>
            </tr>

            <tr>
              <td>01.01.2014</td>
              <td>-0,63 %</td>
            </tr>

            <tr>
              <td>01.07.2013</td>
              <td>-0,38 %</td>
            </tr>

            <tr>
              <td>01.01.2013</td>
              <td>-0,13 %</td>
            </tr>

            <tr>
              <td>01.07.2012</td>
              <td>0,12 %</td>
            </tr>

            <tr>
              <td>01.01.2012</td>
              <td>0,12 %</td>
            </tr>

            <tr>
              <td>01.07.2011</td>
              <td>0,37 %</td>
            </tr>

            <tr>
              <td>01.01.2011</td>
              <td>0,12 %</td>
            </tr>

            <tr>
              <td>01.07.2010</td>
              <td>0,12 %</td>
            </tr>

            <tr>
              <td>01.01.2010</td>
              <td>0,12 %</td>
            </tr>

            <tr>
              <td>01.07.2009</td>
              <td>0,12 %</td>
            </tr>

            <tr>
              <td>01.01.2009</td>
              <td>1,62 %</td>
            </tr>

            <tr>
              <td>01.07.2008</td>
              <td>3,19 %</td>
            </tr>

            <tr>
              <td>01.01.2008</td>
              <td>3,32 %</td>
            </tr>

            <tr>
              <td>01.07.2007</td>
              <td>3,19 %</td>
            </tr>

            <tr>
              <td>01.01.2007</td>
              <td>2,70 %</td>
            </tr>

            <tr>
              <td>01.07.2006</td>
              <td>1,95 %</td>
            </tr>

            <tr>
              <td>01.01.2006</td>
              <td>1,37 %</td>
            </tr>

            <tr>
              <td>01.07.2005</td>
              <td>1,17 %</td>
            </tr>

            <tr>
              <td>01.01.2005</td>
              <td>1,21 %</td>
            </tr>

            <tr>
              <td>01.07.2004</td>
              <td>1,13 %</td>
            </tr>

            <tr>
              <td>01.01.2004</td>
              <td>1,14 %</td>
            </tr>

            <tr>
              <td>01.07.2003</td>
              <td>1,22 %</td>
            </tr>

            <tr>
              <td>01.01.2003</td>
              <td>1,97 %</td>
            </tr>

            <tr>
              <td>01.07.2002</td>
              <td>2,47 %</td>
            </tr>

            <tr>
              <td>01.01.2002</td>
              <td>2,57 %</td>
            </tr>

            <tr>
              <td>01.09.2001</td>
              <td>3,62 %</td>
            </tr>

            <tr>
              <td>01.09.2000</td>
              <td>4,26 %</td>
            </tr>

            <tr>
              <td>01.05.2000</td>
              <td>3,42 %</td>
            </tr>

            <tr>
              <td>01.01.2000</td>
              <td>2,68 %</td>
            </tr>

            <tr>
              <td>01.05.1999</td>
              <td>1,95 %</td>
            </tr>

            <tr>
              <td>01.01.1999</td>
              <td>2,50 %</td>
            </tr>

            <tr>
              <td>19.04.1996</td>
              <td>2,50 %</td>
            </tr>

            <tr>
              <td>15.12.1995</td>
              <td>3,00 %</td>
            </tr>

            <tr>
              <td>25.08.1995</td>
              <td>3,50 %</td>
            </tr>

            <tr>
              <td>31.03.1995</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>13.05.1994</td>
              <td>4,50 %</td>
            </tr>

            <tr>
              <td>15.04.1994</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>18.02.1994</td>
              <td>5,25 %</td>
            </tr>

            <tr>
              <td>22.10.1993</td>
              <td>5,75 %</td>
            </tr>

            <tr>
              <td>10.09.1993</td>
              <td>6,25 %</td>
            </tr>

            <tr>
              <td>02.07.1993</td>
              <td>6,75 %</td>
            </tr>

            <tr>
              <td>23.04.1993</td>
              <td>7,25 %</td>
            </tr>

            <tr>
              <td>19.03.1993</td>
              <td>7,50 %</td>
            </tr>

            <tr>
              <td>05.02.1993</td>
              <td>8,00 %</td>
            </tr>

            <tr>
              <td>15.09.1992</td>
              <td>8,25 %</td>
            </tr>

            <tr>
              <td>17.07.1992</td>
              <td>8,75 %</td>
            </tr>

            <tr>
              <td>20.12.1991</td>
              <td>8,00 %</td>
            </tr>

            <tr>
              <td>16.08.1991</td>
              <td>7,50 %</td>
            </tr>

            <tr>
              <td>01.02.1991</td>
              <td>6,50 %</td>
            </tr>

            <tr>
              <td>06.10.1989</td>
              <td>6,00 %</td>
            </tr>

            <tr>
              <td>30.06.1989</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>21.04.1989</td>
              <td>4,50 %</td>
            </tr>

            <tr>
              <td>20.01.1989</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>26.08.1988</td>
              <td>3,50 %</td>
            </tr>

            <tr>
              <td>01.07.1988</td>
              <td>3,00 %</td>
            </tr>

            <tr>
              <td>04.12.1987</td>
              <td>2,50 %</td>
            </tr>

            <tr>
              <td>23.01.1987</td>
              <td>3,00 %</td>
            </tr>

            <tr>
              <td>07.03.1986</td>
              <td>3,50 %</td>
            </tr>

            <tr>
              <td>16.08.1985</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>29.06.1984</td>
              <td>4,50 %</td>
            </tr>

            <tr>
              <td>18.03.1983</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>03.12.1982</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>22.10.1982</td>
              <td>6,00 %</td>
            </tr>

            <tr>
              <td>27.08.1982</td>
              <td>7,00 %</td>
            </tr>

            <tr>
              <td>02.05.1980</td>
              <td>7,50 %</td>
            </tr>

            <tr>
              <td>29.02.1980</td>
              <td>7,00 %</td>
            </tr>

            <tr>
              <td>01.11.1979</td>
              <td>6,00 %</td>
            </tr>

            <tr>
              <td>13.07.1979</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>30.03.1979</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>16.12.1977</td>
              <td>3,00 %</td>
            </tr>

            <tr>
              <td>12.09.1975</td>
              <td>3,50 %</td>
            </tr>

            <tr>
              <td>15.08.1975</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>23.05.1975</td>
              <td>4,50 %</td>
            </tr>

            <tr>
              <td>07.03.1975</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>07.02.1975</td>
              <td>5,50 %</td>
            </tr>

            <tr>
              <td>20.12.1974</td>
              <td>6,00 %</td>
            </tr>

            <tr>
              <td>25.10.1974</td>
              <td>6,50 %</td>
            </tr>

            <tr>
              <td>01.06.1973</td>
              <td>7,00 %</td>
            </tr>

            <tr>
              <td>04.05.1973</td>
              <td>6,00 %</td>
            </tr>

            <tr>
              <td>12.01.1973</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>01.12.1972</td>
              <td>4,50 %</td>
            </tr>

            <tr>
              <td>03.11.1972</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>09.10.1972</td>
              <td>3,50 %</td>
            </tr>

            <tr>
              <td>25.02.1972</td>
              <td>3,00 %</td>
            </tr>

            <tr>
              <td>23.12.1971</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>14.10.1971</td>
              <td>4,50 %</td>
            </tr>

            <tr>
              <td>01.04.1971</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>03.12.1970</td>
              <td>6,00 %</td>
            </tr>

            <tr>
              <td>18.11.1970</td>
              <td>6,50 %</td>
            </tr>

            <tr>
              <td>16.07.1970</td>
              <td>7,00 %</td>
            </tr>

            <tr>
              <td>09.03.1970</td>
              <td>7,50 %</td>
            </tr>

            <tr>
              <td>11.09.1969</td>
              <td>6,00 %</td>
            </tr>

            <tr>
              <td>20.06.1969</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>18.04.1969</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>12.05.1967</td>
              <td>3,00 %</td>
            </tr>

            <tr>
              <td>14.04.1967</td>
              <td>3,50 %</td>
            </tr>

            <tr>
              <td>17.02.1967</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>06.01.1967</td>
              <td>4,50 %</td>
            </tr>

            <tr>
              <td>27.05.1966</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>13.08.1965</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>22.01.1965</td>
              <td>3,50 %</td>
            </tr>

            <tr>
              <td>05.05.1961</td>
              <td>3,00 %</td>
            </tr>

            <tr>
              <td>20.01.1961</td>
              <td>3,50 %</td>
            </tr>

            <tr>
              <td>11.11.1960</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>03.06.1960</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>23.10.1959</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>04.09.1959</td>
              <td>3,00 %</td>
            </tr>

            <tr>
              <td>10.01.1959</td>
              <td>2,75 %</td>
            </tr>

            <tr>
              <td>27.06.1958</td>
              <td>3,00 %</td>
            </tr>

            <tr>
              <td>17.01.1958</td>
              <td>3,50 %</td>
            </tr>

            <tr>
              <td>19.09.1957</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>11.01.1957</td>
              <td>4,50 %</td>
            </tr>

            <tr>
              <td>06.09.1956</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>19.05.1956</td>
              <td>5,50 %</td>
            </tr>

            <tr>
              <td>08.03.1956</td>
              <td>4,50 %</td>
            </tr>

            <tr>
              <td>04.08.1955</td>
              <td>3,50 %</td>
            </tr>

            <tr>
              <td>20.05.1954</td>
              <td>3,00 %</td>
            </tr>

            <tr>
              <td>11.06.1953</td>
              <td>3,50 %</td>
            </tr>

            <tr>
              <td>08.01.1953</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>21.08.1952</td>
              <td>4,50 %</td>
            </tr>

            <tr>
              <td>29.05.1952</td>
              <td>5,00 %</td>
            </tr>

            <tr>
              <td>27.10.1950</td>
              <td>6,00 %</td>
            </tr>

            <tr>
              <td>14.07.1949</td>
              <td>4,00 %</td>
            </tr>

            <tr>
              <td>27.05.1949</td>
              <td>4,50 %</td>
            </tr>

            <tr>
              <td>01.07.1948</td>
              <td>5,00 %</td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  );
};
export default BasisZins;
