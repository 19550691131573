import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Uebersicht = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Antragsübersicht</title>
      </head>
      <body className="generic-help ubersicht">
        <HelpNavigationMenu />

        <h2>Antragsübersicht</h2>

        <p>Hier können Sie alle von Ihnen erfassten Ein- bzw. Angaben im Überblick sehen oder auch bearbeiten.</p>

        <p>
          Im Falle eines Erfassungsfehlers besteht über die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value="bearbeiten" type="submit" />
            -Schaltfläche
          </span>{' '}
          die Möglichkeit, die Angaben zu korrigieren. Auch auf evtl. Warnmeldungen kann so reagiert werden. Sind alle Angaben korrekt,
          bestätigen Sie die Übersicht mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>
        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Uebersicht;
