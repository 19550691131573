import { useAppSelector } from 'app/config/store';
import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';

const Weg = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Einführung zum Online-Mahnantrag - Erfassen eines Sonstigen Antragsgegner - WEG</title>
      </head>
      <body className="antragsgegner sonstige weg">
        <HelpNavigationMenu />

        <h2>
          <a target="weg"></a>
          <i>
            <a href={`hilfe${urlPrefix}/glossweg`}>WEG</a>
          </i>{' '}
          (Wohnungseigentumssachen)
        </h2>

        <p className="Body-Text">
          Sie können Ihre Rechte gegen den Verwalter, gegen die Gemeinschaft der Wohnungseigentümer oder gegen die Wohnungseigentümer selbst
          geltend machen.
        </p>

        <p className="Body-Text">
          Bei der <a href={`hilfe${urlPrefix}/glossweg`}>WEG</a> wird deshalb zunächst abgefragt, ob sich das Verfahren gegen den Verwalter,
          gegen die Wohnungseigentümergemeinschaft oder gegen Wohnungseigentümer selbst richtet.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Weg;
