import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import './antragsteller.css';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';

const Antragsteller = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Erfassen eines Antragstellers</title>
      </head>
      <body className="antragsteller">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="erfasseneinesantragstellers"></a>Erfassen eines Antragstellers
        </h2>

        <p className="Body-Text">
          In der Maske Angaben zum Antragstellers wird festgelegt welche Rechtsform der Antragsteller hat. Es ist eine der folgenden
          Zuweisungsmöglichkeiten auszuwählen: Herr/Frau (natürliche Person), Firma, Kennziffer oder sonstige. Im folgenden wird festgelegt
          wer der Antragsteller ist.
        </p>

        <p className="Body-Text">
          Haben Sie die entsprechenden Antragstellerdaten eingegeben, so bestätigen Sie die Eingabe mit Klick auf die{' '}
          <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
        </p>

        <p className="Body-Text">
          Sie werden gefragt, ob für den Antragsteller noch ein{' '}
          <a href={`hilfe${urlPrefix}/rechtsbeistand_vertretung`}>gesetzlicher Vertreter</a> erfasst werden soll. Hier haben Sie die
          Möglichkeiten <b>JA</b> oder <b>NEIN</b>. Bei einigen Rechtsformen entfällt diese Abfrage, weil die Angabe eines gesetzlichen
          Vertreters Pflicht ist. In diesen Fällen werden Sie automatisch zur Erfassung der erforderlichen Angaben aufgefordert.
        </p>

        <p className="Body-Text">
          Nachdem Sie einen Antragsteller erfasst haben, werden Sie gefragt, ob Sie einen weiteren Antragsteller erfassen möchten. Auch hier
          besteht die Wahlmöglichkeit zwischen <b>JA</b> oder <b>NEIN</b>.
        </p>

        <p className="RelatedHead">
          <div></div>
        </p>
        <p className="RelatedHead">
          <b>Siehe auch:</b>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsteller/ausland`}>Antragsteller im Ausland</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsteller/natuerliche_person`}>Erfassen einer Natürlichen Person</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsteller/firma`}>Erfassen einer Firma</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsteller/kennziffer`}>Erfassen einer Kennziffer</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsteller/sonstige`}>Erfassen eines Sonstigen Antragsteller</a>
        </p>
        <p className="RelatedHead">
          <div></div>
        </p>
        <p></p>
        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Antragsteller;
