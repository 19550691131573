import './gloss.css';
import React from 'react';

const GlossKennziffer = () => {
  return (
    <html>
      <head>
        <title>Kennziffer</title>
      </head>
      <body className="gloss kennziffer">
        <h5 className="heading-5">
          <a target="glosskennziffer"></a>Kennziffer
        </h5>

        <p className="Definition">
          Möglichkeit der Verschlüsselung von Antragsteller- oder Prozessbevollmächtigtenangaben in einem 8-stelligen Schlüssel (bei Gericht
          zu beantragen)
        </p>
      </body>
    </html>
  );
};
export default GlossKennziffer;
