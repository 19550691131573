import './hauptforderungen.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';

const Hauptforderungen = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Angaben zu Hauptforderung und Zinsen</title>
      </head>
      <body className="hauptforderungen">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="hauptforderungen"></a>Angaben zu Hauptforderung und Zinsen
        </h2>

        <p className="Body-Text">Hier legen Sie fest, welche Art von Mahnverfahren angestrebt werden soll.</p>
        <ul className="list">
          <li>
            <a href={`hilfe${urlPrefix}/hauptforderungen/regulaeres_mahnverfahren`}>Reguläres Mahnverfahren</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/hauptforderungen/urkunden_mahnverfahren`}>Urkunden-Mahnverfahren</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/hauptforderungen/scheck_mahnverfahren`}>Scheck-Mahnverfahren</a> oder
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/hauptforderungen/wechsel_mahnverfahren`}>Wechsel-Mahnverfahren</a>
          </li>
        </ul>
        <p></p>

        <p className="Body-Text">
          Bestätigen Sie Ihre Eingaben durch Klicken auf die <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} />{' '}
          -Schaltfläche.
        </p>

        <p className="Body-Text">
          Es öffnet sich eine Folgemaske. Hier werden im oberen Teil die bisher eingegebenen Ansprüche, die Hauptforderungssumme und die
          Gerichtskosten angezeigt.
        </p>

        <p className="Body-Text">
          Bitte bezeichnen Sie Ihren Anspruch so genau, dass der Anspruch unzweifelhaft identifiziert werden kann (Rechnung, Rechnungsnummer
          und Datum der Rechnung oder entsprechende Angaben). Bei der Geltendmachung mehrerer Ansprüche sollten Sie im Zweifel jede
          Einzelforderung entsprechend bezeichnen. Eine ungenügende Individualisierung der Ansprüche kann zu Rechtsnachteilen führen. Bei
          Unsicherheiten sollten Sie den Rat einer zur Rechtsberatung befugten Person (Rechtsanwalt usw.) einholen.
          <p></p>
          <b>
            Fügen Sie Ihrem Antrag jedoch <u>keine</u> Anlagen, Forderungsaufstellungen oder Rechnungskopien zur Bezeichnung des Anspruchs
            bei.
          </b>
        </p>

        <p className="RelatedHead">
          <b>Siehe auch:</b>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/hauptforderungen/katalognummer`}>Katalogisierbarer Anspruch</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/hauptforderungen/sonstiges`}>Sonstiger Anspruch</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/hauptforderungen/ausgerechneter_zins`}>Ausgerechneter Zins</a>
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Hauptforderungen;
