import './hinweis.css';
import React from 'react';

const Firefox = () => {
  return (
    <html>
      <head>Hinweis zu Mozilla Firefox</head>
      <body className="hinweis firefox">
        <h1>Hinweis zu Mozilla Firefox</h1>

        <div className="text">
          Mit dem in Mozilla Firefox integrierten PDF Viewer können Sie den Barcodeantrag nicht korrekt ausdrucken.
        </div>
        <div className="text">
          Installieren Sie bitte zuerst den Adobe Acrobat Reader und deaktivieren Sie dann den Mozilla Firefox folgendermaßen: <div></div>
          Rufen Sie im Mozilla Firefox den Menüpunkt Extras &gt; <b>Einstellungen</b> auf. Wählen Sie dort den Unterpunkt <b>Allgemein</b>{' '}
          aus und scrollen dann nach unten bis zur Überschrift <b>Anwendungen</b>. Suchen Sie nach dem Dateityp &quot;Portable Document
          Format (PDF)&quot; und wählen Sie dann in der rechten Spalte den Punkt &quot;Mit Adobe Acrobat Reader öffnen&quot; oder
          &quot;Jedes Mal nachfragen&quot; aus.
        </div>

        <a className="closeButton" href="javascript:window.close(true)">
          Fenster schließen
        </a>
      </body>
    </html>
  );
};
export default Firefox;
