// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eid {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 10pt;
  color: #ffffff;
  background-color: #669966 !important;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding: 5px;
}

.eid .ausweis-logo {
  float: left;
  margin: 5px 10px 5px 0;
  width: 184px;
  height: 57px;
  background-color: #ffffff;
}

.eid .clear-both {
  clear: both;
}

.eid h2 {
  font-size: 14pt;
  margin-top: 9pt;
  margin-left: 5px;
  margin-bottom: 7pt;
  font-weight: bold;
}

.eid A {
  color: #ffffff;
  text-decoration: underline;
  font-weight: normal;
}

.eid a:hover {
  color: #00ff00;
  text-decoration: underline;
}

.eid .back-to-top-table {
  width: 100%;
  border: 0;
}

.eid .back-to-top-cell {
  width: 30%;
  text-align: right;
}

.eid H3 {
  font-size: 10pt;
  margin-top: 15pt;
  margin-bottom: 0pt;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/dunning/eid/eid.css"],"names":[],"mappings":"AAAA;EACE,kDAAA;EACA,eAAA;EACA,cAAA;EACA,oCAAA;EAGA,qBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;AACF;;AAEA;EACE,WAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AACF","sourcesContent":[".eid {\n  font-family: Verdana, Arial, Helvetica, sans-serif;\n  font-size: 10pt;\n  color: #ffffff;\n  background-color: #669966 !important;\n  -ms-hyphens: auto;\n  -moz-hyphens: auto;\n  -webkit-hyphens: auto;\n  hyphens: auto;\n  padding: 5px;\n}\n\n.eid .ausweis-logo {\n  float: left;\n  margin: 5px 10px 5px 0;\n  width: 184px;\n  height: 57px;\n  background-color: #ffffff;\n}\n\n.eid .clear-both {\n  clear: both;\n}\n\n.eid h2 {\n  font-size: 14pt;\n  margin-top: 9pt;\n  margin-left: 5px;\n  margin-bottom: 7pt;\n  font-weight: bold;\n}\n\n.eid A {\n  color: #ffffff;\n  text-decoration: underline;\n  font-weight: normal;\n}\n\n.eid a:hover {\n  color: #00ff00;\n  text-decoration: underline;\n}\n\n.eid .back-to-top-table {\n  width: 100%;\n  border: 0;\n}\n\n.eid .back-to-top-cell {\n  width: 30%;\n  text-align: right;\n}\n\n.eid H3 {\n  font-size: 10pt;\n  margin-top: 15pt;\n  margin-bottom: 0pt;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
