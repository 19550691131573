import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';
import { RequestTypes } from 'app/config/constants';

const ZustellungVertreter = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Zustellung an gesetzlichen Vertreter des Antragsgegners</title>
      </head>
      <body className="generic-help zustellung-vertreter">
        <HelpNavigationMenu />
        {type === RequestTypes.Objection ? (
          <h2>Unterzeichnender erhebt den Widerspruch als gesetzlicher Vertreter des Antragsgegners</h2>
        ) : (
          <h2>Zustellung an den gesetzlichen Vertreter des Antragsgegners</h2>
        )}

        {type !== RequestTypes.Objection && (
          <div>
            <p className="Note">
              <strong>Hinweis:</strong> Falls Sie hier einen bisher nicht genannten gesetzlichen Vertreter eintragen, werden{' '}
              <strong>alle</strong> zuvor benannten gesetzlichen Vertreter des Antragsgegners durch diese Angabe ersetzt.
            </p>
            <p>
              Hier kann nur eine natürliche Person als Zustellungsempfänger ausgewählt werden.<div></div>
              Bei GmbH &amp; Co. KG, oder ähnlichen zweistufigen Vertretungen also nicht die Komplementärgesellschaft, sondern deren
              gesetzlicher Vertreter (Geschäftsführer, Vorstand, etc.). Ebenso ist bei einer WEG vertreten durch eine juristische Person als
              Verwalterin der gesetzliche Vertreter der Verwalterin einzutragen. Bei einer Partei Kraft Amtes (Insolvenzverwalter, o.ä.)
              muss der Name mit der Angabe aus dem MB-Antrag übereinstimmen (lediglich die Änderung der Anschrift ist hier zugelassen)!
            </p>
          </div>
        )}

        <p>
          Im Feld <span className="quotable">Stellung</span> wählen Sie aus der vorgegebenen Liste die Stellung des gesetzlichen Vertreter
          aus. Nur wenn in der Liste die Funktion des gesetzlichen Vertreters nicht enthalten ist, geben Sie diese im Feld{' '}
          <span className="quotable">sonstige Stellung</span> an.
        </p>

        <p>
          Im Feld <span className="quotable">Vor- und Nachname</span> geben Sie den kompletten Vor- und Nachnamen (ohne Abkürzungen) des
          gesetzlichen Vertreters an.
        </p>

        <p>
          Im Feld <span className="quotable">Straße/Hausnummer</span> und <span className="quotable">PLZ/Ort/Ausl.Kz.</span> müssen Sie die
          Adresse des gesetzlichen Vertreters eingeben. Das Auslandskennzeichen ist nur anzugeben, wenn der Wohnsitz nicht in Deutschland
          liegt. Befindet sich der Wohnsitz in Deutschland, so soll kein Auslandskennzeichen angegeben werden.
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default ZustellungVertreter;
