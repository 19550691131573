import { translate } from 'react-jhipster';
import { ValidationProps } from 'app/shared/components/validated/validatedComponent';
import { DropdownValidation } from './validation';

class DropdownEmptyValidation extends DropdownValidation {
  // eslint-disable-next-line @typescript-eslint/require-await
  static async validate(props: ValidationProps): Promise<string[]> {
    const errors = [];
    if (props.newValue === '' && !props.isOptional) {
      errors.push(translate('error.missing-value'));
    }

    return errors;
  }
}
export default DropdownEmptyValidation;
