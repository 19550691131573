// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eld-identity-layout {
  margin-top: 40px;
}

.transfer-button {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/generic/printAndSign/eIDidentityCard/eIDidentity.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,gBAAA;AAEF","sourcesContent":[".eld-identity-layout {\n  margin-top: 40px;\n}\n.transfer-button {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
