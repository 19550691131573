import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';

import React, { useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { BrowserRouter, useLocation, Location } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import Home from 'app/modules/home/home';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { isShowingSelector } from 'app/shared/layout/side-container/side-container-slice';

import SideContainer from './shared/layout/side-container';
import { useSelector } from 'react-redux';
import { typeSelector } from 'app/shared/reducers/requeststore';
import { setLocale } from 'app/shared/reducers/locale';

const baseHref = document.querySelector('base')?.getAttribute('href').replace(/\/$/, '') || '/';

export const App = () => {
  const dispatch = useAppDispatch();
  const showSideContainer = useSelector(isShowingSelector);

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  function getHeader(location: Location) {
    const locationSplit = location.pathname.split('/');
    if (locationSplit.length > 2) {
      return (
        <span>
          {translate(`${type}.title`)} - {translate(`pageheader.${type}.${locationSplit[locationSplit.length - 1]}`)}
        </span>
      );
    } else {
      return (
        <span>
          {translate(`${type}.title`)} - {translate(`pageheader.generic.${locationSplit[1]}`)}
        </span>
      );
    }
  }

  function BodyComponent() {
    const location = useLocation();
    return (
      <div>
        {location.pathname === '/' && <Home />}
        {location.pathname !== '/' && (
          <div>
            <Col className="col-12 col-xs-12 text-center">
              <h3 className="header-title">{getHeader(location)}</h3>
            </Col>
            <Row className="justify-content-md-center">
              <Col className="d-none d-sm-block col-sm-2 col-md-3" />
              <Col className={`col-12 col-xs-9 col-sm-8 col-md-6 theme-${type} `}>
                <Card className={`jh-card base`}>
                  <ErrorBoundary>
                    <AppRoutes />
                  </ErrorBoundary>
                </Card>
              </Col>
              {showSideContainer && (
                <Col className={`col-12 col-xxs-2 col-xs-3 col-sm-2 col-md-2 theme-${type}`}>
                  <Card className={`jh-card base`} id={'sidecontainer_card'}>
                    <ErrorBoundary>
                      <SideContainer />
                    </ErrorBoundary>
                  </Card>
                </Col>
              )}
              <Col className={'d-none d-sm-block'} />
            </Row>
            <Col className="col-xs-6 offset-xs-3">
              <Footer />
            </Col>
          </div>
        )}
      </div>
    );
  }

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const paddingTop = '60px';
  const type = useAppSelector(typeSelector);

  const queryParameters = new URLSearchParams(window.location.search);
  const lang = queryParameters.get('lang');
  if (lang && !isInProduction) dispatch(setLocale(lang));
  else dispatch(setLocale('de'));

  return (
    <BrowserRouter basename={baseHref}>
      <div className="app-container" style={{ paddingTop }}>
        <ToastContainer position="top-left" className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>
          <Header
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            currentLocale={currentLocale}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isOpenAPIEnabled={isOpenAPIEnabled}
          />
        </ErrorBoundary>
        <div className="container-fluid view-container" id="app-view-container">
          <BodyComponent />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
