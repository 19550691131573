import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';

const Pka = () => {
  return (
    <html>
      <head>
        <title>Partei kraft Amtes (PKA)</title>
      </head>

      <body className="antragsteller sonstige pka">
        <HelpNavigationMenu />

        <h2>
          <a target="pka"></a>
          <i>Partei kraft Amtes (PkA)</i>
        </h2>

        <p className="Body-Text">Wählen Sie aus der vorgegebenen Liste die Rechtsform des Antragstellers aus.</p>

        <p className="Body-Text">Im Feld &quot;Vornamen&quot; geben Sie den vollständigen Vornamen ohne Abkürzung ein.</p>

        <p className="Body-Text">Im Feld &quot;Nachnamen&quot; geben Sie den vollständigen Nachnamen ohne Abkürzung ein.</p>

        <p className="Body-Text">
          Im Feld &quot;Straße/Hausnummer&quot; tragen Sie den Straßennamen und die Hausnummer ein, in der der Antragsteller seinen Sitz
          hat.
        </p>

        <p className="Body-Text">
          Geben Sie die Postleitzahl und den Ort ein. Das Auslandskennzeichen ist nur anzugeben, wenn der Wohnsitz nicht in Deutschland
          liegt. Befindet sich der Wohnsitz in Deutschland, so soll kein Auslandskennzeichen angegeben werden.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Betreutes Vermögen&quot; geben Sie das betreute Vermögen an. Für die Eingabe des betreuten Vermögens stehen 2 Zeilen
          zur Verfügung.
        </p>

        <p className="Body-Text">
          <i>Beispiel: </i>
        </p>

        <p className="List">Konkursverwalter</p>

        <p className="List">Manfred</p>

        <p className="List">Mustermann</p>

        <p className="List">Musterstraße 16</p>

        <p className="List">88888 Musterstadt</p>

        <p className="List">Vermögen der Fa. Stein GmbH, Steinweg 2, 88888 Musterstadt</p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Pka;
