import axios from 'axios';
import { translate } from 'react-jhipster';

export default class ZipcodeCityValidation {
  static async validate(zipcode: string, city: string): Promise<[string[], string]> {
    const errors = [];
    let res;
    try {
      res = await axios.get(`/validation/validate-zip-code?zipCode=${zipcode}&city=${city}`);

      if (!res.data.valid) errors.push(translate('error.zipcode-city-validation'));
    } catch (error) {
      if (error.response) {
        errors.push(error.response.data);
      }
    }
    return [errors, res?.data.LKZ];
  }
}
