// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auslagen-nebenforderungen {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 10pt;
  color: #ffffff;
  background-color: #669966 !important;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding: 5px;
}

.auslagen-nebenforderungen H2 {
  font-size: 14pt;
  margin-top: 9pt;
  margin-bottom: 7pt;
  font-weight: bold;
}

.auslagen-nebenforderungen .Body-Text {
  line-height: 14pt;
  margin-top: 5pt;
  text-align: justify;
}

.auslagen-nebenforderungen .RelatedHead {
  font-size: 11pt;
  font-weight: bold;
  color: #ffffff;
  margin-top: -0.25em;
  margin-bottom: 0.5em;
}

.auslagen-nebenforderungen .Jump-from-List {
  text-decoration: underline;
  margin-bottom: 3pt;
  margin-top: -0.25em;
}

.auslagen-nebenforderungen A {
  color: #ffffff;
  text-decoration: underline;
  font-weight: normal;
}

.auslagen-nebenforderungen a:hover {
  color: #00ff00;
  font-weight: normal;
  text-decoration: underline;
}

.auslagen-nebenforderungen P.List {
  margin-left: 2em;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/dunning/auslagenNebenforderungen/auslagenNebenforderungen.css"],"names":[],"mappings":"AAAA;EACE,kDAAA;EACA,eAAA;EACA,cAAA;EACA,oCAAA;EAGA,qBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,oBAAA;AACF;;AAEA;EACE,0BAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,mBAAA;EACA,0BAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".auslagen-nebenforderungen {\n  font-family: Verdana, Arial, Helvetica, sans-serif;\n  font-size: 10pt;\n  color: #ffffff;\n  background-color: #669966 !important;\n  -ms-hyphens: auto;\n  -moz-hyphens: auto;\n  -webkit-hyphens: auto;\n  hyphens: auto;\n  padding: 5px;\n}\n\n.auslagen-nebenforderungen H2 {\n  font-size: 14pt;\n  margin-top: 9pt;\n  margin-bottom: 7pt;\n  font-weight: bold;\n}\n\n.auslagen-nebenforderungen .Body-Text {\n  line-height: 14pt;\n  margin-top: 5pt;\n  text-align: justify;\n}\n\n.auslagen-nebenforderungen .RelatedHead {\n  font-size: 11pt;\n  font-weight: bold;\n  color: #ffffff;\n  margin-top: -0.25em;\n  margin-bottom: 0.5em;\n}\n\n.auslagen-nebenforderungen .Jump-from-List {\n  text-decoration: underline;\n  margin-bottom: 3pt;\n  margin-top: -0.25em;\n}\n\n.auslagen-nebenforderungen A {\n  color: #ffffff;\n  text-decoration: underline;\n  font-weight: normal;\n}\n\n.auslagen-nebenforderungen a:hover {\n  color: #00ff00;\n  font-weight: normal;\n  text-decoration: underline;\n}\n\n.auslagen-nebenforderungen P.List {\n  margin-left: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
