import { useAppSelector } from 'app/config/store';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';

const NatuerlichePerson = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Einführung zum Online-Mahnantrag - Erfassen eines Antragsgegners - Erfassen einer Natürlichen Person</title>
      </head>

      <body className="antragsgegner natuerliche-person">
        <HelpNavigationMenu />

        <h2>
          <a target="natuerliche_person"></a>Erfassen einer Natürlichen Person
        </h2>

        <p className="Body-Text">
          Wählen Sie aus der vorgegebenen Liste die Anredeform des Antragsgegners aus. Hier gibt es die Auswahlmöglichkeiten Herr oder Frau.
        </p>

        <p className="Body-Text">
          Geben Sie den Vornamen des Antragsgegners im Feld &quot;Vornamen&quot; ein. Bitte tragen Sie den kompletten Vornamen des
          Antragsgegners ein, Abkürzungen sind nicht erlaubt.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Nachnamen&quot; tragen Sie bitte den kompletten Nachnamen ein. Auch hier sind Abkürzungen nicht erlaubt.
        </p>

        <p className="Body-Text">
          Berufsbezeichnung bzw. Geburtsdatum sollten nur zur Vermeidung von Verwechslungen erfolgen, etwa wenn zwei namensgleiche Personen
          unter derselben Anschrift wohnen.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Straße/Hausnummer&quot; geben Sie die Straße und Hausnummer ein, in der der Antragsgegner seinen Wohnsitz hat.
        </p>

        <p className="Body-Text">
          <b>
            Trägt die Adresse des Antragstellers einen c/o-Zusatz, tragen sie diesen bitte ebenfalls im Feld Straße/Hausnummer&quot; ein.
          </b>
        </p>

        <p className="Body-Text">
          <b>Beispiel: &quot;Hauptstraße 1 c/o Max Muster&quot;</b>
        </p>

        <p className="Body-Text">Geben Sie die Postleitzahl und den Ort ein.</p>

        <p className="Body-Text">
          <i>Beispiel:</i>
        </p>

        <p className="List">Herr</p>

        <p className="List">Manfred </p>

        <p className="List">Mustermann</p>

        <p className="List">Musterstraße 12</p>

        <p className="List">88888 Musterstadt </p>

        <p className="RelatedHead">
          <p></p>
        </p>
        <p className="RelatedHead">
          <b>Siehe auch:</b>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsgegner/ausland`}>Antragsgegner im Ausland</a>
        </p>
        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsgegner/nato`}>Zusatzabkommen zum NATO-Truppenstatut</a>
        </p>
        <p className="RelatedHead">
          <p></p>
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default NatuerlichePerson;
