import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Antragsgegnerbezeichnung = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Antragsgegnerbezeichnung</title>
      </head>
      <body className="generic-help antragsgegnerbezeichnung">
        <HelpNavigationMenu />
        <h2>Antragsgegnerbezeichnung</h2>

        <p>
          Hat sich die Bezeichnung des Antragsgegners seit dem Antrag auf Erlass eines Mahnbescheids geändert, so geben Sie hier die
          korrekte Bezeichnung ein.
        </p>

        <p>
          Im Feld <span className="quotable">Rechtsform</span> wählen Sie aus der vorgegebenen Liste die Rechtsform des Antragsgegners aus.
          Nur wenn in der Liste die Rechtsform des Antragsgegners nicht enthalten ist, geben Sie diese im Feld{' '}
          <span className="quotable">sonstige Rechtsform</span> an.
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Antragsgegnerbezeichnung;
