// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gloss {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 10pt;
  color: #ffffff;
  background-color: #669966 !important;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding: 5px;
}

.gloss h5 {
  font-size: 12pt;
  display: block;
  margin-block-end: 1.67em;
  font-weight: bold;
  unicode-bidi: isolate;
}

.gloss .Definition {
  line-height: 14pt;
  margin-top: 5.75pt;
  font-style: italic;
}

.gloss.auslkz A {
  color: #ffffff;
  text-decoration: underline;
  font-weight: normal;
}

.gloss.auslkz a:hover {
  color: #00ff00;
  font-weight: normal;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/dunning/gloss/gloss.css"],"names":[],"mappings":"AAAA;EACE,kDAAA;EACA,eAAA;EACA,cAAA;EACA,oCAAA;EAGA,qBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,wBAAA;EACA,iBAAA;EACA,qBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,mBAAA;EACA,0BAAA;AACF","sourcesContent":[".gloss {\n  font-family: Verdana, Arial, Helvetica, sans-serif;\n  font-size: 10pt;\n  color: #ffffff;\n  background-color: #669966 !important;\n  -ms-hyphens: auto;\n  -moz-hyphens: auto;\n  -webkit-hyphens: auto;\n  hyphens: auto;\n  padding: 5px;\n}\n\n.gloss h5 {\n  font-size: 12pt;\n  display: block;\n  margin-block-end: 1.67em;\n  font-weight: bold;\n  unicode-bidi: isolate;\n}\n\n.gloss .Definition {\n  line-height: 14pt;\n  margin-top: 5.75pt;\n  font-style: italic;\n}\n\n.gloss.auslkz A {\n  color: #ffffff;\n  text-decoration: underline;\n  font-weight: normal;\n}\n\n.gloss.auslkz a:hover {\n  color: #00ff00;\n  font-weight: normal;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
