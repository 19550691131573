import '../generic-help.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Rechtsbeistand = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Erfassen eines Prozeßbevollmächtigten</title>
      </head>
      <body className="generic-help rechtsbeistand">
        <HelpNavigationMenu />
        <h2>Erfassen eines Prozessbevollmächtigten</h2>

        <p>Bitte wählen Sie aus, zu welcher Gruppe Prozessbevollmächtigter Sie gehören:</p>
        <p></p>
        <ul>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/rechtsanwalt_rechtsbeistand`}>Rechtsanwalt/Rechtsbeistand</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/rechtsanwaltsgesellschaft`}>Rechtsanwalts-Gesellschaft</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/herr_frau`}>Herr / Frau</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/inkasso_kreditdienstleister`}>Inkasso-/Kreditdienstleister</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand/verbraucherverband`}>Verbraucherzentrale, /-verband</a>
          </li>
        </ul>
        <p></p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Rechtsbeistand;
