function navigate(offset: number, pagesArray: string[], urlPrefix: string) {
  if (urlPrefix === '') {
    return;
  }
  const actualUrl = location.href;
  const urlPrefixIndex = actualUrl.indexOf(urlPrefix);
  const endIndex = urlPrefixIndex + urlPrefix.length + 1;
  const currentPageUrl = actualUrl.substring(0, endIndex);
  const navigationPathFromArray = actualUrl.substring(endIndex);

  const currentIndexOfArray = pagesArray.findIndex(i => i === navigationPathFromArray);

  location.href = currentPageUrl + pagesArray[currentIndexOfArray + offset];
}

export function next(pagesArray: string[], urlPrefix: string) {
  navigate(1, pagesArray, urlPrefix);
}

export function back(pagesArray: string[], urlPrefix: string) {
  navigate(-1, pagesArray, urlPrefix);
}

export function home(pagesArray: string[], urlPrefix: string) {
  const actualUrl = location.href;
  location.href = actualUrl.substring(0, actualUrl.indexOf(urlPrefix) + urlPrefix.length + 1) + pagesArray[0];
}
export function closeWindow() {
  window.close();
}

export const removeItemByName = (array: string[], name: string) => {
  return array.filter(item => item !== name);
};

export const insertBeforeItem = (array: string[], newItem: string, beforeItem: string) => {
  const index = array.indexOf(beforeItem);
  if (index === -1) return array;
  const newArray = [...array];
  newArray.splice(index, 0, newItem);
  return newArray;
};
