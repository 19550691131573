import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';

const WegAntragsteller = () => {
  return (
    <html>
      <head>
        <title>Einführung zum Online-Mahnantrag - Erfassen eines Sonstigen Antragsteller - WEG</title>
      </head>
      <body className="antragsteller sonstige weg-antragsteller">
        <HelpNavigationMenu />

        <h2>
          <a target="weg_antragsteller"></a>
          <i>WEG als Antragsteller</i>
        </h2>

        <p className="Body-Text">
          Im Feld &quot;vollst. Bezeichnung&quot; muss die Bezeichnung &quot;Wohnungseigentümergemeinschaft&quot; oder &quot;Gemeinschaft
          der Wohnungseigentümer&quot; gefolgt von einer bestimmten Angabe des gemeinschaftlichen Grundstücks (z.B. nach postalische
          Anschrift oder Grundbuchbezeichnung, evtl. Flurstücksnummer) angegeben werden.
        </p>

        <p className="Body-Text">
          In den Feldern &quot;Straße/Hausnummer&quot; und &quot;PLZ/Ort/AuslKz&quot; ist die vollständige Anschrift der Gemeinschaft auch
          dann einzutragen, wenn sie bereits in der Parteibezeichnung der WEG vollständig enthalten ist. Die Angabe eines
          Auslandkennzeichens ist nicht zulässig.
        </p>

        <p className="Body-Text">
          Nachrichten und Bescheide des Gerichts werden bei Wohnungseigentümergemeinschaften immer an den gesetzlichen Vertreter
          (Miteigentümer oder Verwalter) versandt, der deshalb zusätzlich und mit vollständiger Anschrift angegeben werden muss.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default WegAntragsteller;
