// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eda-download-form-layout {
  margin-top: 40px;
}

.list-items-layout {
  margin-top: 20px;
}

.list-item {
  list-style-type: disc;
  margin-bottom: 10px;
}

.sub-text {
  margin-top: 10px;
  font-style: italic;
  padding-left: 20px;
}

.download-description {
  margin-top: 30px;
  margin-bottom: 10px;
}

.saveButton {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/generic/printAndSign/edaDownload/edaDownload.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AAAA;EACE,qBAAA;EACA,mBAAA;AAGF;;AADA;EACE,gBAAA;EACA,kBAAA;EACA,kBAAA;AAIF;;AAFA;EACE,gBAAA;EACA,mBAAA;AAKF;;AAHA;EACE,gBAAA;AAMF","sourcesContent":[".eda-download-form-layout {\n  margin-top: 40px;\n}\n.list-items-layout {\n  margin-top: 20px;\n}\n.list-item {\n  list-style-type: disc;\n  margin-bottom: 10px;\n}\n.sub-text {\n  margin-top: 10px;\n  font-style: italic;\n  padding-left: 20px;\n}\n.download-description {\n  margin-top: 30px;\n  margin-bottom: 10px;\n}\n.saveButton {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
