import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavDropdown } from '../menu-components';
import { Translate, translate } from 'react-jhipster';
import { useAppSelector } from 'app/config/store';
import { routeObject } from 'app/shared/oldPages/help/generic';
import { typeSelector } from 'app/shared/reducers/requeststore';

export const EnforcementHelpMenu = () => {
  const menuEntries = new Map<string, string>();
  const mainLabelForTranslateKey = 'menu.help';
  const requestTypeFoTranslateKey = 'enforcement';
  const labelKey = `${mainLabelForTranslateKey}.${requestTypeFoTranslateKey}`;
  menuEntries.set(`${labelKey}.application-for-enforcement-order`, '/hilfe/vollstreckungsbescheid/inhaltsverzeichnis');
  menuEntries.set(`${labelKey}.contact-person`, '/hilfe/vollstreckungsbescheid/ansprechpartner');

  insertDynamicMenuEntry();
  function handleOnClick(value: string) {
    if (value === '/') {
      alert('not implemented');
    } else {
      window.open(value, '_blank');
    }
  }

  function insertDynamicMenuEntry() {
    const type = useAppSelector(typeSelector);
    const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
    const routes = routeObject(urlPrefix);
    const currentUrl = window.location.href;
    if (!currentUrl.includes('hilfe')) {
      const pathParts = currentUrl.split('/');
      const lastPath = pathParts[pathParts.length - 1];
      for (const path in routes) {
        if (Object.prototype.hasOwnProperty.call(routes, path) && path === lastPath) {
          const labelName = `pageheader.${type}.${lastPath}`;
          const link = `hilfe${urlPrefix}/${path}`;
          const menuEntriesArray = Array.from(menuEntries.entries());
          menuEntriesArray.splice(1, 0, [labelName, link]);
          const updatedMenuEntries = new Map(menuEntriesArray);
          menuEntries.clear();
          for (const [key, value] of updatedMenuEntries) {
            menuEntries.set(key, value);
          }
          break;
        }
      }
    }
  }

  return (
    <NavDropdown name={translate('menu.help.label')} id="help-dropdown">
      {[...menuEntries.entries()].map(([key, value]) => (
        <DropdownItem key={key} onClick={() => handleOnClick(value)}>
          <Translate contentKey={key} />
        </DropdownItem>
      ))}
    </NavDropdown>
  );
};
