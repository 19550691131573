import './attornyOfRecord.scss';
import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { Button, Col, Form, Label, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import InputField from 'app/shared/layout/custom-fields/input/inputField';
import ValidatedDropdown from 'app/shared/components/validated/drop-down-box/validatedDropdown';
import dunningDropdownStyles from 'app/shared/components/validated/drop-down-box/dunningDropdownStyle';
import LocationDetailsField from 'app/shared/layout/custom-fields/input/locationDetailsField';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getApplicant,
  getAttorneyOfRecord,
  initialAttorneyOfRecordState,
  resetAttorneyOfRecord,
  setAttorneyOfRecord as setAttorneyOfRecordToStore,
  setFunctionToAttorneyOfRecord,
} from 'app/shared/reducers/requeststore';
import { DropdownConfigKeys, fetchDropdownConfig, getDropdownConfig } from 'app/shared/reducers/configuration';
import InputWithFormText from 'app/shared/layout/custom-fields/input-with-form-text/inputWithFormText';
import DunningNavigation from 'app/shared/components/UI/navigation/dunning-navigation';
import GenericInputValidation from 'app/shared/validations/genericInputValidation';
import FieldEmptyValidation from 'app/shared/validations/fieldEmptyValidation';
import DropdownEmptyValidation from 'app/shared/validations/dropdownEmptyValidation';
import { convertDateToIsoString, convertToStandartDateFormat } from 'app/shared/util/date-utils';
import LabelWithComponent from 'app/shared/layout/custom-fields/labelWithComponent';
import { RequestTypes } from 'app/config/constants';
import { RegisteredValidationMap } from 'app/shared/components/validated/validatedComponent';
import SpecialCharactersValidation from 'app/shared/validations/specialCharactersValidation';

export enum tabType {
  lawyer,
  lawyerCompany,
  mrMrs,
  collectionAgency,
  consumerAssociation,
}

export const TabPanelLayout = (props: { activeTab: tabType }) => {
  const applicant = useSelector(getApplicant);
  const requestType = useAppSelector(state => state.requestStore.type);
  const consumersAssociationOptions = getDropdownConfig(DropdownConfigKeys.consumerAssociations);
  const descriptionOptions = getDropdownConfig(DropdownConfigKeys.descriptions);
  const legalForms = getDropdownConfig(DropdownConfigKeys.legalForms);
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);

  useEffect(() => {
    if (!descriptionOptions || descriptionOptions.length === 0) dispatch(fetchDropdownConfig(DropdownConfigKeys.descriptions));
    if (!consumersAssociationOptions || consumersAssociationOptions.length === 0)
      dispatch(fetchDropdownConfig(DropdownConfigKeys.consumerAssociations));
    if (!legalForms || legalForms.length === 0) dispatch(fetchDropdownConfig(DropdownConfigKeys.legalForms));
  }, []);

  const attorneyOfRecordSessionData = useSelector(getAttorneyOfRecord);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [attorneyOfRecord, setAttorneyOfRecord] = useState({
    initialRender: true,
    type: props.activeTab,
    function: attorneyOfRecordSessionData.function,
    commissionDate: attorneyOfRecordSessionData.commissionDate,
    expenses: attorneyOfRecordSessionData.expenses,
    isApplicantEntitledToDeductInputText: attorneyOfRecordSessionData.isApplicantEntitledToDeductInputText,
    isLegalRepresentativeExemptFromPaymentSalesTax: attorneyOfRecordSessionData.isLegalRepresentativeExemptFromPaymentSalesTax,
    readerServiceCode: attorneyOfRecordSessionData.readerServiceCode,
    descriptionOrLegalOption: attorneyOfRecordSessionData.descriptionOrLegalOption,
    name: attorneyOfRecordSessionData.name[0],
    streetAndHouseNumber: attorneyOfRecordSessionData.streetAndHouseNumber,
    postCode: attorneyOfRecordSessionData.postCode,
    city: attorneyOfRecordSessionData.city,
    lkz: attorneyOfRecordSessionData.lkz,
    foreignCountry: attorneyOfRecordSessionData.foreignCountry,
    renumerationRvg: attorneyOfRecordSessionData.renumerationRvg,
    legalRepresentative: attorneyOfRecordSessionData.legalRepresentative,
    createdDunningRequest: false,
  });

  const [registeredValidations, setRegisteredValidations] = useState({} as RegisteredValidationMap);

  function handleNextClick() {
    dispatch(
      setAttorneyOfRecordToStore({
        ...attorneyOfRecord,
        name: [attorneyOfRecord.name],
      }),
    );
    if (requestType === RequestTypes.Dunning) {
      navigateDunning();
    } else if (requestType === RequestTypes.RedeliveryDunning) {
      navigate(urlPrefix + '/bankverbindung');
    } else if (requestType === RequestTypes.Enforcement) {
      navigateEnforcement();
    } else if (requestType === RequestTypes.RedeliveryEnforcement) {
      navigateRedeliveryEnforcement();
    } else if (requestType === RequestTypes.Objection) {
      navigateObjection();
    }
    dispatch(setFunctionToAttorneyOfRecord(attorneyOfRecord.type));
  }

  function navigateEnforcement() {
    if (
      !attorneyOfRecord.legalRepresentative &&
      [tabType.lawyerCompany, tabType.collectionAgency, tabType.consumerAssociation].includes(props.activeTab)
    ) {
      navigate(urlPrefix + '/rechtsbeistand_vertretung');
    } else {
      navigate(urlPrefix + '/bankverbindung');
    }
  }

  function navigateDunning() {
    if (
      !attorneyOfRecord.legalRepresentative &&
      [tabType.lawyerCompany, tabType.collectionAgency, tabType.consumerAssociation].includes(props.activeTab)
    ) {
      navigate(urlPrefix + '/rechtsbeistand_vertretung');
    } else {
      navigate(urlPrefix + '/antragsteller');
    }
  }

  function navigateRedeliveryEnforcement() {
    if ((attorneyOfRecord.commissionDate !== '' && attorneyOfRecord.readerServiceCode !== '') || props.activeTab === tabType.lawyer) {
      navigate(urlPrefix + '/bankverbindung');
    } else if (
      !attorneyOfRecord.legalRepresentative &&
      [tabType.lawyerCompany, tabType.collectionAgency, tabType.consumerAssociation].includes(props.activeTab)
    ) {
      navigate(urlPrefix + '/rechtsbeistand_vertretung');
    }
  }

  function navigateObjection() {
    if (
      !attorneyOfRecord.legalRepresentative &&
      requestType !== RequestTypes.Objection &&
      [tabType.lawyerCompany, tabType.collectionAgency, tabType.consumerAssociation].includes(props.activeTab)
    ) {
      navigate(urlPrefix + '/rechtsbeistand_vertretung');
    } else {
      navigate(urlPrefix + '/antragsgegneranschrift');
    }
  }

  function deleteAttorneyOfRecord() {
    dispatch(resetAttorneyOfRecord());
    setAttorneyOfRecord({ ...initialAttorneyOfRecordState, initialRender: true });
  }

  function deleteAttorneyLegalRep() {
    setAttorneyOfRecord({ ...attorneyOfRecord, legalRepresentative: null, initialRender: false });
  }

  const handleChange = e => {
    const { id, value, checked, type } = e.target;
    let newValue = type === 'checkbox' ? checked : value;
    newValue = type === 'date' ? convertDateToIsoString(newValue) : value;

    setAttorneyOfRecord({ ...attorneyOfRecord, [id]: newValue, initialRender: false });
  };

  const handleChangeDropDown = event => {
    if (event.value !== '') setBothEmptyError([]);

    setAttorneyOfRecord({ ...attorneyOfRecord, [event.id]: event.value, initialRender: false });
  };

  const [bothEmptyError, setBothEmptyError] = useState([]);

  const onBlurValidation = (): Promise<string[]> => {
    return new Promise(resolve => {
      let errors = [];

      if (attorneyOfRecord.initialRender) resolve([]);

      if (
        (attorneyOfRecord.readerServiceCode !== '' && attorneyOfRecord.descriptionOrLegalOption !== '') ||
        (attorneyOfRecord.readerServiceCode === '' && attorneyOfRecord.descriptionOrLegalOption === '')
      ) {
        errors = [
          translate('error.fill-id-or-other', {
            idField: translate('dunning.attorneyOfRecord.reader-service-code.name'),
          }),
        ];
      }

      if (errors.length === bothEmptyError.length) resolve(errors);

      setBothEmptyError(errors);
      resolve(errors);
    });
  };

  useEffect(() => {
    if (!attorneyOfRecord.initialRender) onBlurValidation();
  }, [attorneyOfRecord.descriptionOrLegalOption, attorneyOfRecord.readerServiceCode]);

  return (
    <div>
      <Form className="form-layout">
        {applicant !== 'own-lawyer' && requestType !== RequestTypes.Objection && (
          <InputWithFormText
            id="commissionDate"
            type="date"
            name="kommissionDatum"
            labelContentKey="dunning.attorneyOfRecord.commisioning-date.name"
            labelDescription="dunning.attorneyOfRecord.commisioning-date.description"
            value={convertToStandartDateFormat(attorneyOfRecord.commissionDate)}
            handleChange={handleChange}
            validations={[FieldEmptyValidation]}
            setRegisteredValidations={setRegisteredValidations}
            registeredValidations={registeredValidations}
          />
        )}

        {requestType !== RequestTypes.Objection &&
          requestType !== RequestTypes.RedeliveryEnforcement &&
          [tabType.lawyer, tabType.lawyerCompany].includes(props.activeTab) && (
            <InputWithFormText
              id="expenses"
              name="expenses"
              labelContentKey="dunning.attorneyOfRecord.expenses.name"
              labelDescription="dunning.attorneyOfRecord.expenses.description"
              handleChange={handleChange}
              value={attorneyOfRecord.expenses}
              validations={[SpecialCharactersValidation]}
              setRegisteredValidations={setRegisteredValidations}
              registeredValidations={registeredValidations}
            />
          )}
        {requestType !== RequestTypes.Objection && requestType !== RequestTypes.RedeliveryEnforcement && (
          <InputField
            id="isApplicantEntitledToDeductInputText"
            labelContentKey="dunning.attorneyOfRecord.applicant-not-entitled-to-deduct-input-text"
            name="Vorsteuerabzugsberechtigung"
            onChange={handleChange}
            type="checkbox"
            value={attorneyOfRecord.isApplicantEntitledToDeductInputText}
            checked={attorneyOfRecord.isApplicantEntitledToDeductInputText}
            validations={[]}
            setRegisteredValidations={undefined}
            registeredValidations={undefined}
          />
        )}
        {[tabType.collectionAgency, tabType.lawyer, tabType.lawyerCompany, tabType.consumerAssociation].includes(props.activeTab) &&
          requestType !== RequestTypes.Objection &&
          requestType !== RequestTypes.RedeliveryEnforcement && (
            <InputField
              id="isLegalRepresentativeExemptFromPaymentSalesTax"
              labelContentKey="dunning.attorneyOfRecord.legal-representative-exempt-from-payment-sales-tax"
              name="Umsatzsteuerabzugsbefreiung"
              onChange={handleChange}
              type="checkbox"
              value={attorneyOfRecord.isLegalRepresentativeExemptFromPaymentSalesTax}
              checked={attorneyOfRecord.isLegalRepresentativeExemptFromPaymentSalesTax}
              validations={[]}
              setRegisteredValidations={undefined}
              registeredValidations={undefined}
            />
          )}
        <p className="court-assigned-guardian-ad-item-id">
          <Translate contentKey="dunning.attorneyOfRecord.court-assigned-guardian-ad-item-id"></Translate>
        </p>
        <InputWithFormText
          id="readerServiceCode"
          name="leserServiceCode"
          labelContentKey="dunning.attorneyOfRecord.reader-service-code.name"
          labelDescription="dunning.attorneyOfRecord.reader-service-code.description"
          handleChange={handleChange}
          value={attorneyOfRecord.readerServiceCode}
          validations={[GenericInputValidation]}
          isOptional={attorneyOfRecord.descriptionOrLegalOption !== ''}
          externalErrors={bothEmptyError}
          key={'sc' + bothEmptyError.length}
          customValidations={[onBlurValidation]}
          setRegisteredValidations={setRegisteredValidations}
          registeredValidations={registeredValidations}
        />
        <p className="enter-data">
          <Translate contentKey="dunning.attorneyOfRecord.enter-data"></Translate>
        </p>
        {props.activeTab === tabType.lawyer && (
          <div>
            <LabelWithComponent contentKey="dunning.attorneyOfRecord.description" labelId="label-descriptionOrLegalOption">
              <ValidatedDropdown
                labelledby="label-descriptionOrLegalOption"
                id="descriptionOrLegalOption"
                name="Bezeichnung"
                options={descriptionOptions}
                onChange={handleChangeDropDown}
                isOptional={attorneyOfRecord.readerServiceCode !== ''}
                value={attorneyOfRecord.descriptionOrLegalOption}
                style={dunningDropdownStyles}
                externalErrors={bothEmptyError}
                key={'desc' + bothEmptyError.length}
                validations={[DropdownEmptyValidation]}
                // customValidations={[onBlurValidation]} custom validation done by useEffect hook
                registeredValidations={registeredValidations}
                setRegisteredValidations={setRegisteredValidations}
              />
            </LabelWithComponent>
            <InputField
              id="name"
              onChange={handleChange}
              className="first-and-last-name"
              labelContentKey="dunning.attorneyOfRecord.first-and-last-name"
              type="text"
              value={attorneyOfRecord.name}
              validations={[GenericInputValidation]}
              isOptional={attorneyOfRecord.readerServiceCode !== ''}
              externalErrors={bothEmptyError}
              key={'name' + bothEmptyError.length}
              customValidations={[onBlurValidation]}
              setRegisteredValidations={setRegisteredValidations}
              registeredValidations={registeredValidations}
            />
          </div>
        )}
        {props.activeTab === tabType.mrMrs && (
          <InputField
            id="name"
            onChange={handleChange}
            className="first-and-last-name"
            labelContentKey="dunning.attorneyOfRecord.first-and-last-name"
            type="text"
            value={attorneyOfRecord.name}
            validations={[GenericInputValidation]}
            isOptional={true}
            externalErrors={bothEmptyError}
            key={'fn' + bothEmptyError.length}
            customValidations={[onBlurValidation]}
            setRegisteredValidations={setRegisteredValidations}
            registeredValidations={registeredValidations}
          />
        )}
        {[tabType.lawyerCompany, tabType.collectionAgency, tabType.consumerAssociation].includes(props.activeTab) && (
          <div>
            <LabelWithComponent contentKey="dunning.attorneyOfRecord.legal-form" labelId="label-descriptionOrLegalOption">
              <ValidatedDropdown
                labelledby="label-descriptionOrLegalOption"
                id="descriptionOrLegalOption"
                onChange={handleChangeDropDown}
                name="Rechtsform"
                options={props.activeTab === tabType.consumerAssociation ? consumersAssociationOptions : legalForms}
                value={attorneyOfRecord.descriptionOrLegalOption}
                validations={[DropdownEmptyValidation]}
                registeredValidations={registeredValidations}
                setRegisteredValidations={setRegisteredValidations}
                isOptional={true}
                externalErrors={bothEmptyError}
                key={'leg' + bothEmptyError.length}
                style={{
                  control: {
                    border: '1px solid #669966',
                    activeBorder: '1px solid #669966',
                    borderHover: '1px solid #99cc98',
                  },
                  dropdownIndicator: {
                    fill: '#669966',
                  },
                  indicatorSeparator: {
                    backgroundColor: '#669966',
                  },
                }}
              />
            </LabelWithComponent>
            <InputField
              id="name"
              onChange={handleChange}
              className="first-and-last-name"
              labelContentKey="dunning.attorneyOfRecord.full-description"
              type="textarea"
              value={attorneyOfRecord.name}
              validations={[GenericInputValidation]}
              customValidations={[onBlurValidation]}
              isOptional={attorneyOfRecord.readerServiceCode !== ''}
              externalErrors={bothEmptyError}
              key={'loc' + bothEmptyError.length}
              setRegisteredValidations={setRegisteredValidations}
              registeredValidations={registeredValidations}
            />
          </div>
        )}
        <InputField
          id="streetAndHouseNumber"
          className="street-house-number"
          onChange={handleChange}
          labelContentKey="dunning.attorneyOfRecord.street-house-number"
          type="text"
          value={attorneyOfRecord.streetAndHouseNumber}
          validations={[GenericInputValidation]}
          isOptional={attorneyOfRecord.readerServiceCode !== ''}
          externalErrors={bothEmptyError}
          key={'sthn' + bothEmptyError.length}
          setRegisteredValidations={setRegisteredValidations}
          registeredValidations={registeredValidations}
        />
        <LocationDetailsField
          onChangePostCode={handleChange}
          onChangeCity={handleChange}
          onChangeCountry={handleChange}
          postCodeValue={attorneyOfRecord.postCode}
          cityValue={attorneyOfRecord.city}
          foreignCountryValue={attorneyOfRecord.foreignCountry}
          isOptional={attorneyOfRecord.readerServiceCode !== ''}
          externalErrors={bothEmptyError}
          key={'loc2' + bothEmptyError.length}
          registeredValidations={registeredValidations}
          setRegisteredValidations={setRegisteredValidations}
          setLKZ={(lkz: string) => setAttorneyOfRecord({ ...attorneyOfRecord, lkz })}
        ></LocationDetailsField>
        <Row className="mb-5"></Row>
        {(props.activeTab === tabType.lawyer || props.activeTab === tabType.lawyerCompany) && requestType !== RequestTypes.Objection && (
          <div>
            <InputField
              id="renumerationRvg"
              onChange={handleChange}
              labelContentKey="dunning.attorneyOfRecord.renumeration-rvg.name"
              type="text"
              descriptionLabelContentKey="dunning.attorneyOfRecord.renumeration-rvg.description"
              value={attorneyOfRecord.renumerationRvg}
              validations={[SpecialCharactersValidation]}
              setRegisteredValidations={setRegisteredValidations}
              registeredValidations={registeredValidations}
            />
          </div>
        )}
        <Row className="mb-5"></Row>
        {attorneyOfRecord?.legalRepresentative && (
          <>
            <Row>
              <Col md="3" style={{ display: 'flex', justifyContent: 'right' }}>
                <Label className="legal-representative-label" for="legal-representative">
                  <Translate contentKey="generic.legalRepRepresentation.represented-by" />
                </Label>
              </Col>
              <Col md="6" className="delete-attorny-of-record-layout">
                <Link
                  className="delete-attorny-of-record"
                  to="/mahnbescheid/rechtsbeistand_vertretung"
                  id={'Prozessbevollmächtigten bearbeiten'}
                >
                  <span>{attorneyOfRecord.legalRepresentative.function + ' ' + attorneyOfRecord.legalRepresentative.name}</span>
                </Link>
              </Col>
              <Col md="3" style={{ textAlign: 'right' }}>
                <Button name="rechtsbeistand löschen" onClick={deleteAttorneyLegalRep}>
                  <Translate contentKey="entity.action.delete"></Translate>
                </Button>
              </Col>
            </Row>
            <Row className="mb-5"></Row>
          </>
        )}
        <Row>
          <Col md="3"></Col>
          <Col md="6" className="delete-attorny-of-record-layout" lg={8}>
            <Link
              className="delete-attorny-of-record"
              to="/mahnbescheid/rechtsbeistand"
              onClick={deleteAttorneyOfRecord}
              id={'Prozessbevollmächtigten löschen'}
            >
              <Translate contentKey="dunning.attorneyOfRecord.delete-attorny-of-record"></Translate>
            </Link>
          </Col>
          <Col md="3"></Col>
        </Row>
      </Form>
      <Row>
        <DunningNavigation
          handleOnNextClick={handleNextClick}
          nameSuffix="tab-panel-layout"
          registeredValidations={registeredValidations}
        />
      </Row>
    </div>
  );
};

export default TabPanelLayout;
