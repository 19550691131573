// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-modal .modal-content {
  border: 3px solid #721c24;
}

.alert-modal .modal-header {
  border-bottom: 3px solid #721c24;
  color: #721c24;
}

.alert-modal .modal-body {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/generic/alertModal/alertModal.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,gCAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".alert-modal .modal-content {\n  border: 3px solid #721c24;\n}\n\n.alert-modal .modal-header {\n  border-bottom: 3px solid #721c24;\n  color: #721c24;\n}\n\n.alert-modal .modal-body {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
