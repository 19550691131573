import { useAppSelector } from 'app/config/store';
import React from 'react';

const WechselMahnverfahren = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Wechsel-Mahnverfahren</title>
      </head>
      <body className="hauptforderungen wechsel-mahnverfahren">
        <h5 className="heading-5">
          <a target="wechsel_mahnverfahren"></a>Wechsel-Mahnverfahren
        </h5>

        <p className="Definition">
          Sonderform des <a href={`hilfe${urlPrefix}/hauptforderungen/urkunden_mahnverfahren`}>Urkunden-Mahnverfahrens</a>.
        </p>

        <p className="Definition">
          Kommt es zum streitigen Verfahren (etwa bei Widerspruch oder Einspruch des Antragsgegners), so mündet das Wechsel-Mahnverfahren in
          einen Wechselprozess (siehe{' '}
          <a href="https://dejure.org/gesetze/ZPO" target="_blank" rel="noreferrer">
            ZPO
          </a>{' '}
          §592 und folgende)
        </p>

        <p className="Definition">
          Will der Antragsteller ein Wechselmahnverfahren durchführen, ist dieses im Antrag auf Erlass des Mahnbescheides gesondert
          kenntlich zu machen.
        </p>
      </body>
    </html>
  );
};
export default WechselMahnverfahren;
