import React from 'react';
import { Button } from 'reactstrap';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';
import { Translate } from 'react-jhipster';

const Katalognummer = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Katalogisierbarer Anspruch</title>
      </head>
      <body className="hauptforderungen katalognummer">
        <HelpNavigationMenu />

        <h2>
          <a target="katalognummer"></a>Katalogisierbarer Anspruch
        </h2>

        <p className="Body-Text">
          Wählen Sie aus der vorgegebenen Liste die Bezeichnung Ihrer Forderung aus (z.B. Warenlieferung, Vergleich, etc.). Sollte keine der
          vorgegebenen Bezeichnungen Ihrer Forderung entsprechen, so handelt es sich wahrscheinlich um einen{' '}
          <a href={`hilfe${urlPrefix}/hauptforderungen/sonstiges`}>sonstigen Anspruch</a>.
        </p>

        <p className="Body-Text">Beispiel:</p>

        <p className="Body-Text">
          <a href={`hilfe${urlPrefix}/hauptforderungen/katalognummer/katalognummern`} target="_blank" rel="noreferrer">
            Kat-Nr./Kat.-Anspruch
          </a>
        </p>
        <ol className="Body-Text">
          <li>Anzeigen in Zeitung u.a.</li>
          <li value="3">Bürgschaft</li>
          <li value="11">Kaufvertrag</li>
          <li value="19">Miete für Wohnraum einschl. Nebenkosten</li>
          <li value="43">Warenlieferung</li>
        </ol>

        <p className="Body-Text">Machen Sie nähere Angaben zum Anspruch.</p>

        <p className="Body-Text">
          Geben Sie an, wie dem Schuldner/Antragsgegner die Forderung(en) mitgeteilt wurden. Dazu steht Ihnen eine Auswahlliste zur
          Verfügung. Sie haben auch die Möglichkeit eine andere Form der Mitteilung manuell einzugeben.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Rechnungsnummer o.ä.&quot; geben Sie Rechnungsnummer (oder Vertragsnummer oder Kundennummer) ein.
        </p>

        <p className="Body-Text">
          <b>Sonderfälle:</b> Bei Katalognummer 36 (Überziehung des Bankkontos) geben Sie hier die Kontonummer an. Bei Katalognummer 42
          (Versorgungsleistung - Strom, Wasser, Gas, Wärme) geben Sie hier die Abrechnungs-/Zählernummer an. Bei Katalognummer 61
          (Wahlleistungen bei stationärer Behandlung) geben Sie hier die Art der Wahlleistung an.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Anspruch vom/bis&quot; geben Sie, falls Sie einen Anspruch für einen bestimmten Zeitraum geltend machen, den Beginn
          und das Ende des Zeitraumes ein.{' '}
        </p>

        <p className="Body-Text">
          Beispiel:<p></p>
          Der Anspruch resultiert aus Katalog-Nr. 19 (Miete für Wohnraum einschl. Nebenkosten) für die Monate April und Mai 2001, so würde
          das Feld &quot;vom/bis&quot; wie folgt ausgefüllt:<p></p>
          (vom) 01.04.2001 (bis) 31.05.2001
        </p>

        <p className="Note">
          Hinweis: Das &quot;Anspruch vom&quot;-Datum muss immer angegeben werden. Das &quot;Anspruch bis&quot;-Datum muss nur in einigen
          Fällen angegeben werden.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Betrag&quot; tragen Sie den Hauptforderungsbetrag ein. Handelt es sich bei dem geltend zu machenden Anspruch
          lediglich um eine Teilforderung, eine Restforderung oder eine Rate, kann dies dadurch ersichtlich gemacht werden, dass im
          bis-Datum der Begriff &quot;Teil&quot;, &quot;Rest&quot; oder &quot;Rate&quot; eingetragen wird.
        </p>

        <p className="Body-Text">
          Sie haben die Möglichkeit ein Kontrollkästchen zu aktivieren das aussagt, ob der Antragsteller Kreditgeber ist und der Anspruch
          aus Verbraucherdarlehensvertrag (§§491 ff BGB) geltend gemacht wird. Ist dies der Fall, so werden Sie später automatisch
          aufgefordert die erforderlichen Angaben zu machen.
        </p>

        <p className="Body-Text">
          Haben Sie alle Daten eingegeben, bestätigen Sie diese Eingaben mit Klick auf die{' '}
          <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
        </p>

        <p className="Body-Text">Es öffnet sich eine Maske, in der Sie weitere Daten zum Kataloganspruch machen können.</p>

        <ul>
          <li>Eine Abtretung zum Anspruch erfassen</li>
          <li>Zinsangaben zum Anspruch erfassen</li>
          <li>Einen weiteren Anspruch oder ausgerechnete Zinsen erfassen/ändern</li>
          <li>Keine weiteren Angaben zu Hauptforderung oder Zinsen</li>
        </ul>

        <p className="Body-Text">
          Wählen Sie aus, ob Sie weitere Angaben (Abtretung, Zinsen) zu diesem Anspruch machen wollen oder einen weiteren Anspruch erfassen
          wollen oder keine weiteren Angaben zu Hauptforderung und/oder Zinsen machen möchten. Betätigen Sie dann die{' '}
          <Button disabled className="next-button" name={'weiter'}>
            <Translate contentKey="generic.next" /> <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
          </Button>
          -Schaltfläche.-Schaltfläche.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Katalognummer;
