import { combineReducers } from '@reduxjs/toolkit';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';


import administration from 'app/modules/administration/administration.reducer';
import entitiesReducers from 'app/entities/reducers';
import sideContainer from 'app/shared/layout/side-container/side-container-slice';
import requestStore from 'app/shared/reducers/requeststore';
import configurationStore from 'app/shared/reducers/configuration';
import eIDIdentityCardStore from 'app/shared/reducers/eIDIdentityCard';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer } from 'redux-persist';
import applicationProfile from './application-profile';
import authentication from './authentication';
import locale from './locale';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  authentication,
  locale,
  applicationProfile,
  administration,
  loadingBar,
  sideContainer,
  requestStore: persistReducer(persistConfig, requestStore),
  configurationStore,
  eIDIdentityCardStore,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  ...entitiesReducers,
});

export default rootReducer;
