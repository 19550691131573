import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Button } from 'reactstrap';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import './bundeslandauswahl.css';

const Bundeslandauswahl = () => {
  return (
    <html>
      <body className="bundeslandauswahl">
        <HelpNavigationMenu />
        <h2 className="heading-2">
          <a target="bundeslandauswahl"></a>Bundeslandauswahl
        </h2>

        <p className="Body-Text">
          Zuständig für das Mahnverfahren ist in der Regel das Amtsgericht, in dessen Bezirk der Antragsteller seinen Wohnsitz/Sitz hat.
          Bitte beachten Sie die im Lande geltenden Verordnungen, die die Mahnverfahren aus den Bezirken mehrerer oder aller Amtsgerichte
          des Landes bestimmten Gerichte zuweisen. Der Online-Mahnantrag ermittelt automatisch das zuständige Amtsgericht (Zentrales
          Mahngericht).
        </p>

        <p className="Body-Text">
          Wenn Sie Ihren Wohnsitz/Sitz im Ausland haben, ist für Sie das Amtsgericht <i>Wedding in Berlin</i> zuständig.
        </p>

        <p className="Body-Text">
          Wählen Sie nun aus der Liste dasjenige Bundesland aus, in welchem der Antrag gestellt werden soll. In der Regel wählen Sie das
          Bundesland aus, in dem Sie ihren Wohnsitz haben.
        </p>

        <p className="Body-Text">
          Haben Sie ein Bundesland ausgewählt, klicken Sie auf die{' '}
          <Button disabled className="next-button" name={'weiter'}>
            <Translate contentKey="generic.next" /> <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
          </Button>
          -Schaltfläche.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Bundeslandauswahl;
