import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const AnwaltEigen = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>RA in eig.Sache</title>
      </head>
      <body className="antragsteller sonstige anwalt-eigen">
        <HelpNavigationMenu />

        <h2>
          <a target="anwalt_eigen"></a>
          <i>RA in eig.Sache</i>
        </h2>
        <p className="Body-Text">
          Hier ist anzugeben, ob der Rechtsanwalt den Antrag in eigener Sache stellt. Dazu muss der Rechtsanwalt bzw. die Rechtsanwälte als{' '}
          <a href={`hilfe${urlPrefix}/rechtsbeistand`}>Prozessbevollmächtige</a> erfasst und das vorhandene Kästchen markiert werden.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default AnwaltEigen;
