import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';

const Weitere = () => {
  return (
    <html>
      <head>
        <title>Einführung zum Online-Mahnantrag - Erfassen eines Sonstigen Antragsteller - Weitere</title>
      </head>
      <body className="antragsteller sonstige weitere">
        <HelpNavigationMenu />
        <h2>
          <a target="weitere"></a>
          <i>Weitere</i>
        </h2>

        <p className="Body-Text">
          Hier haben Sie die Möglichkeit einen Antragsteller anzugeben, der nicht unter die bisher genannten Kategorien fällt.
        </p>

        <p className="Body-Text">Im Feld &quot;Rechtsform&quot; wählen Sie die Rechtsform aus, die der Antragsteller hat.</p>

        <p className="Body-Text">
          Im Feld &quot;Bezeichnung&quot; geben Sie die komplette Bezeichnung des Antragstellers ein. Bitte verwenden Sie keinerlei
          Abkürzungen, da der Antrag ansonsten eventuell moniert werden würde.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Straße/Hausnummer&quot; tragen Sie den Straßennamen und die Hausnummer ein, in der der Antragsteller seinen Sitz
          hat.
        </p>

        <p className="Body-Text">
          Geben Sie die Postleitzahl und den Ort ein. Das Auslandskennzeichen ist nur anzugeben, wenn der Wohnsitz nicht in Deutschland
          liegt. Befindet sich der Wohnsitz in Deutschland, so soll kein Auslandskennzeichen angegeben werden.
        </p>

        <p className="Body-Text">
          <i>Beispiel:</i>
        </p>

        <p className="List">Vereinigung der Antragsteller eV</p>

        <p className="List">eingetragener Verein</p>

        <p className="List">Musterstraße 12</p>

        <p className="List">88888 Musterstadt</p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Weitere;
