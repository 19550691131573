import './gloss.css';
import React from 'react';

const GlossWeg = () => {
  return (
    <html>
      <title>Gloss WEG</title>
      <body className="gloss weg">
        <h5 className="heading-5">
          <a target="glossweg">WEG</a>
        </h5>

        <p className="Definition">Wohnungseigentümergemeinschaft</p>

        <p className="index-heading"></p>

        <p className="index-heading"></p>
      </body>
    </html>
  );
};
export default GlossWeg;
