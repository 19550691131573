import { useAppSelector } from 'app/config/store';
import React from 'react';

const RegulaeresMahnverfahren = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Reguläres Mahnverfahren</title>
      </head>
      <body className="hauptforderungen regulaeres-mahnverfahren">
        <h5 className="heading-5">
          <a target="regulaeres_mahnverfahren"></a>Reguläres Mahnverfahren
        </h5>
        <p className="Definition">Mahnverfahren, die nicht unter</p>
        <ul>
          <li>
            <a href={`hilfe${urlPrefix}/hauptforderungen/urkunden_mahnverfahren`}>Urkunden-Mahnverfahren</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/hauptforderungen/scheck_mahnverfahren`}>Scheck-Mahnverfahren</a> oder
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/hauptforderungen/wechsel_mahnverfahren`}>Wechsel-Mahnverfahren</a>
          </li>
        </ul>
        fallen.
        <p></p>
      </body>
    </html>
  );
};

export default RegulaeresMahnverfahren;
