import { Validation } from 'app/shared/validations/validation';
import { translate } from 'react-jhipster';
import { ValidationProps } from 'app/shared/components/validated/validatedComponent';

export default class SpecialCharactersValidation extends Validation {
  // eslint-disable-next-line @typescript-eslint/require-await
  static async validate(props: ValidationProps): Promise<string[]> {
    const errors = [];
    const specialCharacters = 'äöüÜÖÄß@€';

    if (!props.newValue) return [];

    for (const c of props.newValue) {
      if (c.charCodeAt(0) > 127 && !specialCharacters.includes(c)) {
        errors.push(translate('error.invalid-character', { character: c }));
      }
    }

    return errors;
  }
}
