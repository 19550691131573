import '../generic-help.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';
import { RequestTypes } from 'app/config/constants';

const InkassoKreditdienstleister = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Erfassen eines Prozeßbevollmächtigten</title>
      </head>
      <body className="generic-help inkasso-kreditdienstleister">
        <HelpNavigationMenu />
        <h2>Erfassen eines Prozessbevollmächtigten (Inkasso-/Kreditdienstleister)</h2>

        <p className="Note">
          <u>Hinweis:</u> Hier dürfen Sie nur Eingaben machen, wenn es sich bei Ihnen oder Ihrem Unternehmen um eine registrierte Person
          nach § 10 Abs. 1 Satz 1 Nr. 1 des Rechtsdienstleistungsgesetzes (RDG) handelt.
        </p>

        {type !== RequestTypes.RedeliveryEnforcement && type !== RequestTypes.Objection && (
          <div>
            <p>
              In das Feld <span className="quotable">Vergütung</span> geben Sie, falls zutreffend, die Ihnen zustehende Vergütung für die
              Vertretung im gerichtlichen Mahnverfahren - ggf. einschl. Auslagenpauschale und Mehrwertsteuer - ein. Bitte beachten Sie
              hierbei, dass ein Gläubiger die Kosten, die ihm ein Inkassodienstleister für seine Tätigkeit berechnet hat, von seinem
              Schuldner gem. § 13e RDG nur bis zur Höhe der Vergütung als Schaden ersetzt verlangen kann, die einem Rechtsanwalt für diese
              Tätigkeit nach den Vorschriften des Rechtsanwaltsvergütungsgesetzes zustehen würde.
            </p>
            <p>
              Falls der Antragsteller <strong>nicht</strong> zum Vorsteuerabzug berechtigt ist, markieren Sie bitte das zugehörige Kästchen.
            </p>
          </div>
        )}

        <p>
          In das Feld <span className="quotable">Kennziffer</span> geben Sie, falls vorhanden, die Ihnen vom Gericht zugewiesene
          Prozessvertreterkennziffer ein (8-stellig). Eine Kennziffer ist eine vom zuständigen Mahngericht auf Antrag vergebene Nummer,
          unter der die Daten des Prozessbevollmächtigten bei Gericht hinterlegt sind. Die Nutzung einer Kennziffer wird bei häufiger
          Antragstellung empfohlen. Das Online-Mahnverfahren kann aber auch ohne eine Kennziffer durchgeführt werden.<p></p>
          Die Kennziffer ist grundsätzlich bundesweit bei den Mahngerichten verwendbar.<div></div>
          Lediglich das Mahngericht des Landes Niedersachsen (Amtsgericht Uelzen) akzeptiert keine Kennziffern anderer Bundesländer.
        </p>

        <p>Sofern Ihnen keine Kennziffer zugewiesen wurde, geben Sie in den folgenden Feldern Ihre Daten ein:</p>

        <p>
          Im Feld <span className="quotable">Rechtsform</span> wählen Sie die zutreffende Rechtsform aus. Handelt es sich bei Ihrem
          Unternehmen um ein Einzelunternehmen bzw. sind Sie eingetragener Einzelkaufmann (e.K.), so lassen Sie bitte dieses Feld frei.
          Tragen Sie aber Ihre komplette Firmenbezeichnung mit ausgeschriebenem Vor- und Nachnamen des Inhabers im Feld{' '}
          <span className="quotable">vollst. Bezeichnung</span> ein.
        </p>

        <p>
          Im Feld <span className="quotable">vollst. Bezeichnung</span> geben Sie Ihre komplette Firma ein. Handelt es sich bei Ihrem
          Unternehmen um ein Einzelunternehmen bzw. sind Sie eingetragener Einzelkaufmann (e.K.), so tragen Sie Ihre komplette
          Firmenbezeichnung mit ausgeschriebenem Vor- und Nachnamen des Inhabers ein. In diesem Falle lassen Sie bitte das Feld{' '}
          <span className="quotable">Rechtsform</span> leer.
        </p>

        <p>
          Im Feld <span className="quotable">Straße, Hausnummer</span> geben Sie Ihre Adresse ein.
        </p>

        <p>
          Geben Sie die Postleitzahl und den Ort ein. Das Auslandskennzeichen ist nur anzugeben, wenn der Sitz nicht in Deutschland ist.
          Befindet sich der Wohnsitz in Deutschland, so soll kein Auslandskennzeichen angegeben werden.
        </p>

        <p>
          <i>Beispiel juristische Person:</i>
          <div>GmbH</div>
          <div>Musterinkasso GmbH</div>
          <div>Musterstraße 12</div>
          <div>88888 Musterstadt</div>
        </p>

        <p>
          {' '}
          <div>
            <i>Beispiel Einzelunternehmen:</i>
          </div>
          <div>&nbsp;</div>
          <div>Inkassobüro Michael Muster</div>
          <div>Musterstraße 12</div>
          <div>88888 Musterstadt</div>
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default InkassoKreditdienstleister;
