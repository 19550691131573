// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.read-instructions-form-check {
  padding-left: 0;
}

.barcode-twice-number-li::marker {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/generic/printAndSign/barcode/barcode.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AACA;EACE,iBAAA;AAEF","sourcesContent":[".read-instructions-form-check {\n  padding-left: 0;\n}\n.barcode-twice-number-li::marker {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
