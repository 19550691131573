import React, { SetStateAction, useState } from 'react';
import { LocationDetailsLabel } from '../label/locationDetailsLabel';
import { Col, Row } from 'reactstrap';
import ValidatedInput from 'app/shared/components/validated/validatedInput';
import GenericInputValidation from 'app/shared/validations/genericInputValidation';
import ZipcodeCityValidation from 'app/shared/validations/ZipcodeCityValidation';
import { RegisteredValidationMap } from 'app/shared/components/validated/validatedComponent';
import SpecialCharactersValidation from 'app/shared/validations/specialCharactersValidation';

interface LocationDetailsFieldProps {
  onChangePostCode?: React.ChangeEventHandler<HTMLInputElement>;
  onChangeCity?: React.ChangeEventHandler<HTMLInputElement>;
  onChangeCountry?: React.ChangeEventHandler<HTMLInputElement>;
  onBlurPostCode?: React.FocusEventHandler<HTMLInputElement>;
  onBlurCity?: React.FocusEventHandler<HTMLInputElement>;
  onBlurCountry?: React.FocusEventHandler<HTMLInputElement>;
  postCodeValue?: string;
  cityValue?: string;
  foreignCountryValue?: string;
  isOptional?: boolean;
  externalErrors?: string[];
  registeredValidations: RegisteredValidationMap;
  setRegisteredValidations: React.Dispatch<SetStateAction<RegisteredValidationMap>>;
  setLKZ: (LKZ: string) => void;
}

export const LocationDetailsField = (prop: LocationDetailsFieldProps) => {
  const [zipcodeCityErrors, setZipcodeCityErrors] = useState([...(prop.externalErrors || [])]);

  const executeValidateZipcodeCity = async () => {
    return await validateZipcodeCity(undefined);
  };

  const validateZipcodeCity = async e => {
    // If address is in foreign country or it isn't fully entered yet
    if ((prop.foreignCountryValue && prop.foreignCountryValue !== '') || prop.cityValue === '' || prop.postCodeValue === '') {
      setZipcodeCityErrors([...(prop.externalErrors || [])]);
      return [...(prop.externalErrors || [])];
    }

    const errors = [...(prop.externalErrors || [])];
    const [newErrors, LKZ] = await ZipcodeCityValidation.validate(prop.postCodeValue, prop.cityValue);
    errors.push(...newErrors);
    setZipcodeCityErrors(errors);
    prop.setLKZ(LKZ);
    return errors;
  };
  const internalBlurCity = e => {
    prop.onBlurCity?.apply(this, [e]);
    validateZipcodeCity.apply(this, [e]);
  };

  const internalBlurZipcode = e => {
    prop.onBlurPostCode?.apply(this, [e]);
    validateZipcodeCity.apply(this, [e]);
  };

  const internalBlurCountry = e => {
    prop.onBlurCountry?.apply(this, e);
    validateZipcodeCity.apply(this, e);
  };

  return (
    <Row>
      <Col className="label">
        <LocationDetailsLabel></LocationDetailsLabel>
      </Col>
      <Col className="col-12 col-xs-2" style={{ padding: 0, paddingLeft: '0.75rem' }}>
        <ValidatedInput
          onChange={prop.onChangePostCode}
          id="postCode"
          className="post-code"
          name="postCode"
          type="text"
          aria-labelledby="label-attornyOfRecord.postcode-city"
          value={prop.postCodeValue}
          onBlur={internalBlurZipcode}
          validations={[GenericInputValidation]}
          customValidations={[executeValidateZipcodeCity]}
          isOptional={prop.isOptional}
          externalErrors={zipcodeCityErrors}
          key={'pc' + zipcodeCityErrors.length}
          validationKey={prop.cityValue + '_' + prop.foreignCountryValue}
          registeredValidations={prop.registeredValidations}
          setRegisteredValidations={prop.setRegisteredValidations}
        />
      </Col>
      <Col className="col-12 col-xs-3 p-0">
        <ValidatedInput
          onChange={prop.onChangeCity}
          onBlur={internalBlurCity}
          id="city"
          className="city"
          name="city"
          type="text"
          aria-labelledby="label-attornyOfRecord.postcode-city"
          value={prop.cityValue}
          validations={[GenericInputValidation]}
          customValidations={[executeValidateZipcodeCity]}
          isOptional={prop.isOptional}
          externalErrors={zipcodeCityErrors}
          key={'city' + zipcodeCityErrors.length}
          validationKey={prop.postCodeValue + '_' + prop.foreignCountryValue}
          registeredValidations={prop.registeredValidations}
          setRegisteredValidations={prop.setRegisteredValidations}
        />
      </Col>
      <Col className="col-12 col-xs-1" style={{ padding: 0, paddingRight: '0.75rem' }}>
        <ValidatedInput
          onChange={prop.onChangeCountry}
          onBlur={internalBlurCountry}
          id="foreignCountry"
          className="foreign-country"
          name="ausl-kz"
          type="text"
          aria-labelledby="label-attornyOfRecord.foreign-country"
          value={prop.foreignCountryValue}
          validationKey={prop.postCodeValue + '_' + prop.cityValue}
          validations={[SpecialCharactersValidation]}
          registeredValidations={prop.registeredValidations}
          setRegisteredValidations={prop.setRegisteredValidations}
        />
      </Col>
    </Row>
  );
};

export default LocationDetailsField;
