import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Translate } from 'react-jhipster';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Col, Row } from 'reactstrap';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Alert color="danger">
        <Translate contentKey="error.http.404">The page does not exist.</Translate>
      </Alert>
      <Row>
        <Col className={'col-12 col-xs-3 offset-xs-2'}>
          <Button onClick={() => navigate(-1)} name={'back'}>
            <FontAwesomeIcon icon={faArrowLeft} /> <Translate contentKey={'generic.back'} />
          </Button>
        </Col>
        <Col className={'col-12 col-xs-3'}>
          <Button onClick={() => navigate('/')} name={'home'}>
            <FontAwesomeIcon icon={faHome} /> <Translate contentKey={'generic.returnToHome'} />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PageNotFound;
