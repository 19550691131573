import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';
import { RequestTypes } from 'app/config/constants';

const Antragsgegneranschrift = () => {
  const type = useAppSelector(state => state.requestStore.type);
  const addressChangeText =
    type === RequestTypes.Enforcement || type === RequestTypes.Objection
      ? 'letzten Antrag geändert, so geben Sie diese hier ein.'
      : 'Antrag auf Erlass eines Mahnbescheids geändert oder möchten Sie an dieselbe Adresse zustellen lassen, so geben Sie diese hier ein.';
  return (
    <html>
      <head>
        <title>Antragsgegneranschrift</title>
      </head>
      <body className="generic-help antragsgegneranschrift">
        <HelpNavigationMenu />

        <h2>Antragsgegneranschrift</h2>

        <p>Hat sich die Anschrift des Antragsgegners seit dem {addressChangeText}</p>

        {type !== RequestTypes.Objection && (
          <p>
            Ist eine Zustellung unter der Anschrift des Antragsgegners nicht möglich, so können Sie im weiteren Verlauf Angaben zur
            Zustellung an einen gesetzlichen Vertreter machen.
          </p>
        )}

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Antragsgegneranschrift;
