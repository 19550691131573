// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help h2 {
  font-size: 14pt;
  margin-top: 9pt;
  margin-left: 5px;
  margin-bottom: 7pt;
  font-weight: bold;
}

.help ul {
  margin-left: 0px;
  padding-left: 20px;
  font-size: 10pt;
}

.help {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 10pt;
  color: #ffffff;
  background-color: #669966 !important;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding: 5px;
}

.help a {
  color: #ffffff;
  font-weight: normal;
}

.help a:hover {
  color: #00ff00;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/dunning/overview/inhaltsverzeichnis.css"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AACA;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;AAEF;;AACA;EACE,kDAAA;EACA,eAAA;EACA,cAAA;EACA,oCAAA;EAGA,qBAAA;EACA,aAAA;EACA,YAAA;AAEF;;AAAA;EACE,cAAA;EACA,mBAAA;AAGF;;AADA;EACE,cAAA;EACA,0BAAA;AAIF","sourcesContent":[".help h2 {\n  font-size: 14pt;\n  margin-top: 9pt;\n  margin-left: 5px;\n  margin-bottom: 7pt;\n  font-weight: bold;\n}\n.help ul {\n  margin-left: 0px;\n  padding-left: 20px;\n  font-size: 10pt;\n}\n\n.help {\n  font-family: Verdana, Arial, Helvetica, sans-serif;\n  font-size: 10pt;\n  color: #ffffff;\n  background-color: #669966 !important;\n  -ms-hyphens: auto;\n  -moz-hyphens: auto;\n  -webkit-hyphens: auto;\n  hyphens: auto;\n  padding: 5px;\n}\n.help a {\n  color: #ffffff;\n  font-weight: normal;\n}\n.help a:hover {\n  color: #00ff00;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
