// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.programminfo .link {
  color: #7791a1;
}

.programminfo h4 {
  color: #1b4762;
  padding: 4px;
}

.programminfo li::marker {
  color: #1b4762;
  font-size: 1.45rem;
}

.programminfo .line {
  height: 2px;
  background-color: black;
  border: none;
}

.programminfo .arrow-up-icon {
  margin-left: 5px;
  color: #7791a1;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/programminfo/programminfo.css"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AACA;EACE,cAAA;EACA,YAAA;AAEF;;AACA;EACE,cAAA;EACA,kBAAA;AAEF;;AACA;EACE,WAAA;EACA,uBAAA;EACA,YAAA;AAEF;;AAAA;EACE,gBAAA;EACA,cAAA;AAGF","sourcesContent":[".programminfo .link {\n  color: #7791a1;\n}\n.programminfo h4 {\n  color: #1b4762;\n  padding: 4px;\n}\n\n.programminfo li::marker {\n  color: #1b4762;\n  font-size: 1.45rem;\n}\n\n.programminfo .line {\n  height: 2px;\n  background-color: black;\n  border: none;\n}\n.programminfo .arrow-up-icon {\n  margin-left: 5px;\n  color: #7791a1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
