import React from 'react';
import './katalognummern.css';

const Katalognummern = () => {
  return (
    <html>
      <head>
        <title>Katalog.-Anspruch / Kat.-Nr.</title>
      </head>
      <body className="hauptforderungen katalognummern">
        <h5 className="heading-5">
          <a target="katalognummern"></a>Katalog.-Anspruch / Kat.-Nr.
        </h5>
        <p className="Definition">Katalogisierbarer/Katalogisierter Anspruch</p>
        <p className="Definition">Ein Anspruch der unter eine der folgenden Bezeichnungen fällt.</p>
        <h5 className="heading-5">Hauptforderungs-Katalog</h5>
        (Die Hinweise in Klammern bitte unbedingt beachten. Die Zeilen- und Spaltennummern beziehen sich auf das Papierformular.)
        <table>
          <tbody>
            <tr>
              <th>Bezeichnung</th>
              <th>Kat.-Nr.</th>
            </tr>
            <tr>
              <td>Anzeigen in Zeitungen u. a.</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Ärztliche oder zahnärztliche Leistung</td>
              <td>2</td>
            </tr>
            <tr>
              <td>
                Beiträge zur privaten Pflegeversicherung
                <div></div>(Zuständigkeit des Sozialgerichts für das streitige Verfahren)
              </td>
              <td>95</td>
            </tr>
            <tr>
              <td>Bürgschaft</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Darlehensrückzahlung</td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                Dienstleistungsvertrag
                <div></div>(Keine Ansprüche aus Arbeitsvertrag -Zuständigkeit des Arbeitsgerichts)
              </td>
              <td>5</td>
            </tr>
            <tr>
              <td>Frachtkosten</td>
              <td>6</td>
            </tr>
            <tr>
              <td>
                Geschäftsbesorgung durch Selbständige
                <div></div>(z. B. Rechtsanwälte, Steuerberater)
              </td>
              <td>7</td>
            </tr>
            <tr>
              <td>Handwerkerleistung</td>
              <td>8</td>
            </tr>
            <tr>
              <td>
                Hausgeld - siehe Wohngeld <a href="#sternchen">*</a>
              </td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>Heimunterbringung</td>
              <td>9</td>
            </tr>
            <tr>
              <td>
                Hotelkosten
                <div></div>(z.B. Übernachtung, Verzehr, Getränke)
              </td>
              <td>10</td>
            </tr>
            <tr>
              <td>Kaufvertrag</td>
              <td>11</td>
            </tr>
            <tr>
              <td>
                Kindertagesstättenbeittag
                <div></div>(Zeitraum vom - bis angeben)
              </td>
              <td>70</td>
            </tr>
            <tr>
              <td>Kontokorrentabrechnung</td>
              <td>12</td>
            </tr>
            <tr>
              <td>Krankenhauskosten - Pflege/Behandlung -</td>
              <td>13</td>
            </tr>
            <tr>
              <td>Krankentransportkosten</td>
              <td>77</td>
            </tr>
            <tr>
              <td>Lagerkosten</td>
              <td>14</td>
            </tr>
            <tr>
              <td>Leasing/Mietkauf</td>
              <td>15</td>
            </tr>
            <tr>
              <td>Lehrgangs-/Unterrichtskosten</td>
              <td>16</td>
            </tr>
            <tr>
              <td>Miete für Geschäftsraum einschl. Nebenkosten</td>
              <td>17</td>
            </tr>
            <tr>
              <td>Miete für Kraftfahrzeug</td>
              <td>18</td>
            </tr>
            <tr>
              <td>
                Miete für Wohnraum einschl. Nebenkosten
                <div></div>(PLZ und Ort der Wohnung sind im Vordruck Zeile einzutragen. Wollen Sie die Nebenkosten getrenntgeltend machen,
                siehe Katalog-Nr. 20.)
              </td>
              <td>19</td>
            </tr>
            <tr>
              <td>
                Mietnebenkosten - auch Renovierungskosten -<div></div>(nur für Wohnraum&apos;, falls keine Miete geltend gemacht wird,sind
                PLZ und Ort der Wohnung im Vordruck Zeile 35einzutragen.)
              </td>
              <td>20</td>
            </tr>
            <tr>
              <td>Miete (sonstige)</td>
              <td>21</td>
            </tr>
            <tr>
              <td>Mitgliedsbeitrag</td>
              <td>22</td>
            </tr>
            <tr>
              <td>Pacht</td>
              <td>23</td>
            </tr>
            <tr>
              <td>Pflegeversicherung - siehe Beiträge zur privaten Pflegevers.</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>Rechtsanwalts-/Rechtsbeistandshonorar</td>
              <td>24</td>
            </tr>
            <tr>
              <td>
                Reisevertrag <a href="#sternchen">*</a>{' '}
              </td>
              <td>75</td>
            </tr>
            <tr>
              <td>Rententückstände</td>
              <td>25</td>
            </tr>
            <tr>
              <td>Reparaturleistung</td>
              <td>26</td>
            </tr>
            <tr>
              <td>Rückgriff aus Versicherungsvertrag wegen Unfall/Vorfall</td>
              <td>27</td>
            </tr>
            <tr>
              <td>
                Schadenersatz aus .........-Vertrag
                <div></div>(Die Vertragsart ist im Vordruck Zeile 35 zweite Hälfte einzutragen.)
              </td>
              <td>28</td>
            </tr>
            <tr>
              <td>Schadenersatz aus Unfall/Vorfall</td>
              <td>29</td>
            </tr>
            <tr>
              <td>
                Scheck/Wechsel
                <div></div>(Fügen Sie bitte keine Scheck-/Wechselabschrift bei.)
              </td>
              <td>30</td>
            </tr>
            <tr>
              <td>Scheck-/Wechselprovision (l/3 %)</td>
              <td>31</td>
            </tr>
            <tr>
              <td>Scheck-/Wechselunkosten - Spesen/Protest -</td>
              <td>32</td>
            </tr>
            <tr>
              <td>Schuldanerkenntnis</td>
              <td>33</td>
            </tr>
            <tr>
              <td>Speditionskosten</td>
              <td>34</td>
            </tr>
            <tr>
              <td>Telekommunikationsleistungen</td>
              <td>76</td>
            </tr>
            <tr>
              <td>Tierärztliche Leistung</td>
              <td>78</td>
            </tr>
            <tr>
              <td>Tilgungs-/Zinsraten</td>
              <td>35</td>
            </tr>
            <tr>
              <td>
                Überziebung des Bankkontos
                <div></div>(Konto-Nr. in Zeile 32-34 in der 3. Spalte angeben.)
              </td>
              <td>36</td>
            </tr>
            <tr>
              <td>Ungerechtfertigte Bereicherung</td>
              <td>37</td>
            </tr>
            <tr>
              <td>Unterhaltsrückstände</td>
              <td>38</td>
            </tr>
            <tr>
              <td>Vergleich, außergerichtlicher</td>
              <td>39</td>
            </tr>
            <tr>
              <td>
                Vermittlungs-/Maklerprovision
                <div></div>(nicht aus Ehemaklervertrag)
              </td>
              <td>40</td>
            </tr>
            <tr>
              <td>
                Verpflegungskosten <a href="#sternchen">*</a>
              </td>
              <td>79</td>
            </tr>
            <tr>
              <td>Versicherungsprämiel-beitrag (ohne Beiträge zur privaten Pflegeversicherung, vgl. Nr. 95)</td>
              <td>41</td>
            </tr>
            <tr>
              <td>
                Versorgungsleistung - Strom, Wasser, Gas, Wärme -<div></div>(Abn./Zähler-Nr. in Zeile 32-34 in der 3. Spalte eintragen.)
              </td>
              <td>42</td>
            </tr>
            <tr>
              <td>
                Wahlleistungen bei stationärer Behandlung
                <div></div>(Art derwahll. in Zeile 32-34 in der 2. Spalte eintragen.)
              </td>
              <td>61</td>
            </tr>
            <tr>
              <td>Warenlieferung-en</td>
              <td>43</td>
            </tr>
            <tr>
              <td>Wechsel - siehe Scheck</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>Werkvertrag/Werklieferungsvertrag</td>
              <td>44</td>
            </tr>
            <tr>
              <td>
                Wohngeld/Hausgeld für Wohnungseigentümergemeinschaft
                <div></div>(PLZ und Ort des Wohnungseigentums sind im Vordruck in Zeile 35 einzutragen.)
              </td>
              <td>90</td>
            </tr>
            <tr>
              <td>Zeitungs-/Zeitschriftenbezug</td>
              <td>45</td>
            </tr>
            <tr>
              <td>
                Zinsrückstäride/Verzugszinsen
                <div></div>(Gilt nur für Zinsen, bei denen die zugrundeliegende Forderung nicht gleichzeitig geltend gemacht wird. Zinsen in
                diesen Fällen nicht in Zeile 40-43 bezeichnen.)
              </td>
              <td>46</td>
            </tr>
          </tbody>
        </table>
        <a target="sternchen">* Hinweise/Ergänzung der Koordinierungsstelle für das automat. Mahnverfahren</a>
      </body>
    </html>
  );
};
export default Katalognummern;
