import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Zahlugen = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Zahlungen</title>
      </head>
      <body className="generic-help ubersicht">
        <HelpNavigationMenu />
        <h2>Zahlungen des Antragsgegners</h2>

        <p>Geben Sie an, ob der Antragsgegner Zahlungen seit dem Mahnbescheid geleistet hat.</p>

        <p>
          Hat der Antragsgegner Zahlungen geleistet, so geben Sie alle Zahlungen an, die er seit dem Mahnbescheid geleistet hat. Sie können
          bis zu 12 Zahlungen angeben.
        </p>

        <p>
          Geben Sie <span className="quotable">Datum</span> und <span className="quotable">Betrag</span> an und betätigen Sie die{' '}
          <input className={`${type} button-thumbnail`} value="Zahlung hinzufügen" type="submit" />
          -Schaltfläche.
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, gehen Sie mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>{' '}
          zu den weiteren Angaben des Antrags.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Zahlugen;
