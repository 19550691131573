import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';
import { UrlPrefix } from 'app/config/constants';

const Inhaltsverzeichnis = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Inhaltsverzeichnis</title>
      </head>
      <body className="generic-help inhaltsverzeichnis">
        <HelpNavigationMenu hideBack />
        <h2>Inhaltsverzeichnis</h2>

        <ul className="list">
          <li>
            <a href={`hilfe${urlPrefix}/geschaeftsnummer`}>Geschäfts­nummer des Mahngerichts</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/antragstellerart`}>Neuer Prozessbevollmächtigter?</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/rechtsbeistand`}>Erfassen eines Prozessbevollmächtigten</a>
          </li>
          <ul className="list">
            <li>
              <a href={`hilfe${urlPrefix}/rechtsbeistand/rechtsanwalt_rechtsbeistand`}>
                Erfassen eines Prozessbevollmächtigten (Rechtsanwalt, Rechtsbeistand)
              </a>
            </li>
            <li>
              <a href={`hilfe${urlPrefix}/rechtsbeistand/rechtsanwaltsgesellschaft`}>
                Erfassen eines Prozessbevollmächtigten (Rechtsanwaltsgesellschaft)
              </a>
            </li>
            <li>
              <a href={`hilfe${urlPrefix}/rechtsbeistand/herr_frau`}>Erfassen eines Prozessbevollmächtigten (Herr / Frau)</a>
            </li>
            <li>
              <a href={`hilfe${urlPrefix}/rechtsbeistand/inkasso_kreditdienstleister`}>
                Erfassen eines Prozessbevollmächtigten (Inkasso-/Kreditdienstleister)
              </a>
            </li>
            <li>
              <a href={`hilfe${urlPrefix}/rechtsbeistand/verbraucherverband`}>
                Erfassen eines Prozessbevollmächtigten (Verbraucherverband)
              </a>
            </li>
            <li>
              <a href={`hilfe${urlPrefix}/gesetzlichevertreter`}>Gesetzlicher Vertreter des Proz.bevollm.</a>
            </li>
            {urlPrefix !== UrlPrefix.Objection && (
              <li>
                <a href={`hilfe${urlPrefix}/bankverbindung`}>Bankverbindung des Proz.bevollm.</a>
              </li>
            )}
          </ul>
          {urlPrefix === UrlPrefix.Enforcement && (
            <li>
              <a href={`hilfe${urlPrefix}/zahlungen`}>Zahlungen des Antragsgegners</a>
            </li>
          )}
          <li>
            <a href={`hilfe${urlPrefix}/antragsgegneranschrift`}>Antragsgegneranschrift</a>
          </li>
          {urlPrefix !== UrlPrefix.Redelivery_enforcement && urlPrefix !== UrlPrefix.Objection && (
            <li>
              <a href={`hilfe${urlPrefix}/antragsgegnerbezeichnung`}>Antragsgegnerbezeichnung</a>
            </li>
          )}

          {urlPrefix === UrlPrefix.Redelivery_dunning && (
            <li>
              <a href={`hilfe${urlPrefix}/prozessgericht`}>Prozessgericht</a>
            </li>
          )}
          {urlPrefix !== UrlPrefix.Redelivery_enforcement && urlPrefix !== UrlPrefix.Objection && (
            <li>
              <a href={`hilfe${urlPrefix}/auslagen`}>Weitere Auslagen für dieses Verfahren</a>
            </li>
          )}
          <li>
            <a href={`hilfe${urlPrefix}/zustellung_vertreter`}>
              {urlPrefix === UrlPrefix.Objection
                ? 'Gesetzlicher Vertreter des Antragsgegners'
                : 'Zustellung an gesetzlichen Vertreter des Antragsgegners'}
            </a>
          </li>
          {urlPrefix === UrlPrefix.Objection && (
            <li>
              <a href={`hilfe${urlPrefix}/umfang`}>Angaben zum Widerspruch</a>
            </li>
          )}
          {urlPrefix !== UrlPrefix.Redelivery_enforcement && urlPrefix !== UrlPrefix.Objection && (
            <li>
              <a href={`hilfe${urlPrefix}/neuer_vertreter`}>Neuer gesetzlicher Vertreter des Antragsgegners</a>
            </li>
          )}
          <li>
            <a href={`hilfe${urlPrefix}/allgemeine_angaben`}>
              {urlPrefix === UrlPrefix.Objection ? 'Allgemeine Angaben' : 'Allgemeine Angaben zum Antrag'}
            </a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/uebersicht`}>Antragsübersicht</a>
          </li>
          <li>
            <a href={`hilfe${urlPrefix}/barcode`}>Barcodedruck (PDF)</a>
          </li>
          {urlPrefix !== UrlPrefix.Objection && (
            <li>
              <a href={`hilfe${urlPrefix}/eda`}>EDA-Download</a>
            </li>
          )}
          <li>
            <a href={`hilfe${urlPrefix}/eid`}>eID-Personalausweis</a>
          </li>
        </ul>

        <HelpNavigationMenu hideBack />
      </body>
    </html>
  );
};

export default Inhaltsverzeichnis;
