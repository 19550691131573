import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavDropdown } from '../menu-components';
import { Translate, translate } from 'react-jhipster';
import { useAppSelector } from 'app/config/store';
import { routeObject } from 'app/shared/oldPages/help/dunning';
import { typeSelector } from 'app/shared/reducers/requeststore';

export const DunningHelpMenu = () => {
  const menuEntries = new Map<string, string>();
  const mainLabelForTranslateKey = 'menu.help';
  const requestTypeFoTranslateKey = 'dunning';
  const labelKey = `${mainLabelForTranslateKey}.${requestTypeFoTranslateKey}`;
  menuEntries.set(`${labelKey}.online-mahnantrag`, '/hilfe/mahnbescheid/inhaltsverzeichnis');
  menuEntries.set(`${labelKey}.court-costs-table`, '/mahnbescheid/gerichtsgebuehr');
  menuEntries.set(`${labelKey}.prime-rate-tabel`, '/hilfe/mahnbescheid/basiszins');
  menuEntries.set(`${labelKey}.contact-person`, '/hilfe/mahnbescheid/ansprechpartner');
  menuEntries.set(`${labelKey}.info`, '/');
  insertDynamicMenuEntry();
  function handleOnClick(value: string) {
    if (value === '/') {
      alert('not implemented');
    } else {
      window.open(value, '_blank');
    }
  }

  function insertDynamicMenuEntry() {
    const type = useAppSelector(typeSelector);
    const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
    const routes = routeObject(urlPrefix);
    const currentUrl = window.location.href;
    const pathParts = currentUrl.split('/');
    const lastPath = pathParts[pathParts.length - 1];
    const currentPath = urlPrefix + '/' + lastPath;
    for (const path in routes) {
      if (Object.prototype.hasOwnProperty.call(routes, path) && path === currentPath) {
        const labelName = `pageheader.${type}.${lastPath}`;
        const link = `hilfe${path}`;
        const menuEntriesArray = Array.from(menuEntries.entries());
        menuEntriesArray.splice(1, 0, [labelName, link]);
        const updatedMenuEntries = new Map(menuEntriesArray);
        menuEntries.clear();
        for (const [key, value] of updatedMenuEntries) {
          menuEntries.set(key, value);
        }
        break;
      }
    }
  }

  return (
    <NavDropdown name={translate('menu.help.label')} id="help-dropdown">
      {[...menuEntries.entries()].map(([key, value]) => (
        <DropdownItem key={key} onClick={() => handleOnClick(value)}>
          <Translate contentKey={key} />
        </DropdownItem>
      ))}
    </NavDropdown>
  );
};
