import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Button } from 'reactstrap';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import './antragstellerauswahl.css';

const Antragstellerauswahl = () => {
  return (
    <html>
      <body className="antragstellerauswahl">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="bundeslandauswahl"></a>Antragstellerauswahl
        </h2>

        <p className="Body-Text">
          Geben Sie an, ob Sie der Prozessbevollmächtigte des Antragstellers sind oder ob Sie selbst der Antragsteller sind.
        </p>

        <p className="Note">
          <b>Beispiel:</b> Die Rechtsabteilung eines Unternehmens beantragt den Mahnbescheid im Auftrag des Unternehmens und die
          Korrespondenz soll über einen bestimmten Beschäftigten der Rechtsabteilung geführt werden. In diesem Fall tragen Sie den
          Beschäftigten als Prozessbevollmächtigten und das Unternehmen als Antragsteller ein. Bitte wählen Sie beim Prozessbevollmächtigten
          die Bezeichnung &quot;Herr, Frau&quot; aus. Die Auswahl von &quot;Herr, Frau&quot; bewirkt, dass der bestimmte Beschäftigte in den
          Bescheiden als Prozessbevollmächtigter ausgewiesen und alle Korrespondenz über ihn geführt wird. Sie bewirkt auch, dass keine
          Gebühren nach dem RVG berechnet werden.
          <p></p>
          <p>
            Sind Sie kein Rechtsanwalt, beachten Sie bitte die Einschränkungen des{' '}
            <a href="http://www.gesetze-im-internet.de/zpo/__79.html" target="_blank" rel="noreferrer">
              § 79 Abs. 2 ZPO
            </a>
            .
          </p>
        </p>

        <p className="Body-Text">
          Klicken Sie auf die{' '}
          <Button disabled className="next-button" name={'weiter'}>
            <Translate contentKey="generic.next" /> <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
          </Button>
          -Schaltfläche unter Ihrer Auswahl.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Antragstellerauswahl;
