import dayjs from 'dayjs';

import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import moment from 'moment';

export const convertDateTimeFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = (date?: string): dayjs.Dayjs | null => (date ? dayjs(date) : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const convertDateToIsoString = (date: string) => {
  if (!date || date === '') return undefined;
  return new Date(date).toISOString();
};

export const convertIsoStringToDate = (date: string) => {
  if (!date || date === '') return undefined;
  return moment(date, moment.ISO_8601).format('DD.MM.YYYY');
};

export const convertToStandartDateFormat = (date: string) => {
  if (!date || date === '') return undefined;
  return moment(date).format('YYYY-MM-DD');
};
