import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const NeuerVertreter = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Neuer gesetzlicher Vertreter des Antragsgegners</title>
      </head>
      <body className="generic-help neuer-vertreter">
        <HelpNavigationMenu />

        <h2>Neuer gesetzlicher Vertreter des Antragsgegners</h2>

        <p className="Note">
          <strong>Hinweis:</strong> Eingaben an dieser Stelle führen dazu, dass <strong>alle</strong> bisher im Verfahren genannten
          gesetzlichen Vertreter durch den hier angegebenen ersetzt werden (Ausnahme: Komplementärgesellschaft bei mehrstufiger Vertretung,
          Verwaltergesellschaft bei jur. Pers. als WEG-Verwalterin).
        </p>

        <p>
          Hier kann nur eine natürliche Person als neuer GV hinzugefügt werden.
          <div></div>Bei GmbH &amp; Co. KG, oder ähnlichen zweistufigen Vertretungen also nicht die Komplementärgesellschaft, sondern deren
          gesetzlicher Vertreter (Geschäftsführer, Vorstand, etc.). Ebenso ist bei einer WEG vertreten durch eine juristische Person als
          Verwalterin ein gesetzlicher Vertreter der Verwalterin einzutragen.
        </p>

        <p>
          Im Feld <span className="quotable">Stellung</span> wählen Sie aus der vorgegebenen Liste die Stellung des gesetzlichen Vertreter
          aus. Nur wenn in der Liste die Funktion des gesetzlichen Vertreters nicht enthalten ist, geben Sie diese im Feld{' '}
          <span className="quotable">sonstige Stellung</span> an.
        </p>

        <p>
          Im Feld <span className="quotable">Vor- und Nachname</span> geben Sie den kompletten Vor- und Nachnamen (ohne Abkürzungen) des
          gesetzlichen Vertreters an.
        </p>

        <p>
          Im Feld <span className="quotable">Straße/Hausnummer</span> und <span className="quotable">PLZ/Ort/Ausl.Kz.</span> können Sie die
          Adresse des gesetzlichen Vertreters eingeben. Das Auslandskennzeichen ist nur anzugeben, wenn der Wohnsitz nicht in Deutschland
          liegt. Befindet sich der Wohnsitz in Deutschland, so soll kein Auslandskennzeichen angegeben werden.
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default NeuerVertreter;
