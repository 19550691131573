import './glossar.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Glossar = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Glossar</title>
      </head>
      <body className="glossar">
        <HelpNavigationMenu hideNext />

        <p className="TOCTitle">
          <a target="glossar"></a>
          <b>Glossar</b>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/glossauslkz`}>Ausl.Kz</a>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/hauptforderungen/katalognummer/katalognummern`}>Katalog.-Anspruch / Kat.-Nr.</a>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/glosskennziffer`}>Kennziffer</a>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/glossraineieigenersache`}>RA in eig. Sache.</a>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/hauptforderungen/regulaeres_mahnverfahren`}>Reguläres Mahnverfahren</a>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/glossrvg`}>RVG</a>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/hauptforderungen/scheck_mahnverfahren`}>Scheck-Mahnverfahren</a>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/hauptforderungen/urkunden_mahnverfahren`}>Urkunden-Mahnverfahren</a>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/hauptforderungen/sonstiges/verbraucherkredit`}>VerbrKrG / Verbraucherkreditgesetz</a>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/glossvvrvg`}>VV RVG</a>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/hauptforderungen/wechsel_mahnverfahren`}>Wechsel-Mahnverfahren</a>
        </p>

        <p className="GlossaryTerm">
          <a href={`hilfe${urlPrefix}/glossweg`}>WEG</a>
        </p>

        <p className="GlossaryTerm">
          <a href="https://dejure.org/gesetze/ZPO" target="_blank" rel="noreferrer">
            ZPO
          </a>
        </p>

        <HelpNavigationMenu hideNext />
      </body>
    </html>
  );
};

export default Glossar;
