export interface IDunningDropDown {
  dropdownIndicator?: {
    fill?: string;
  };
  indicatorSeparator?: {
    backgroundColor: string;
  };
  control?: {
    border?: string;
    activeBorder?: string;
    borderHover?: string;
    boxShadow?: string;
  };
}

const dunningDropdownStyles: IDunningDropDown = {
  control: {
    border: '2px solid #669966',
    activeBorder: '2px solid #669966',
    borderHover: '2px solid #99cc98',
  },
  dropdownIndicator: {
    fill: '#669966',
  },
  indicatorSeparator: {
    backgroundColor: '#669966',
  },
};

export default dunningDropdownStyles;
