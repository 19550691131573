import './validated.scss';
import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import {
  generateBlur,
  getDomProps,
  getErrorsForTooltip,
  registerValidationComponent,
  ValidatedInputBaseProps,
} from 'app/shared/components/validated/validatedComponent';
import { NumericFormatProps } from 'react-number-format/types/types';
export type ValidatedInputProps = Input['props'] & ValidatedInputBaseProps<HTMLInputElement>;
export type ValidatedCurrencyProps = NumericFormatProps & ValidatedInputBaseProps<HTMLInputElement> & { maskOptions: object };

export const ValidatedInput = (props: ValidatedInputProps) => {
  const [errors, setErrors] = useState([...(props.externalErrors || [])]);

  const css = errors.length !== 0 ? 'validation-failed' : '';
  const DOMprops = getDomProps(props);

  registerValidationComponent(props, errors, setErrors);

  return (
    <div>
      <Input
        {...DOMprops}
        onBlur={e => {
          generateBlur<HTMLInputElement>(props, errors, setErrors)(e);
        }}
        id={props.id || props.name}
        className={css}
        key={props.id + errors.length}
        readOnly={props.readOnly}
        type={props.type}
      />
      {errors.length !== 0 && (
        <Tooltip anchorSelect={`#${props.id || props.name}`} place="top">
          {getErrorsForTooltip(errors)}
        </Tooltip>
      )}
    </div>
  );
};

export default ValidatedInput;
//
