import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';

const RechtsbeistandVertretung = () => {
  return (
    <html>
      <head>
        <title>Erfassen eines gesetzlichen Vertreters</title>
      </head>
      <body className="rechtsbeistand rechtsbeistand_vertretung">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="rechtsbeistand"></a>Erfassen des gesetzlichen Vertreters eines Prozessbevollmächtigten
        </h2>

        <p className="Body-Text">
          Wählen Sie die zu Ihrer Rechtsform passende Funktion des gesetzlichen Vertreters.
          <div></div>
          Die namentliche Bezeichnung des gesetzlichen Vertreters ist nicht zwingend erforderlich. Wollen Sie hierzu Angaben machen, so
          geben Sie bitte einen ausgeschrieben Vornamen und den Nachnamen ohne Abkürzungen ein.
        </p>

        <p className="Body-Text">
          Haben Sie alle Eingaben zum gesetzlichen Vertreter gemacht, bestätigen Sie die Eingaben mit Klick auf die{' '}
          <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default RechtsbeistandVertretung;
