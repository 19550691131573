import React from 'react';
import { Button } from 'reactstrap';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';
import { Translate } from 'react-jhipster';

const Sonstiges = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Sonstiger Anspruch</title>
      </head>
      <body className="hauptforderungen sonstiges">
        <HelpNavigationMenu />

        <h2>
          <a target="sonstiges"></a>Sonstiger Anspruch
        </h2>

        <p className="Body-Text">
          Den sonstigen Anspruch wählen Sie, wenn Ihr Anspruch nicht als{' '}
          <a href={`hilfe${urlPrefix}/hauptforderungen/katalognummer`}>katalogisierbarer Anspruch</a> angegeben werden kann.
        </p>

        <p className="Body-Text">
          Hier geben Sie im Feld &quot;Bezeichnung&quot; den kompletten Anspruch so genau wie möglich an. Das heißt, der Anspruch sollte mit
          Vertrags-/Lieferdatum/Zeitraum von/bis etc. enthalten sein.
        </p>

        <p className="Note">
          Hinweis: Für die Bezeichnung des Anspruchs stehen Ihnen aus technischen Gründen nur eine begrenzte Anzahl Zeichen zur Verfügung.
        </p>

        <p className="Body-Text">
          Im Feld &quot;Anspruch von/bis&quot; geben Sie, falls Sie einen Anspruch für einen bestimmten Zeitraum geltend machen, den Beginn
          und das Ende des Zeitraumes ein.
        </p>

        <p className="Note">
          Hinweis: Das &quot;Anspruch vom&quot;- Datum muss angegeben werden. Das &quot;Anspruch bis&quot;- Datum kann angegeben werden
        </p>

        <p className="Body-Text">
          Im Feld &quot;Betrag&quot; tragen Sie den Hauptforderungsbetrag ein. Handelt es sich bei dem geltend zu machenden Anspruch
          lediglich um eine Teilforderung, eine Restforderung oder eine Rate, kann dies dadurch ersichtlich gemacht werden, dass im
          bis-Datum der Begriff &quot;Teil&quot;, &quot;Rest&quot; oder &quot;Rate&quot; eingetragen wird.
        </p>

        <p className="Body-Text">
          Sie haben die Möglichkeit ein Kontrollkästchen zu aktivieren das aussagt, ob der Antragsteller Kreditgeber ist und der Anspruch
          auf dem <a href={`hilfe${urlPrefix}/hauptforderungen/sonstiges/verbraucherkredit`}>VerbraucherKreditGesetz</a> beruht. Ist dies
          der Fall, so werden Sie später automatisch aufgefordert die erforderlichen Angaben zu machen.
        </p>

        <p className="Body-Text">
          Haben Sie alle Angaben gemacht, bestätigen Sie Ihre Eingabe mit Klick auf die{' '}
          <FontAwesomeIcon className="image-icon" icon={faSquareCaretRight} /> -Schaltfläche.
        </p>

        <p className="Body-Text">Es öffnet sich eine Maske in der Sie weitere Daten zum Kataloganspruch machen können.</p>

        <ul className="List">
          <li>Eine Abtretung zum Anspruch erfassen</li>
          <li>Zinsangaben zum Anspruch erfassen</li>
          <li>Einen weiteren Anspruch oder ausgerechnete Zinsen erfassen/ändern</li>
          <li>Keine weiteren Angaben zu Hauptforderung oder Zinsen</li>
        </ul>

        <p className="Body-Text">
          Wählen Sie aus, ob Sie weitere Angaben (Abtretung, Zinsen) zu diesem Anspruch machen wollen oder einen weiteren Anspruch erfassen
          wollen oder keine weiteren Angaben zu Hauptforderung und/oder Zinsen machen möchten. Betätigen Sie dann die{' '}
          <Button disabled className="next-button" name={'weiter'}>
            <Translate contentKey="generic.next" /> <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
          </Button>
          -Schaltfläche.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Sonstiges;
