import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';

export const LegalNoticeMenu = () => {
  function handleOnClick() {
    window.open('/impressum', '_blank');
  }

  return (
    <NavItem name={translate('menu.legal-notice')} id="legal-notice-menu-item">
      <NavLink href="#" onClick={handleOnClick} id="legal-notice-nav-link">
        <Translate contentKey="menu.legal-notice" />
      </NavLink>
    </NavItem>
  );
};
