import './allgemeineAngaben.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const AllgemeineAngaben = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Allgemeine Angaben zum Antrag</title>
      </head>
      <body className="allgemeine-angaben">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="allgemeine_angaben"></a>Allgemeine Angaben zum Antrag
        </h2>

        <p className="Body-Text">
          <strong>Geschäftszeichen:</strong> Die Angabe Ihres Geschäftszeichens soll Ihnen die Zuordnung von Mitteilungen des Gerichts oder
          des Antragsgegners erleichtern.
        </p>

        <p className="Body-Text">
          <strong>Erklärung zur Gegenleistung:</strong> Das Mahnverfahren ist nur zulässig, wenn die geforderte Zahlung keine Leistung des
          Antrag<strong>stellers</strong> mehr voraussetzt, wenn also<p></p>
          a) der Antragsteller seine Leistung bereits erbracht hat (Bsp.: Ware wurde geliefert, Dienstleistung wurde erbracht, usw.)<p></p>
          <strong>oder</strong>
          <p></p>
          b) der Antragsgegner leisten muss, ohne dass der Antragsteller eine Leistung erbringen musste (Bsp.: Schadenersatz aus Unfall,
          usw.).<p></p>
          Im Fall a) geben Sie also bitte an, dass der Anspruch von einer Gegenleistung abhängig ist, diese aber bereits erbracht wurde, im
          Fall b) geben Sie bitte an, dass der Anspruch nicht von einer Gegenleistung abhängig ist. Klicken Sie dazu auf das entsprechende
          Kästchen
        </p>

        <p className="Body-Text">
          <strong>Antrag auf Durchführung des streitigen Verfahrens:</strong> Sie können beantragen, im Falle eines Widerspruchs des
          Antragsgegners ein streitiges Verfahren durchzuführen. Dazu klicken Sie auf das entsprechende Kästchen. Bei Widerspruch des
          Antragsgegners wird der Rechtstreit dann an das zuständige Prozessgericht abgegeben, grundsätzlich allerdings nur, wenn die Gebühr
          für das Mahnverfahren und Prozesskosten für das streitige Verfahren vollständig bezahlt sind (die erforderlichen Prozesskosten
          werden Ihnen in der Widerspruchsnachricht mitgeteilt).
        </p>

        <p className="Body-Text">
          Siehe auch: <a href={`hilfe${urlPrefix}/prozessgerichte`}>Prozessgericht</a>
        </p>

        <a className="kostenstatus"></a>
        <p className="Body-Text">
          <strong>Kosten-/Gebührenfreiheit:</strong> Gerichtskosten- oder Gebührenfreiheit im Mahnverfahren besteht nur in den nach Bundes-
          oder Landesrecht geregelten Ausnahmefällen für besondere Rechtspersönlichkeiten (z.B. gem. § 2 GKG für Bund und Länder,
          öffentliche Anstalten, Kassen u.ä.). Soweit nicht bereits eine Kosten- oder Gebührenbefreiung in Verbindung mit einer
          Kennziffererteilung geregelt wurde (überprüfen Sie hierzu bitte Ihre Kennziffer-Unterlagen), muss das Vorliegen der
          Voraussetzungen dem Gericht auf Anforderung nachgewiesen werden.
        </p>

        <a className="prozesskostenhilfe"></a>
        <p className="Body-Text">
          <strong>Prozesskostenhilfe:</strong> Prozesskostenhilfe (PKH) kann gewährt werden, wenn der Antragsteller aufgrund seiner
          finanziellen Verhältnisse die relativ geringen Gerichtskosten nicht oder nur in mehr als 4 Raten aufbringen kann und die
          Rechtsverfolgung im Mahnverfahren nicht offensichtlich aussichtslos erscheint (§§ 114 ff. ZPO). Im Regelfall wird PKH nur
          hinsichtlich der Gerichtskosten ausgesprochen. Die Beiordnung eines Rechtsanwalts wird mangels Notwendigkeit grundsätzlich
          abgelehnt.
          <p></p>Zur Erklärung über die wirtschaftlichen Verhältnisse sind bei den Amtsgerichten Formulare erhältlich (
          <a href="https://justiz.de/service/formular/f_kosten_verguetungen/index.php" target="_blank" rel="noreferrer">
            https://justiz.de/service/formular/f_kosten_verguetungen/index.php
          </a>
          ), denen Nachweise zu den gemachten Angaben beizufügen sind. Diese Unterlagen sind dem Mahngericht entweder zusammen mit dem
          Antrag oder spätestens nach Aufforderung des Gerichts vorzulegen.
        </p>

        <p className="Body-Text">Zur PKH-Beantragung klicken Sie bitte im Drop-Down-Feld auf den Antragstext.</p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default AllgemeineAngaben;
