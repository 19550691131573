import FieldEmptyValidation from 'app/shared/validations/fieldEmptyValidation';
import { Validation } from 'app/shared/validations/validation';
import SpecialCharactersValidation from 'app/shared/validations/specialCharactersValidation';
import { ValidationProps } from 'app/shared/components/validated/validatedComponent';

export class GenericInputValidation extends Validation {
  static async validate(props: ValidationProps): Promise<string[]> {
    const errors = [];

    errors.push(...(await FieldEmptyValidation.validate(props)));
    errors.push(...(await SpecialCharactersValidation.validate(props)));

    return errors;
  }
}

export default GenericInputValidation;
