import { useAppSelector } from 'app/config/store';
import React from 'react';

const Verbraucherkredit = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>VerbraucherKreditGesetz</title>
      </head>
      <body className="hauptforderungen verbaucherkredit">
        <h5 className="heading-5">
          <a target="verbaucherkredit"></a>VerbraucherKreditGesetz (VerbrKrG)
        </h5>
        <p className="Definition">
          Das <a href={`hilfe${urlPrefix}/hauptforderungen/sonstiges/verbraucherkredit`}>VerbraucherKreditGesetz</a> gilt für Verträge
          zwischen gewerblichen und beruflichen Kreditgebern (z.B. Banken, Händlern, aber auch zwischen Freiberuflern und damit sogar
          zwischen Rechtsanwälten, die das Honorar stunden) und privaten Verbrauchern (natürlichen Personen, soweit kein
          gewerblicher/beruflicher Zusammenhang besteht. Eine Ausnahme besteht allerdings bis zur Bagatellgrenze von DM 400,-- (§ 3 I Nr. 1
          VerbrKrG) oder bei einem Zahlungsaufschub von nicht mehr als drei Monaten (§ 3 I Nr. 3 VerbrKrG).
        </p>
      </body>
    </html>
  );
};

export default Verbraucherkredit;
