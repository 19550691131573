import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';

const Eigen = () => {
  return (
    <html>
      <head>
        <title>Erfassen eines Prozeßbevollmächtigten</title>
      </head>
      <body className="rechtsbeistand eigen">
        <HelpNavigationMenu />
        <h2 className="heading-2">
          <a target="rechtsbeistand"></a>Erfassen eines Rechtsanwalts/Rechtsbeistands in eigener Sache
        </h2>
        <p className="Body-Text">
          Hier können Sie eintragen, ob ein Rechtsanwalt/Rechtsbeistand den Antrag als Antragsteller in eigener Sache stellt und eine
          Vergütung für die Geltend-machung des eigenen Anspruchs verlangt (§ 91 Abs. 2 Satz 3 ZPO). Die Angabe weiterer Antragsteller ist
          nicht möglich
        </p>
        <p className="Body-Text"></p>
        <ul>
          <li>
            <a href="erfasseneinesprozessbevollmaechtigten_ra_rb.htm">Rechtsanwalt/Rechtsbeistand</a>
          </li>
          <li>
            <a href="erfasseneinesprozessbevollmaechtigten_ra_gesellschaft.htm">Rechtsanwalts-Gesellschaft</a>
          </li>
        </ul>
        <p></p>
        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Eigen;
