import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IInitialState as IRequeststoreInitialState } from './requeststore.interface';

export interface IInitialState {
  loading: boolean;
  error: string;
  oidcAuthorizationUrl?: string;
  identityCard: IIdentityCard;
  egvp: IEGVP;
}

export interface IIdentityCard {
  familyName: string;
  givenName: string;
  birthdate: string;
  addressType: string;
  issuingState: string;
  address: IAddress;
}

export interface IAddress {
  residentInGermany: boolean;
  formatted: string;
  streetAddress: string;
  postalCode: string;
  locality: string;
  country: string;
}

export interface IIAm {
  applicant: boolean;
  representative: boolean;
  authorized: boolean;
}

export interface IEGVP {
  edaId: string;
  edaServerId: string;
  sendDate: string;
  egvpMessageId: string;
}

export const initialState: IInitialState = {
  loading: false,
  error: '',
  oidcAuthorizationUrl: '',
  identityCard: null,
  egvp: null,
};

export const getOidcAuthorizationUrl = createAsyncThunk(`eIDIdentityCard/oidcAuthorizationUrl`, () => {
  return axios.get(`openidconnect/authorizationUrl`).then(response => {
    return response.data;
  });
});

export const getIdentityCard = createAsyncThunk(
  `eIDIdentityCard/identityCard`,
  ({ code, redirectUri }: { code: string; redirectUri: string }) => {
    return axios.get(`openidconnect/identityCard?code=` + code + `&redirectUri=` + encodeURIComponent(redirectUri)).then(response => {
      return response.data;
    });
  },
);

export const sendToEGVP = createAsyncThunk(
  `eIDIdentityCard/egvp`,
  ({
    requestStoreData,
    identityCard,
    iAm,
    code,
  }: {
    requestStoreData: IRequeststoreInitialState;
    identityCard: IIdentityCard;
    iAm: IIAm;
    code: string;
  }) => {
    requestStoreData.identificationDocument = buildIdentificationDocument(identityCard, iAm, code);
    return axios
      .request({
        url: 'egvp/send',
        data: requestStoreData,
        method: 'POST',
      })
      .then(response => {
        return response.data;
      });
  },
);

export const buildIdentificationDocument = (identityCard: IIdentityCard, iAm: IIAm, code: string) => {
  let creator = null;
  if (!iAm) {
    return null;
  }
  if (!identityCard) {
    return null;
  }
  if (iAm.applicant) {
    creator = '1';
  } else if (iAm.authorized) {
    creator = '2';
  } else if (iAm.representative) {
    creator = '3';
  } else {
    return null;
  }
  if ('test' === code) {
    creator = '4';
  }
  return {
    creator,
    firstName: identityCard.givenName,
    lastName: identityCard.familyName,
    birthdate: identityCard.birthdate,
    addressType: identityCard.addressType,
    issuingState: identityCard.issuingState,
    streetAddress: identityCard.address.streetAddress,
    residentInGermany: identityCard.address.residentInGermany,
    postalCode: identityCard.address.postalCode,
    city: identityCard.address.locality,
    country: identityCard.address.country,
  };
};

export const eIDIdentityCardSlice = createSlice({
  name: 'eIDIdentityCardState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOidcAuthorizationUrl.pending, state => {
        state.loading = true;
      })
      .addCase(getOidcAuthorizationUrl.fulfilled, (state, action) => {
        state.loading = false;
        state.oidcAuthorizationUrl = action.payload;
        state.error = '';
      })
      .addCase(getOidcAuthorizationUrl.rejected, (state, action) => {
        state.loading = false;
        state.oidcAuthorizationUrl = '';
        state.error = action.error.message;
      })
      .addCase(getIdentityCard.pending, state => {
        state.loading = true;
      })
      .addCase(getIdentityCard.fulfilled, (state, action) => {
        state.loading = false;
        state.identityCard = action.payload;
        state.error = '';
      })
      .addCase(getIdentityCard.rejected, (state, action) => {
        state.loading = false;
        state.identityCard = null;
        state.error = action.error.message;
      })
      .addCase(sendToEGVP.pending, state => {
        state.loading = true;
      })
      .addCase(sendToEGVP.fulfilled, (state, action) => {
        state.loading = false;
        state.egvp = action.payload;
        state.error = '';
      })
      .addCase(sendToEGVP.rejected, (state, action) => {
        state.loading = false;
        state.egvp = null;
        state.error = action.error.message;
      });
  },
});

export default eIDIdentityCardSlice.reducer;
