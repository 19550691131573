import React from 'react';
import { contentSelector } from 'app/shared/layout/side-container/side-container-slice';
import { useAppSelector } from 'app/config/store';

export interface ErrorSideContainerContent {
  errorMessages: string[];
}
export const ErrorSideContainer = () => {
  const content: ErrorSideContainerContent = useAppSelector(contentSelector);

  const formatErrorMessage = (message: string): string => `FEHLER: ${message}`;
  const errorMessages = content?.errorMessages || [];
  return (
    <div id={'error-side-container'}>
      <p>
        {errorMessages.map((errorMessage, index) => (
          <span key={index} style={{ color: 'red' }}>
            {formatErrorMessage(errorMessage)}
            <br />
          </span>
        ))}
      </p>
    </div>
  );
};
export default ErrorSideContainer;
