import './auslagenNebenforderungen.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const AuslagenNebenforderungen = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Auslagen und Nebenforderungen</title>
      </head>
      <body className="auslagen-nebenforderungen">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="auslagen_nebenforderungen"></a>Auslagen und Nebenforderungen
        </h2>

        <p className="Body-Text">
          Hier haben Sie die Möglichkeit, die Auslagen und/oder Nebenforderungen einzugeben, die für das vorgerichtliche Verfahren und die
          im Mahnverfahren entstanden sind.
        </p>

        <p className="RelatedHead">
          <b>Siehe auch:</b>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/auslagen_nebenforderungen/antragsteller`}>Auslagen des Antragstellers für dieses Verfahren</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/auslagen_nebenforderungen/andere`}>Andere Nebenforderungen</a>
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default AuslagenNebenforderungen;
