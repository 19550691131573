import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Sonstige = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Erfassen eines Sonstigen Antragsgegner</title>
      </head>
      <body className="antragsgegner sonstige">
        <HelpNavigationMenu />

        <h2>
          <a target="sonstige"></a>Erfassen eines Sonstigen Antragsgegners
        </h2>

        <p className="Body-Text">
          In dieser Registerkarte gibt es weitere Unterteilungen. Wählen Sie aus, ob der Antragsgegner eine Partei kraft Amtes (PKA), eine{' '}
          <a href="glossweg">WEG</a> (Wohnungseigentümergemeinschaft) ist oder eine weitere Rechtsform hat.
        </p>

        <p className="RelatedHead">
          <p></p>
        </p>
        <p className="RelatedHead">
          <b>Siehe auch:</b>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsgegner/ausland`}>Antragsgegner im Ausland</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsgegner/sonstige/pka`}>Partei kraft Amtes</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsgegner/sonstige/weg`}>WEG</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsgegner/sonstige/weitere`}>Weitere</a>
        </p>
        <p className="RelatedHead">
          <p></p>
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Sonstige;
