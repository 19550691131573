// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glossar {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 10pt;
  color: #ffffff;
  background-color: #669966 !important;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding: 5px;
}

.glossar A {
  color: #ffffff;
  text-decoration: underline;
  font-weight: normal;
}

.glossar a:hover {
  color: #00ff00;
  text-decoration: underline;
}

.glossar .TOCTitle {
  font-size: 24pt;
  margin-top: 9pt;
  margin-bottom: 7pt;
}

.glossar .GlossaryTerm {
  font-size: 11pt;
  margin-top: 2pt;
  margin-bottom: 2pt;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/dunning/glossar/glossar.css"],"names":[],"mappings":"AAAA;EACE,kDAAA;EACA,eAAA;EACA,cAAA;EACA,oCAAA;EAGA,qBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AACF","sourcesContent":[".glossar {\n  font-family: Verdana, Arial, Helvetica, sans-serif;\n  font-size: 10pt;\n  color: #ffffff;\n  background-color: #669966 !important;\n  -ms-hyphens: auto;\n  -moz-hyphens: auto;\n  -webkit-hyphens: auto;\n  hyphens: auto;\n  padding: 5px;\n}\n\n.glossar A {\n  color: #ffffff;\n  text-decoration: underline;\n  font-weight: normal;\n}\n\n.glossar a:hover {\n  color: #00ff00;\n  text-decoration: underline;\n}\n\n.glossar .TOCTitle {\n  font-size: 24pt;\n  margin-top: 9pt;\n  margin-bottom: 7pt;\n}\n\n.glossar .GlossaryTerm {\n  font-size: 11pt;\n  margin-top: 2pt;\n  margin-bottom: 2pt;\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
