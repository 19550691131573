// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datenschutz h1 {
  margin: 0 0 5px 0;
  font-size: 180%;
  background-color: #666;
  text-align: center;
  padding: 4px;
}

.datenschutz h2 {
  margin: 20px 0 5px 0;
  font-size: 120%;
  background-color: #666;
  text-align: left;
  padding: 4px;
}

.datenschutz div.text {
  margin: 0 0 5px 0;
  padding: 5px;
  background-color: #666;
}

.datenschutz * {
  color: White;
}

.datenschutz a.closeButton {
  margin: 5px 0 5px 0;
  float: right;
  padding: 3px;
  background-color: #666;
  font-size: 80%;
  text-decoration: none;
  border: 2px solid #eee;
  border-bottom-right-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/dataProtection/datenschutz.css"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AACA;EACE,oBAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;AAEF;;AAAA;EACE,iBAAA;EACA,YAAA;EACA,sBAAA;AAGF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,mBAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EACA,cAAA;EACA,qBAAA;EACA,sBAAA;EAGA,gCAAA;AAKF","sourcesContent":[".datenschutz h1 {\n  margin: 0 0 5px 0;\n  font-size: 180%;\n  background-color: #666;\n  text-align: center;\n  padding: 4px;\n}\n.datenschutz h2 {\n  margin: 20px 0 5px 0;\n  font-size: 120%;\n  background-color: #666;\n  text-align: left;\n  padding: 4px;\n}\n.datenschutz div.text {\n  margin: 0 0 5px 0;\n  padding: 5px;\n  background-color: #666;\n}\n.datenschutz * {\n  color: White;\n}\n.datenschutz a.closeButton {\n  margin: 5px 0 5px 0;\n  float: right;\n  padding: 3px;\n  background-color: #666;\n  font-size: 80%;\n  text-decoration: none;\n  border: 2px solid #eee;\n  -webkit-border-bottom-right-radius: 10px;\n  -moz-border-radius-bottomright: 10px;\n  border-bottom-right-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
