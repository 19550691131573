// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hinweis {
  background-color: #aaa;
  color: White;
  font-family: Arial, sans-serif;
  font-size: 10pt;
  display: block;
  margin: 8px;
}

.hinweis h1 {
  margin: 0 0 5px 0;
  font-size: 180%;
  background-color: #666;
  text-align: center;
  padding: 4px;
  display: block;
  font-size: 2em;
  font-weight: bold;
  unicode-bidi: isolate;
}

.hinweis div.text {
  margin: 0 0 5px 0;
  padding: 5px;
  background-color: #666;
}

.hinweis a.closeButton {
  margin: 5px 0 5px 0;
  float: right;
  padding: 3px;
  background-color: #666;
  font-size: 80%;
  text-decoration: none;
  border: 2px solid #eee;
  border-bottom-right-radius: 10px;
}

.hinweis a {
  color: White;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/oldPages/help/generic/hinweis/hinweis.css"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,YAAA;EACA,8BAAA;EACA,eAAA;EACA,cAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;EACA,qBAAA;AACF;;AAEA;EACE,iBAAA;EACA,YAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EACA,cAAA;EACA,qBAAA;EACA,sBAAA;EAGA,gCAAA;AACF;;AACA;EACE,YAAA;AAEF","sourcesContent":[".hinweis {\n  background-color: #aaa;\n  color: White;\n  font-family: Arial, sans-serif;\n  font-size: 10pt;\n  display: block;\n  margin: 8px;\n}\n\n.hinweis h1 {\n  margin: 0 0 5px 0;\n  font-size: 180%;\n  background-color: #666;\n  text-align: center;\n  padding: 4px;\n  display: block;\n  font-size: 2em;\n  font-weight: bold;\n  unicode-bidi: isolate;\n}\n\n.hinweis div.text {\n  margin: 0 0 5px 0;\n  padding: 5px;\n  background-color: #666;\n}\n\n.hinweis a.closeButton {\n  margin: 5px 0 5px 0;\n  float: right;\n  padding: 3px;\n  background-color: #666;\n  font-size: 80%;\n  text-decoration: none;\n  border: 2px solid #eee;\n  -webkit-border-bottom-right-radius: 10px;\n  -moz-border-radius-bottomright: 10px;\n  border-bottom-right-radius: 10px;\n}\n.hinweis a {\n  color: White;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
