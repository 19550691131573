import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Prozessgericht = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Prozessgericht</title>
      </head>
      <body className="generic-help prozessgericht">
        <HelpNavigationMenu />

        <h2>Prozessgericht</h2>

        <p>
          Hat sich die Anschrift des Antragsgegners seit dem Antrag auf Erlass eines Mahnbescheids geändert und ergibt sich daraus eine
          Änderung des zuständigen Prozessgerichts, so geben Sie hier das neue zuständige Prozessgericht an.
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Prozessgericht;
