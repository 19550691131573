// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.foreign-country-dialog .modal-content {
  background-color: #666666;
  color: white;
}

.table-data {
  text-align: center;
  color: white;
}

.table-header {
  text-align: center;
  color: white;
}

.foreign-country-dialog .modal-title {
  text-align: center;
}

.foreign-country-dialog {
  max-width: 400px;
  width: 100%;
  max-height: 700px;
  height: 100%;
}

.foreign-country-dialog .modal-header {
  border-bottom: none;
  padding-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/dunning/attorney/foreign-country-dialog/foreignCountryModal.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;AACF;;AACA;EACE,kBAAA;EACA,YAAA;AAEF;;AAAA;EACE,kBAAA;EACA,YAAA;AAGF;;AADA;EACE,kBAAA;AAIF;;AAFA;EACE,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;AAKF;;AAHA;EACE,mBAAA;EACA,mBAAA;AAMF","sourcesContent":[".foreign-country-dialog .modal-content {\n  background-color: #666666;\n  color: white;\n}\n.table-data {\n  text-align: center;\n  color: white;\n}\n.table-header {\n  text-align: center;\n  color: white;\n}\n.foreign-country-dialog .modal-title {\n  text-align: center;\n}\n.foreign-country-dialog {\n  max-width: 400px;\n  width: 100%;\n  max-height: 700px;\n  height: 100%;\n}\n.foreign-country-dialog .modal-header {\n  border-bottom: none;\n  padding-bottom: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
