import React, { useState } from 'react';
import './helpNavigationMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCaretRight } from '@fortawesome/pro-solid-svg-icons/faSquareCaretRight';
import { faSquareCaretLeft } from '@fortawesome/pro-solid-svg-icons/faSquareCaretLeft';
import { faSquareInfo } from '@fortawesome/pro-solid-svg-icons/faSquareInfo';
import { faSquareX } from '@fortawesome/pro-solid-svg-icons/faSquareX';
import { useAppSelector } from 'app/config/store';
import { back, closeWindow, home, next } from '../../generic/navigationMenuUtils';

interface IHelpNavigationMenuProps {
  hideBack?: boolean;
  hideNext?: boolean;
}

const HelpNavigationMenu = ({ hideBack, hideNext }: IHelpNavigationMenuProps) => {
  const pagesArray = [
    'inhaltsverzeichnis',
    'einfuehrung',
    'bedienung',
    'bundeslandauswahl',
    'antragstellerart',
    'rechtsbeistand',
    'rechtsbeistand/eigen',
    'rechtsbeistand/fremd',
    'rechtsbeistand/gesellschaft',
    'rechtsbeistand/herr_frau',
    'rechtsbeistand/inkasso',
    'rechtsbeistand/verbraucher',
    'rechtsbeistand/rechtsbeistand_vertretung',
    'antragsteller',
    'antragsteller/ausland',
    'antragsteller/natuerliche_person',
    'antragsteller/firma',
    'antragsteller/kennziffer',
    'antragsteller/sonstige',
    'antragsteller/sonstige/pka',
    'antragsteller/sonstige/anwalt_eigen',
    'antragsteller/sonstige/weg',
    'antragsteller/sonstige/weg_verwalter',
    'antragsteller/sonstige/weg_antragsteller',
    'antragsteller/sonstige/weitere',
    'antragsgegner',
    'antragsgegner/ausland',
    'antragsgegner/natuerliche_person',
    'antragsgegner/nato',
    'antragsgegner/firma',
    'antragsgegner/sonstige',
    'antragsgegner/sonstige/pka',
    'antragsgegner/sonstige/weg',
    'antragsgegner/sonstige/weg_verwalter',
    'antragsgegner/sonstige/weg_antragsgegner',
    'antragsgegner/sonstige/weitere',
    'rechtsbeistand_vertretung',
    'hauptforderungen',
    'hauptforderungen/katalognummer',
    'hauptforderungen/sonstiges',
    'hauptforderungen/ausgerechneter_zins',
    'hauptforderungen/laufende_zinsen',
    'auslagen_nebenforderungen',
    'auslagen_nebenforderungen/antragsteller',
    'auslagen_nebenforderungen/andere',
    'prozessgerichte',
    'allgemeine_angaben',
    'bankverbindung',
    'uebersicht',
    'barcode',
    'eda',
    'eid',
    'glossar',
  ];

  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);

  return (
    <table>
      <tbody>
        <tr>
          {!hideBack && (
            <td align="left" className="button">
              <a accessKey="v" onClick={() => back(pagesArray, urlPrefix)}>
                <FontAwesomeIcon className="icon-menu" icon={faSquareCaretLeft} />
              </a>
            </td>
          )}
          {!hideNext && (
            <td align="left" className="button">
              <a accessKey="n" onClick={() => next(pagesArray, urlPrefix)}>
                <FontAwesomeIcon className="icon-menu" icon={faSquareCaretRight} />
              </a>
            </td>
          )}
          <td align="left" className="button">
            <a accessKey="i" onClick={() => home(pagesArray, urlPrefix)}>
              <FontAwesomeIcon className="icon-menu" icon={faSquareInfo} />
            </a>
          </td>
          <td align="left" className="button">
            <a accessKey="x" onClick={() => closeWindow()}>
              <FontAwesomeIcon className="icon-menu" icon={faSquareX} />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default HelpNavigationMenu;
