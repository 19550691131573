import './alertModal.scss';
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';

interface IAlertModalProps {
  titleContentKey?: string;
  bodyContentKey: string;
  isOpen: boolean;
  toggle: () => void;
}

const AlertModal: React.FC<IAlertModalProps> = ({ titleContentKey, bodyContentKey, isOpen, toggle }) => (
  <Modal className="alert-modal" isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle} cssModule={{ 'modal-title': 'w-100 text-center' }}>
      <Translate contentKey={titleContentKey === undefined ? 'alert-modal.generic-title' : titleContentKey}>Title</Translate>
    </ModalHeader>
    <ModalBody>
      <Translate contentKey={bodyContentKey}>Body</Translate>
    </ModalBody>
  </Modal>
);

export default AlertModal;
