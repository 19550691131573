import './home.scss';

import React, { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { Button, Col, Row } from 'reactstrap';
import { useAppDispatch } from 'app/config/store';
import { RequestTypes } from 'app/config/constants';
import { clear, setType } from 'app/shared/reducers/requeststore';
import { UrlPrefix } from 'app/config/constants';

export interface RequestTypeBoxData {
  headerTranslation: string;
  buttonTranslation: string;
  className: RequestTypes | 'hidden-box';
  route: string;
  group: string;
}

const requestTypesBoxData: RequestTypeBoxData[] = [
  {
    headerTranslation: 'home.dunningRequestName',
    buttonTranslation: 'home.buttonStartName',
    className: RequestTypes.Dunning,
    route: UrlPrefix.Dunning + '/bundeslandauswahl',
    group: 'dunningRequests',
  },
  {
    headerTranslation: 'home.redeliveryDunningRequestName',
    buttonTranslation: 'home.buttonStartName',
    className: RequestTypes.RedeliveryDunning,
    route: UrlPrefix.Redelivery_dunning + '/geschaeftsnummer',
    group: 'dunningRequests',
  },
  {
    headerTranslation: 'home.objectionName',
    buttonTranslation: 'home.buttonStartName',
    className: RequestTypes.Objection,
    route: UrlPrefix.Objection + '/intention',
    group: 'dunningRequests',
  },
  {
    headerTranslation: 'home.enforcementOrderName',
    buttonTranslation: 'home.buttonStartName',
    className: RequestTypes.Enforcement,
    route: UrlPrefix.Enforcement + '/geschaeftsnummer',
    group: 'executionRequests',
  },
  {
    headerTranslation: 'home.redeliveryEnforcementOrderName',
    buttonTranslation: 'home.buttonStartName',
    className: RequestTypes.RedeliveryEnforcement,
    route: UrlPrefix.Redelivery_enforcement + '/geschaeftsnummer',
    group: 'executionRequests',
  },
  {
    headerTranslation: '',
    buttonTranslation: '',
    className: 'hidden-box',
    route: null,
    group: 'executionRequests',
  },
  /* Uncomment this to show Einspruch request
     before uncommenting this it is needed to remove the hidden box
  {
     headerTranslation: "home.objection",
     buttonTranslation: "home.buttonStartName",
     className: "RequestTypes.Objection",
     route: null,
     group: "executionRequests"
   }, */
];

const RequestTypeBoxComponent = ({
  requestTypeBoxData,
  onClick,
}: {
  requestTypeBoxData: RequestTypeBoxData;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => (
  <div className={`theme-${requestTypeBoxData.className}`}>
    <div className={`base request-type-bordered-box`}>
      <div className="request-type-box-header">
        <Translate contentKey={requestTypeBoxData.headerTranslation} />
      </div>
      <div className="request-type-box-button">
        <Button
          onClick={onClick}
          className="base start-button"
          name={
            translate(requestTypeBoxData.headerTranslation)?.replaceAll(' ', '').toLowerCase() +
            '-' +
            translate(requestTypeBoxData.buttonTranslation)?.toLowerCase()
          }
        >
          <Translate contentKey={requestTypeBoxData.buttonTranslation} />
        </Button>
      </div>
    </div>
  </div>
);

export const Home = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const groupedRequestTypes = requestTypesBoxData.reduce(
    (
      groups: {
        [key: string]: RequestTypeBoxData[];
      },
      requestType,
    ) => {
      const group = groups[requestType.group] || [];
      group.push(requestType);
      groups[requestType.group] = group;
      return groups;
    },
    {},
  );

  return (
    <div>
      <Col md={{ size: 6, offset: 3 }}>
        <h1 className="header-title">
          <Translate contentKey={`home.headerTitle`}></Translate>
        </h1>
        <p className="sub-title">
          <Translate contentKey={`home.subTitle`}></Translate>
        </p>
      </Col>
      <div className="request-type-box-container">
        {Object.keys(groupedRequestTypes).map((group: string) => (
          <Row key={group} className="request-type-box-group">
            {groupedRequestTypes[group].map((requestTypeBoxData: RequestTypeBoxData, index: number) => (
              <Col md="4" key={requestTypeBoxData.className}>
                <RequestTypeBoxComponent
                  requestTypeBoxData={requestTypeBoxData}
                  onClick={() => {
                    dispatch(clear());
                    dispatch(setType(requestTypeBoxData.className === 'hidden-box' ? undefined : requestTypeBoxData.className));
                    if (requestTypeBoxData.route !== '') {
                      navigate(requestTypeBoxData.route);
                    }
                  }}
                />
              </Col>
            ))}
          </Row>
        ))}
      </div>
    </div>
  );
};

export default Home;
