import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import './einfuehrung.css';

const Einfuehrung = () => {
  return (
    <html>
      <title>Einführung</title>

      <body className="einfuehrung">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="einf.hrung"></a>Einführung
        </h2>

        <p className="Body-Text">
          Im gerichtlichen Mahnverfahren können Sie schnell und einfach einen Voll­streckungs­titel (Voll­streckungs­bescheid) über eine
          Geldforderung erwirken, wenn Einwendungen Ihres Antragsgegners nicht zu erwarten sind. Bevor Sie einen Mahnbescheid beantragen,
          sollten Sie prüfen, ob Sie dem Antragsgegner Ihre Forderungen in klarer, übersichtlicher Form in Rechnung gestellt haben. Holen
          Sie dies notfalls nach. Sonst könnte der Antragsgegner dem Mahnbescheid allein deshalb widersprechen, weil er nicht nachprüfen
          kann, welche Beträge für welche Leistungen Sie im Einzelnen von ihm verlangen.{' '}
        </p>

        <p className="Body-Text">
          Der Online-Mahnantrag ist ein von der Justiz der Bundesländer zur kostenlosen Nutzung zur Verfügung gestelltes interaktives
          Formular, mit dem der Antrag auf Erlass eines Mahnbescheids im Internet ausgefüllt werden kann. Die Antragsdaten können danach an
          das zuständige Mahngericht entweder verschlüsselt und signiert über das Internet übermittelt oder auf Papier gedruckt und per Post
          übersandt werden.
        </p>

        <p className="Body-Text">
          Der Online-Mahnantrag richtet sich an Bürgerinnen und Bürger, Unternehmen und Prozess­bevollmächtigte, die keine eigene
          Mahnsoftware für die Erstellung des Antrags einsetzen. Die Antragsdaten werden bei der Eingabe bereits inhaltlich geprüft, so dass
          fehlerhafte Anträge weitgehend vermieden werden.
        </p>

        <p className="Body-Text">
          Die Anwendung erfolgt ebenso wie die sich nach Antragseingang bei Gericht anschließende maschinelle Bearbeitung der Mahnverfahren
          für alle Bundesländer grundsätzlich nach einheitlichen Regeln. Maßgebend für die Gestaltung ist die durch Verwaltungsabkommen der
          Länder eingerichtete Koordinierungs­stelle für Pflege und Weiter­entwicklung des automatisierten gerichtlichen Mahnverfahrens beim
          Justiz­ministerium Baden-Württemberg.
        </p>

        <p className="Note">
          Hinweis: Die Anwendung des Online-Mahnantrags ist nur für Mahnverfahren der ordentlichen Gerichtsbarkeit (Amtsgerichte), nicht
          aber der Arbeitsgerichtsbarkeit zulässig.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Einfuehrung;
