import React from 'react';
import HelpNavigationMenu from '../../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Sonstige = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  return (
    <html>
      <head>
        <title>Erfassen eines Sonstigen Antragsteller</title>
      </head>
      <body className="antragsteller sonstige">
        <HelpNavigationMenu />

        <h2>
          <a target="sonstige"></a>Erfassen eines Sonstigen Antragstellers
        </h2>

        <p className="Body-Text">
          In dieser Registerkarte gibt es weitere Unterteilungen. Wählen Sie aus, ob der Antragsteller eine Partei kraft Amtes (PKA), RA in
          eig. Sache (Rechtsanwalt in eigener Sache), eine <a href="glossweg">WEG</a> (Wohnungseigentümergemeinschaft) ist oder eine weitere
          Rechtsform hat.{' '}
        </p>

        <p className="RelatedHead">
          <b>Siehe auch:</b>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsteller/ausland`}>Antragsteller im Ausland</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsteller/sonstige/pka`}>Partei kraft Amtes (PKA)</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsteller/sonstige/anwalt_eigen`}>RA in eig.Sache</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsteller/sonstige/weg`}>WEG</a>
        </p>

        <p className="Jump-from-List">
          <a href={`hilfe${urlPrefix}/antragsteller/sonstige/weitere`}>Weitere</a>
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};

export default Sonstige;
