import { useAppSelector } from 'app/config/store';
import { UrlPrefix } from 'app/config/constants';
import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../../../error/error-boundary-routes';
import Chrome from '../generic/hinweis/chrome';
import Edge from '../generic/hinweis/edge';
import Firefox from '../generic/hinweis/firefox';
import AllgemeineAngaben from './allgemeineAngaben';
import Ansprechpartner from './ansprechpartner/ansprechpartner';
import Antragsgegneranschrift from './antragsgegneranschrift';
import Antragsgegnerbezeichnung from './antragsgegnerbezeichnung';
import Antragstellerart from './antragstellerart';
import Auslagen from './auslagen';
import Bankverbindung from './bankverbindung';
import Barcode from './barcode';
import Eda from './eda';
import Eid from './eid';
import Geschaeftsnummer from './geschaeftsnummer';
import Gesetzlichevertreter from './gesetzlichevertreter';
import Inhaltsverzeichnis from './inhaltsverzeichnis';
import MahnantragGestellt from './mahnantragGestellt';
import NeuerVertreter from './neuerVertreter';
import Prozessgericht from './prozessgericht';
import HerrFrau from './rechtsbeistand/herrFrau';
import InkassoKreditdienstleister from './rechtsbeistand/inkassoKreditdienstleister';
import RechtsanwaltRechtsbeistand from './rechtsbeistand/rechtsanwaltRechtsbeistand';
import Rechtsanwaltsgesellschaft from './rechtsbeistand/rechtsanwaltsgesellschaft';
import Rechtsbeistand from './rechtsbeistand/rechtsbeistand';
import Verbraucherverband from './rechtsbeistand/verbraucherverband';
import Uebersicht from './uebersicht';
import Umfang from './umfang';
import Zahlugen from './zahlugen';
import ZustellungVertreter from './zustellungVertreter';

export const routeObject = (urlPrefix: UrlPrefix) => ({
  [`inhaltsverzeichnis`]: <Inhaltsverzeichnis />,
  [`geschaeftsnummer`]: <Geschaeftsnummer />,
  [`antragstellerart`]: <Antragstellerart />,
  [`mahnantrag_gestellt`]: <MahnantragGestellt />,
  [`rechtsbeistand`]: <Rechtsbeistand />,
  [`rechtsbeistand/rechtsanwalt_rechtsbeistand`]: <RechtsanwaltRechtsbeistand />,
  [`rechtsbeistand/rechtsanwaltsgesellschaft`]: <Rechtsanwaltsgesellschaft />,
  [`rechtsbeistand/herr_frau`]: <HerrFrau />,
  [`rechtsbeistand/inkasso_kreditdienstleister`]: <InkassoKreditdienstleister />,
  [`rechtsbeistand/verbraucherverband`]: <Verbraucherverband />,
  [`gesetzlichevertreter`]: <Gesetzlichevertreter />,
  ...(urlPrefix !== UrlPrefix.Objection && { bankverbindung: <Bankverbindung /> }),
  ...(urlPrefix === UrlPrefix.Enforcement && { zahlungen: <Zahlugen /> }),
  [`antragsgegneranschrift`]: <Antragsgegneranschrift />,
  ...(urlPrefix !== UrlPrefix.Redelivery_enforcement &&
    urlPrefix !== UrlPrefix.Objection && { antragsgegnerbezeichnung: <Antragsgegnerbezeichnung /> }),
  ...(urlPrefix === UrlPrefix.Redelivery_dunning && { prozessgericht: <Prozessgericht /> }),
  ...(urlPrefix !== UrlPrefix.Redelivery_enforcement && urlPrefix !== UrlPrefix.Objection && { auslagen: <Auslagen /> }),
  ['zustellung_vertreter']: <ZustellungVertreter />,
  ...(urlPrefix === UrlPrefix.Objection && { umfang: <Umfang /> }),
  ...(urlPrefix !== UrlPrefix.Redelivery_enforcement && urlPrefix !== UrlPrefix.Objection && { neuer_vertreter: <NeuerVertreter /> }),
  [`allgemeine_angaben`]: <AllgemeineAngaben />,
  [`uebersicht`]: <Uebersicht />,
  [`barcode`]: <Barcode />,
  [`hinweis_chrome`]: <Chrome />,
  [`hinweis_firefox`]: <Firefox />,
  [`hinweis_edge`]: <Edge />,
  [`eda`]: <Eda />,
  [`eid`]: <Eid />,
  [`ansprechpartner`]: <Ansprechpartner />,
});

const RedeliveryHelpRoutes = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  const routeObjectWithPrefix = routeObject(urlPrefix);
  return (
    <div>
      <ErrorBoundaryRoutes>
        {Object.keys(routeObjectWithPrefix).map(route => (
          <Route path={route} element={routeObjectWithPrefix[route]} key={route} />
        ))}
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default RedeliveryHelpRoutes;
