import './generic-help.css';
import React from 'react';
import HelpNavigationMenu from './helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';

const Auslagen = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Auslagen</title>
      </head>
      <body className="generic-help auslagen">
        <HelpNavigationMenu />

        <h2>Auslagen</h2>

        <p>
          Sind <strong>weitere</strong> Auslagen für dieses Verfahren entstanden, so können Sie diese hier angeben.
        </p>

        <p>
          Die Kosten der Prozessvertretung durch einen Rechtsanwalt/Rechtsbeistand und die Gerichtskosten werden vom Amtsgericht errechnet
          und in den Mahnbescheid aufgenommen. Bitte tragen Sie diese deshalb <strong>nicht</strong> hier ein.
        </p>

        <p>
          Im Feld <span className="quotable">Sonstige Kosten Bez.</span> wählen Sie aus der vorgegebenen Liste eine evtl. weitere vorhandene
          Kostenart. Nur wenn in der Liste die benötigte Kostenart nicht enthalten ist, geben Sie diese im Feld{' '}
          <span className="quotable">wenn andere</span> an.
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Auslagen;
