import './foreignCountryModal.scss';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';

type ForeignCountryDialogProps = {
  showModal: boolean;
  handleClose: React.MouseEventHandler<any>;
};

export const ForeignCountryDialog = ({ showModal = false, handleClose }: ForeignCountryDialogProps) => {
  const tableData = [
    { flag: 'A', nation: 'OESTERREICH' },
    { flag: 'ADN', nation: ' JEMEN' },
    { flag: 'AFG', nation: ' AFGHANISTAN' },
    { flag: 'AG', nation: '  ANTIGUA UND BARBUDA' },
    { flag: 'AL', nation: '  ALBANIEN' },
    { flag: 'AND', nation: ' ANDORRA' },
    { flag: 'ANG', nation: ' ANGOLA' },
    { flag: 'ARM', nation: ' ARMENIEN' },
    { flag: 'AUS', nation: ' AUSTRALIEN' },
    { flag: 'AXA', nation: ' ANGUILLA' },
    { flag: 'AZ', nation: '  ASERBAIDSCHAN' },
    { flag: 'B', nation: ' BELGIEN' },
    { flag: 'BD ', nation: ' BANGLADESCH' },
    { flag: 'BDA', nation: ' BERMUDA' },
    { flag: 'BDS ', nation: 'BARBADOS' },
    { flag: 'BF ', nation: ' BURKINA FASO' },
    { flag: 'BG ', nation: ' BULGARIEN' },
    { flag: 'BH ', nation: ' BELIZE' },
    { flag: 'BIH ', nation: 'BOSNIEN UND HERZEGOWINA' },
    { flag: 'BOH ', nation: 'BOSNIEN UND HERZEGOWINA' },
    { flag: 'BOL ', nation: 'BOLIVIEN' },
    { flag: 'BR  ', nation: 'BRASILIEN' },
    { flag: 'BRN ', nation: 'BAHRAIN' },
    { flag: 'BRU ', nation: 'BRUNEI' },
    { flag: 'BS  ', nation: 'BAHAMAS' },
    { flag: 'BTN ', nation: 'BHUTAN' },
    { flag: 'BU ', nation: ' BURUNDI' },
    { flag: 'BUR ', nation: 'BURMA MYANMAR' },
    { flag: 'BY  ', nation: 'WEISSRUSSLAND' },
    { flag: 'C ', nation: 'KUBA' },
    { flag: 'CAM ', nation: 'KAMERUN' },
    { flag: 'CAY ', nation: 'CAYMAN' },
    { flag: 'CDN ', nation: 'KANADA' },
    { flag: 'CGO ', nation: 'DEMOKRATISCHE REPUBLIK KONGO' },
    { flag: 'CH  ', nation: 'SCHWEIZ' },
    {flag:  'CHN ', nation: 'China'},
    { flag: 'CI  ', nation: 'ELFENBEINKUESTE' },
    { flag: 'CL  ', nation: 'SRI LANKA' },
    { flag: 'CO  ', nation: 'KOLUMBIEN' },
    { flag: 'COM ', nation: 'KOMOREN' },
    { flag: 'CR  ', nation: 'COSTA RICA' },
    { flag: 'CY  ', nation: 'ZYPERN' },
    { flag: 'CZ  ', nation: 'TSCHECHIEN' },
    { flag: 'D ', nation: 'BUNDESREPUBLIK DEUTSCHLAND' },
    { flag: 'DK  ', nation: 'DAENEMARK' },
    { flag: 'DOM ', nation: 'DOMINIKANISCHE REPUBLIK' },
    { flag: 'DS  ', nation: 'DSCHIBUTI' },
    { flag: 'DY  ', nation: 'BENIN' },
    { flag: 'DZ  ', nation: 'ALGERIEN' },
    { flag: 'E ', nation: 'SPANIEN' },
    { flag: 'EAK ', nation: 'KENIA' },
    { flag: 'EAT ', nation: 'TANSANIA' },
    { flag: 'EAU ', nation: 'UGANDA' },
    { flag: 'EC  ', nation: 'ECUADOR' },
    { flag: 'EGU ', nation: 'AEQUATORIAL GUINEA' },
    { flag: 'ER  ', nation: 'ERITREA' },
    { flag: 'ERI ', nation: 'ERITREA' },
    { flag: 'ES  ', nation: 'EL SALVADOR' },
    { flag: 'EST ', nation: 'ESTLAND' },
    { flag: 'ET  ', nation: 'AEGYPTEN' },
    { flag: 'ETH ', nation: 'AETHIOPIEN' },
    { flag: 'F ', nation: 'FRANKREICH' },
    { flag: 'FIN ', nation: 'FINNLAND' },
    { flag: 'FJI ', nation: 'FIDSCHI' },
    { flag: 'FJL ', nation: 'FIDSCHI' },
    { flag: 'FL  ', nation: 'FUERSTENTUM LIECHTENSTEIN' },
    { flag: 'FR  ', nation: 'FAEROER' },
    { flag: 'FSM ', nation: 'MIKRONESIEN' },
    { flag: 'GAB ', nation: 'GABUN' },
    { flag: 'GB  ', nation: 'GROSSBRITANNIEN' },
    { flag: 'GBA ', nation: 'ALDERNY' },
    { flag: 'GBG ', nation: 'GUERNSEY' },
    { flag: 'GBJ ', nation: 'JERSEY' },
    { flag: 'GBM ', nation: 'INSEL MAN' },
    { flag: 'GBZ ', nation: 'GIBRALTAR' },
    { flag: 'GCA ', nation: 'GUATEMALA' },
    { flag: 'GE  ', nation: 'GEORGIEN' },
    { flag: 'GH  ', nation: 'GHANA' },
    { flag: 'GR  ', nation: 'GRIECHENLAND' },
    { flag: 'GUA ', nation: 'GUAM' },
    { flag: 'GUI ', nation: 'GUINEA BISSAU' },
    { flag: 'GUY ', nation: 'GUYANA' },
    { flag: 'H ', nation: 'UNGARN' },
    { flag: 'HKJ ', nation: 'JORDANIEN' },
    { flag: 'HN  ', nation: 'HONDURAS' },
    { flag: 'HR  ', nation: 'KROATIEN' },
    { flag: 'HV  ', nation: 'BURKINA FASO' },
    { flag: 'I ', nation: 'ITALIEN' },
    { flag: 'IL ', nation: ' ISRAEL' },
    { flag: 'IND', nation: ' INDIEN' },
    { flag: 'IR ', nation: ' IRAN' },
    { flag: 'IRL', nation: ' IRLAND' },
    { flag: 'IRQ ', nation: 'IRAK' },
    { flag: 'IS ', nation: ' ISLAND' },
    { flag: 'J ', nation: 'JAPAN' },
    { flag: 'JA  ', nation: 'JAMAIKA' },
    { flag: 'JOR ', nation: 'JORDANIEN' },
    { flag: 'K ', nation: 'KAMBODSCHA' },
    { flag: 'KIR ', nation: 'KIRIBATI' },
    { flag: 'KOS', nation: ' KOSOVO' },
    { flag: 'KS ', nation: ' KIRGISISTAN' },
    { flag: 'KSA ', nation: 'KOENIGREICH SAUDI ARABIEN' },
    { flag: 'KVD ', nation: 'KAP VERDE' },
    { flag: 'KWT ', nation: 'KUWAIT' },
    { flag: 'KZ  ', nation: 'KASACHSTAN' },
    { flag: 'L ', nation: 'LUXEMBURG' },
    { flag: 'LAO ', nation: 'LAOS' },
    { flag: 'LAR ', nation: 'LIBYEN' },
    { flag: 'LB  ', nation: 'LIBERIA' },
    { flag: 'LS  ', nation: 'LESOTHO' },
    { flag: 'LT  ', nation: 'LITAUEN' },
    { flag: 'LV  ', nation: 'LETTLAND' },
    { flag: 'M ', nation: 'MALTA' },
    { flag: 'MA ', nation: ' MAROKKO' },
    { flag: 'MAL ', nation: 'MALAYSIA' },
    { flag: 'MAU', nation: ' MAURITIUS' },
    { flag: 'MC ', nation: ' MONACO' },
    { flag: 'MD ', nation: ' MOLDAWIEN' },
    { flag: 'MDV', nation: ' MALEDIVEN' },
    { flag: 'MEX', nation: ' MEXIKO' },
    { flag: 'MH ', nation: ' MARSHALL INSELN' },
    { flag: 'MK ', nation: ' MAZEDONIEN' },
    { flag: 'ML ', nation: ' MALEDIVEN' },
    { flag: 'MNE', nation: ' MONTENEGRO' },
    { flag: 'MNG ', nation: 'MONGOLEI' },
    { flag: 'MOC ', nation: 'MOSAMBIK' },
    { flag: 'MOS ', nation: 'MOSAMBIQUE' },
    { flag: 'MS ', nation: ' MAURITIUS' },
    { flag: 'MW ', nation: ' MALAWI' },
    { flag: 'MYA', nation: ' MYANMAR' },
    { flag: 'N ', nation: 'NORWEGEN' },
    { flag: 'NA ', nation: ' NIEDERL ANTILLEN' },
    { flag: 'NAM ', nation: 'NAMIBIA' },
    { flag: 'NAU', nation: ' NAURU' },
    { flag: 'NEP', nation: ' NEPAL' },
    { flag: 'NIC', nation: ' NICARAGUA' },
    { flag: 'NL ', nation: ' NIEDERLANDE' },
    { flag: 'NZ ', nation: ' NEUSEELAND' },
    { flag: 'OM ', nation: ' OMAN' },
    { flag: 'OMA ', nation: 'OMAN' },
    { flag: 'P ', nation: 'PORTUGAL' },
    { flag: 'PA ', nation: ' PANAMA' },
    { flag: 'PAK', nation: ' PAKISTAN' },
    { flag: 'PAL', nation: ' PALAU' },
    { flag: 'PE ', nation: ' PERU' },
    { flag: 'PK ', nation: ' PAKISTAN' },
    { flag: 'PL ', nation: ' POLEN' },
    { flag: 'PNG ', nation: 'PAPUA NEUGUINEA' },
    { flag: 'PRK', nation: ' NORDKOREA' },
    { flag: 'PY ', nation: ' PARAGUAY' },
    { flag: 'Q ', nation: 'KATAR' },
    { flag: 'RA ', nation: ' ARGENTINIEN' },
    { flag: 'RB ', nation: ' BOTSUANA' },
    { flag: 'RC ', nation: ' REPUBLIK CHINA TAIWAN' },
    { flag: 'RCA ', nation: 'ZENTRALAFRIKAN REPUBLIK' },
    { flag: 'RCB ', nation: 'REPUBLIK KONGO' },
    { flag: 'RCH ', nation: 'CHILE' },
    { flag: 'RDB ', nation: 'BENIN' },
    { flag: 'RFC', nation: ' KAMERUN' },
    { flag: 'RG ', nation: ' GUINEA' },
    { flag: 'RH ', nation: ' HAITI' },
    { flag: 'RI ', nation: ' INDONESIEN' },
    { flag: 'RIM', nation: ' MAURETANIEN' },
    { flag: 'RL ', nation: ' LIBANON' },
    { flag: 'RM ', nation: ' MADAGASKAR' },
    { flag: 'RMM ', nation: 'MALI' },
    { flag: 'RN ', nation: ' NIGER' },
    { flag: 'RO ', nation: ' RUMAENIEN' },
    { flag: 'ROK', nation: ' SUEDKOREA' },
    { flag: 'ROM ', nation: 'MADAGASKAR' },
    { flag: 'ROU ', nation: 'URUGUAY' },
    { flag: 'RP  ', nation: 'PHILIPPINEN' },
    { flag: 'RSA ', nation: 'SUEDAFRIKA' },
    { flag: 'RSM ', nation: 'SAN MARINO' },
    { flag: 'RT  ', nation: 'TOGO' },
    { flag: 'RU ', nation: ' BURUNDI' },
    { flag: 'RUS', nation: ' RUSSLAND' },
    { flag: 'RWA', nation: ' RUANDA' },
    { flag: 'S ', nation: 'SCHWEDEN' },
    { flag: 'SA  ', nation: 'SAUDI ARABIEN' },
    { flag: 'SAL ', nation: 'SALOMONEN' },
    { flag: 'SCN ', nation: 'ST KITTS UND NEVIS' },
    { flag: 'SD ', nation: ' SWASILAND' },
    { flag: 'SF ', nation: ' FINNLAND' },
    { flag: 'SGP ', nation: 'SINGAPUR' },
    { flag: 'SK ', nation: ' SLOWAKEI' },
    { flag: 'SLO ', nation: 'SLOWENIEN' },
    { flag: 'SME ', nation: 'SURINAME' },
    { flag: 'SN  ', nation: 'SENEGAL' },
    { flag: 'SP ', nation: ' SOMALIA' },
    { flag: 'SRB', nation: ' SERBIEN' },
    { flag: 'STL', nation: ' SANTA LUCIA' },
    { flag: 'STP ', nation: 'SAO TOME UND PRINCIPE' },
    { flag: 'SUD ', nation: 'SUDAN' },
    { flag: 'SY  ', nation: 'SEYSCHELLEN' },
    { flag: 'SYR ', nation: 'SYRIEN' },
    { flag: 'T ', nation: 'THAILAND' },
    { flag: 'TCH ', nation: 'TSCHAD' },
    { flag: 'TG  ', nation: 'TOGO' },
    { flag: 'THA ', nation: 'TSCHAD' },
    { flag: 'TJ  ', nation: 'TADSCHIKISTAN' },
    { flag: 'TM  ', nation: 'TURKMENISTAN' },
    { flag: 'TN  ', nation: 'TUNESIEN' },
    { flag: 'TON ', nation: 'TONGA' },
    { flag: 'TR  ', nation: 'TUERKEI' },
    { flag: 'TT  ', nation: 'TRINIDAD UND TOBAGO' },
    { flag: 'TUV', nation: ' TUVALU' },
    { flag: 'U ', nation: 'URUGUAY' },
    { flag: 'UA ', nation: ' UKRAINE' },
    { flag: 'UAE ', nation: 'VEREINIGTE ARABISCHE EMIRATE' },
    { flag: 'USA ', nation: 'VEREINIGTE STAATEN VON AMERIKA' },
    { flag: 'USB ', nation: 'USBEKISTAN' },
    { flag: 'V', nation: ' VATIKANSTADT' },
    { flag: 'VAE', nation: ' VEREINIGTE ARABISCHE EMIRATE' },
    { flag: 'VG ', nation: ' BRITISCHE JUNGFERNINSELN' },
    { flag: 'VN ', nation: ' VIETNAM' },
    { flag: 'VR ', nation: ' VOLKSREPUBLIK CHINA' },
    { flag: 'VRC', nation: ' VOLKSREPUBLIK CHINA' },
    { flag: 'VU ', nation: ' VANUATU' },
    { flag: 'WAG', nation: ' GAMBIA' },
    { flag: 'WAL', nation: ' SIERRA LEONE' },
    { flag: 'WAN', nation: ' NIGERIA' },
    { flag: 'WD ', nation: ' DOMINICA' },
    { flag: 'WG ', nation: ' GRENADA' },
    { flag: 'WL ', nation: ' SANTA LUCIA' },
    { flag: 'WS ', nation: ' WEST SAMOA' },
    { flag: 'WV ', nation: ' ST VINCENT UND GRENADINEN' },
    { flag: 'Y ', nation: 'YEMEN' },
    { flag: 'YV', nation: '  VENEZUELA' },
    { flag: 'Z ', nation: 'ZAMBIA' },
    { flag: 'ZA ', nation: ' SUEDAFRIKA' },
    { flag: 'ZW ', nation: ' SIMBABWE' },
  ];
  return (
    <Modal className="foreign-country-dialog" isOpen={showModal} toggle={handleClose} size="lg" scrollable={true}>
      <ModalHeader toggle={handleClose}></ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="table-header" colSpan={2}>
                    <h4>
                      <Translate contentKey="dunning.attorneyOfRecord.foreign-license-plate"></Translate>
                    </h4>
                  </th>
                </tr>
                <tr>
                  <th className="table-header">
                    <Translate contentKey="dunning.attorneyOfRecord.flag"></Translate>
                  </th>
                  <th className="table-header">
                    <Translate contentKey="dunning.attorneyOfRecord.nation"></Translate>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map(item => (
                  <tr key={item.flag}>
                    <td className="table-data">{item.flag}</td>
                    <td className="table-data">{item.nation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ForeignCountryDialog;
