import './prozessgerichte.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';

const Prozessgerichte = () => {
  return (
    <html>
      <head>
        <title>Prozessgerichte</title>
      </head>
      <body className="prozessgerichte">
        <HelpNavigationMenu />

        <h2 className="heading-2">
          <a target="prozessgerichte"></a>Prozessgerichte
        </h2>

        <p className="Body-Text">
          Bezeichnung des für ein streitiges Verfahren zuständigen Gerichts: Das bei &quot;Gerichtsart&quot; anzugebende Gericht bezeichnet
          das Gericht nach der sachlichen, die PLZ-/Ortsangabe in den folgenden Feldern nach der örtlichen Zuständigkeit. Sachlich zuständig
          ist für Ansprüche bis 5000 EUR, für Ansprüche aus Wohnraummietverhältnissen und für Unterhaltsansprüche das Amtsgericht, sonst
          grundsätzlich das Landgericht. Örtlich ist grundsätzlich das Gericht zuständig, in dessen Bezirk der Antragsgegner wohnt bzw.
          seinen Sitz hat. Abweichend von diesen Grundsätzen kann eine besondere oder ausschließliche Zuständigkeit begründet sein. Hierzu
          sollten Sie im Einzelfall Rechtsrat einholen. Haben Sie ein unzuständiges Gericht angegeben, drohen Ihnen Kostennachteile.
        </p>

        <p className="Body-Text">
          Das zuständige Prozessgericht wird automatisch ermittelt. Sie können die Angaben aber mit der Funktion &quot;bearbeiten&quot;
          ändern.
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Prozessgerichte;
